import {
  Alert,
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { patchConsultant } from '../../features/consultant/consultantsSlice';

function FormReseauxSociaux({ setOpen }) {
  const [inputAdornment, setInputAdornment] = useState('');
  const { consultant, loading } = useSelector((state) => state.consultants);

  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: {
      LinkedIn:
        consultant.reseauxSociaux?.find((rs) => rs.libelle === 'LinkedIn')
          ?.path ?? '',
      Facebook:
        consultant.reseauxSociaux?.find((rs) => rs.libelle === 'Facebook')
          ?.path ?? '',
      Twitter:
        consultant.reseauxSociaux?.find((rs) => rs.libelle === 'Twitter')
          ?.path ?? '',
      Instagram:
        consultant.reseauxSociaux?.find((rs) => rs.libelle === 'Instagram')
          ?.path ?? '',
    },
  });
  const { control, handleSubmit } = form;
  const allowedSocialNetworks = [
    'LinkedIn',
    'Facebook',
    'Twitter',
    'Instagram',
  ];

  const onSubmitReseaux = (dataReseaux) => {
    const filteredReseaux = Object.keys(dataReseaux)
      .filter((key) => allowedSocialNetworks.includes(key))
      .reduce((object, key) => {
        return {
          ...object,
          [key]: dataReseaux[key],
        };
      }, {});

    const data = Object.entries(filteredReseaux).map(([key, reseau]) => ({
      libelle: key,
      path: reseau || '',
    }));

    const patch = {
      id: consultant.id,
      reseauxSociaux: data,
    };
    dispatch(patchConsultant(patch));
    toast.success('Réseaux ajouté ou mise à jours.');
    setOpen(false);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitReseaux)}>
      <Box>
        <Typography compoment="p" align="center" variant="h6">
          Mes réseaux sociaux
        </Typography>
        {allowedSocialNetworks.map((network) => (
          <Controller
            key={network}
            render={({ field }) => (
              <>
                <InputLabel>{network}</InputLabel>
                <TextField
                  fullWidth
                  type="text"
                  inputRef={field.ref}
                  sx={{ width: '45ch' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        sx={{ marginRight: '0' }}
                        position="start"
                      >
                        {`https://www.${network.toLowerCase()}.com`}
                        <p style={{ color: 'red' }}>/</p>
                        {inputAdornment}
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              </>
            )}
            name={network}
            control={control}
          />
        ))}
        <Alert severity="info">
          Veuillez remplir votre lien après la barre latérale
          <span style={{ color: 'red', margin: 'auto 1' }}> /</span>.
        </Alert>
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ width: '150px', height: '40px' }}
          >
            Enregistrer
          </Button>
        </Grid>
      </Box>
    </form>
  );
}

export default FormReseauxSociaux;
