import styled from 'styled-components';

const Form = styled.form`
  div {
    max-width: 700px;
    .asterix {
      color: red;
    }
    input,
    select {
      padding: 5px;
      height: 30px;
      border: 1.5px solid gray;
      border-radius: 2px;
    }
    .css-g1d714-ValueContainer {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;

export default Form;
