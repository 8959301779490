import { ErrorMessage } from '@hookform/error-message';
import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useStyles } from '../../styles/estimation/Classes';

function Contact({
  title,
  name,
  firstname,
  email,
  control,
  telephone,
  errors,
  required,
}) {
  const classes = useStyles();

  return (
    <Grid className={classes.field} item xs={12} md={12}>
      <Typography className="title text">{title}</Typography>
      <Controller
        render={({ field }) => (
          <TextField
            placeholder="Petit"
            label="Nom"
            type="text"
            inputRef={field.ref}
            {...field}
          />
        )}
        name={name}
        control={control}
        defaultValue=""
        rules={{
          required: required ? 'Veuillez compléter ce champ.' : '',
          maxLength: {
            value: 255,
            message: 'Le nombre maximum de caractères a été dépassé',
          },
        }}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Typography className="error" color="secondary">
            {message}
          </Typography>
        )}
      />
      <Controller
        render={({ field }) => (
          <TextField
            placeholder="Jean"
            label="Prénom"
            type="text"
            inputRef={field.ref}
            {...field}
          />
        )}
        name={firstname}
        control={control}
        defaultValue=""
        rules={{
          required: required ? 'Veuillez compléter ce champ.' : '',
          maxLength: {
            value: 255,
            message: 'Le nombre maximum de caractères a été dépassé',
          },
        }}
      />
      <ErrorMessage
        errors={errors}
        name={firstname}
        render={({ message }) => (
          <Typography className="error" color="secondary">
            {message}
          </Typography>
        )}
      />
      <Controller
        render={({ field }) => (
          <TextField
            label="Téléphone"
            type="tel"
            placeholder="0676461217"
            inputRef={field.ref}
            {...field}
          />
        )}
        name={telephone}
        control={control}
        defaultValue=""
        rules={{
          required: required ? 'Veuillez compléter ce champ.' : '',
          minLength: {
            value: 2,
            message: 'Numéro incorrect',
          },
          maxLength: {
            value: 10,
            message: 'Numéro incorrect',
          },
        }}
      />
      <ErrorMessage
        errors={errors}
        name={telephone}
        render={({ message }) => (
          <Typography className="error" color="secondary">
            {message}
          </Typography>
        )}
      />
      <Controller
        render={({ field }) => (
          <TextField
            label="Email"
            type="email"
            placeholder="Jean-Petit@email.com"
            inputRef={field.ref}
            {...field}
          />
        )}
        name={email}
        control={control}
        defaultValue=""
        rules={{
          required: required ? 'Veuillez compléter ce champ.' : '',
          pattern: /^\S+@\S+$/i,
        }}
      />
      <ErrorMessage
        errors={errors}
        name={email}
        render={({ message }) => (
          <Typography className="error" color="secondary">
            {message}
          </Typography>
        )}
      />
    </Grid>
  );
}

export default Contact;
