import { Grid, Typography } from '@mui/material';
import React from 'react';

function UndefinedPage() {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid>
          <Typography className="ariane mt-5" compoment="p" align="center">
            Page à venir
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UndefinedPage;
