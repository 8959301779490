import {
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  TextField,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Pagination from '@mui/material/Pagination';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { debounce } from 'lodash';
import Backdrop from '@mui/material/Backdrop';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { HeightOutlined } from '@material-ui/icons';
import SideBar from '../components/SideBar';
import { API_LBA } from '../constants/routes';
import useErrorHandler from '../hooks/useErrorHandler';
import { findEvent, findEventAdmin } from '../service/eventApi';
import { useStyles } from '../styles/estimation/Classes';
import EventParticipants from '../components/evenement/EventParticipants';
import TitlePage from '../components/TitlePage';

function Event() {
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [order, setOrder] = useState('asc');
  const [Events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem('token') || false;
  const user = token ? jwtDecode(token) : null;
  const [search, setSearch] = useState('');
  const { errorHandler } = useErrorHandler();
  const itemsPerPage = 12;

  useEffect(() => {
    if (!user.roles.includes('ROLE_ADMIN')) {
      fetchEvent();
    } else {
      fetchEventAdmin();
    }
  }, [currentPage, order]);

  const fetchEvent = async () => {
    setIsLoading(true);
    try {
      const data = await findEvent(
        currentPage,
        itemsPerPage,
        order,
        user.username,
      );
      setEvents(data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchEventAdmin = async () => {
    setIsLoading(true);
    try {
      const data = await findEventAdmin(
        currentPage,
        itemsPerPage,
        order,
        search,
      );
      setEvents(data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    const { myValue } = event.currentTarget.dataset;
    setOrder(myValue);
  };

  // debounce
  const updateQuery = async () => {
    let data;
    if (search === null) return;
    try {
      setIsLoading(true);
      data = await findEventAdmin(currentPage, itemsPerPage, order, search);
      setEvents(data);
    } catch (e) {
      toast.error('Une erreur est survenue');
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const delayedQuery = useCallback(debounce(updateQuery, 500), [search]);

  useEffect(() => {
    delayedQuery();
    setCurrentPage(1);
    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel;
  }, [delayedQuery]);

  const handleSearch = ({ currentTarget }) => {
    setSearch(currentTarget.value);
  };

  // eslint-disable-next-line consistent-return
  /*   const style = (event) => {
    switch (true) {
      case event >= 10:
        return { backgroundColor: 'rgba(0,255,60,0.65)' };
      case event >= 4 && event <= 10:
        return { backgroundColor: 'rgba(0,42,255,0.63)' };
      case event < 4:
        return { backgroundColor: 'rgba(255,0,0,0.68)' };
      default:
        break;
    }
  }; */
  const classes = useStyles();
  // pagination
  const totalPages = Math.ceil(Events['hydra:totalitems'] / itemsPerPage);
  return (
    <SideBar>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignitems="flex-start"
      >
        <TitlePage
          title="Mes évènements"
          underTitle="Consultez les évènements déjà générés"
        />
        <Grid className="p-5" item xs={12}>
          {!isLoading && (
            <TableContainer component={Paper}>
              <Grid container display="flex" justifyContent="flex-start">
                <Button
                  component={Link}
                  to="/creer-evenement"
                  className="m-1"
                  variant="contained"
                  color="primary"
                >
                  Créer un événement
                </Button>
                {user.roles.includes('ROLE_ADMIN') && (
                  <>
                    <Button
                      className="m-1"
                      variant="contained"
                      color="primary"
                      href={`${API_LBA}/export/evenements`}
                      download="export_event.csv"
                    >
                      Export CSV
                    </Button>
                    <TextField
                      size="small"
                      className="m-1"
                      onChange={handleSearch}
                      value={search || ''}
                      label="Rechercher par nom du consultant"
                    />
                  </>
                )}
              </Grid>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Titre</TableCell>
                    <TableCell align="center">Nom</TableCell>
                    <TableCell align="center">Adresse</TableCell>
                    <TableCell align="center">Participants</TableCell>
                    <TableCell align="center">Horaire</TableCell>
                    <TableCell align="center">
                      Date
                      <Button
                        className="ml-1"
                        size="small"
                        color="primary"
                        onClick={handleClick}
                      >
                        <HeightOutlined fontSize="small" />
                      </Button>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem data-my-value="desc" onClick={handleClose}>
                          Décroissant
                        </MenuItem>
                        <MenuItem data-my-value="asc" onClick={handleClose}>
                          Croissant
                        </MenuItem>
                      </Menu>
                    </TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody key={Math.random()}>
                  {Events['hydra:member'] !== undefined &&
                    Events['hydra:member'].map((event) => (
                      <EventParticipants
                        key={event.titreEvenement}
                        props={{ event, user, setIsLoading, fetchEvent }}
                      />
                    ))}
                </TableBody>
              </Table>{' '}
              {!isLoading ? (
                <Pagination
                  count={!Number.isNaN(totalPages) ? totalPages : 0}
                  page={currentPage}
                  onChange={handleChange}
                  size="large"
                  shape="rounded"
                />
              ) : null}
            </TableContainer>
          )}
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={!!isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      ;
    </SideBar>
  );
}

export default Event;
