export const prixCommercialisation = [
  {
    label: "Fourchette d'estimation",
    value: 20,
  },
  {
    label: 'De 1% à 5%',
    value: 15,
  },
  {
    label: 'De 6% à 10%',
    value: 10,
  },
  {
    label: 'De 11% à 15%',
    value: 5,
  },
  {
    label: 'Plus de 15%',
    value: 0,
  },
];
export const fourchettePrix = [
  {
    label: 'Moins de 150 000€',
    value: 8,
  },
  {
    label: 'Entre 150 000€ et 200 000€',
    value: 6,
  },
  {
    label: 'Entre 200 000€ et 350 000€',
    value: 3,
  },
  {
    label: 'Plus de 350 000€',
    value: 0,
  },
];
export const typeMandat = [
  {
    label: 'Mandat exclusif',
    value: 15,
  },
  {
    label: 'Mandat simple & 2 agences',
    value: 3,
  },
  {
    label: 'Mandat simple & 2 agences + PAP',
    value: 0,
  },
];

export const motivation = [
  {
    label: 'Motivation niveau 1',
    value: 15,
  },
  {
    label: 'Motivation niveau 2',
    value: 3,
  },
  {
    label: 'Motivation indéfinie',
    value: 0,
  },
];
export const perimetreAgent = [
  {
    label: 'Secteur primaire',
    value: 10,
  },
  {
    label: 'Secteur secondaire',
    value: 2,
  },
  {
    label: 'Secteur tertiaire',
    value: 0,
  },
];
export const cles = [
  {
    label: 'Oui',
    value: 7,
  },
  {
    label: 'Non',
    value: 3,
  },
  {
    label: 'Locataire',
    value: 0,
  },
];
export const dominanceMarche = [
  {
    label: 'Maisons',
    value: 'maison',
  },
  {
    label: 'Appartements',
    value: 'appartement',
  },
];
export const typeBien = [
  {
    label: 'Maison',
    value: 'maison',
  },
  {
    label: 'Appartement',
    value: 'appartement',
  },
];
export const disponibiliteBien = [
  {
    label: 'À la vente',
    value: 3,
  },
  {
    label: 'Plus de 6 mois',
    value: 0,
  },
];
export const situationGeographique = [
  {
    label: 'Ville du consultant',
    value: 10,
  },
  {
    label: 'Autre',
    value: 5,
  },
];
export const dossierComplet = [
  {
    label: 'Oui',
    value: 2,
  },
  {
    label: 'Non',
    value: 0,
  },
];
export const panneau = [
  {
    label: 'Oui',
    value: 3,
  },
  {
    label: 'Non',
    value: 0,
  },
];
