import styled from 'styled-components';

const ButtonCountStyle = styled.div`
  display: flex;
  //height: 30px;
  width: 300px;
  margin-right: 2rem;
  //margin-bottom: 2rem;
  border-radius: 2.5px;
  border: solid 2px #21ba45;
  justify-content: center;
  background-color: #4caf50;
  align-items: center;
  color: white;
  cursor: pointer;
  img {
    width: 15px;
    margin-right: 10px;
    margin-left: 10px;
    fill: white;
  }
  .result {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 50px;
    background-color: white;
    color: #4caf50;
  }

  .ui-button {
    width: 260px;
  }

  @media screen and (max-width: 640px) {
    margin-right: 0px;
  }
`;

export default ButtonCountStyle;
