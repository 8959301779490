import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  findAll,
  findConsultantById,
  findConsultantByMail,
  updateConsultant,
} from '../../service/consultantsApi';

export const fetchConsultants = createAsyncThunk(
  'consultants/getAll',
  async ({ currentPage, search, itemsPerPage, actif }, thunkAPI) => {
    try {
      const response = await findAll(currentPage, search, itemsPerPage, actif);
      const responseData = await response;

      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(responseData.data);
      }
      throw thunkAPI.rejectWithValue(responseData);
    } catch (e) {
      throw thunkAPI.rejectWithValue(e.response.data);
    }
  },
);
export const fetchConsultantByEmail = createAsyncThunk(
  'consultants/getByEmail',
  async (email, thunkAPI) => {
    try {
      const response = await findConsultantByMail(email);
      const responseData = await response;
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(responseData.data['hydra:member'][0]);
      }
      throw thunkAPI.rejectWithValue(responseData);
    } catch (e) {
      throw thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const fetchConsultantById = createAsyncThunk(
  'consultants/getById',
  async (id, thunkAPI) => {
    try {
      const response = await findConsultantById(id);
      const responseData = await response;
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(responseData.data);
      }
      throw thunkAPI.rejectWithValue(responseData.data);
    } catch (e) {
      throw thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const patchConsultant = createAsyncThunk(
  'consultants/patchConsultant',
  async (
    {
      actifPresentation,
      actif,
      actifMooveo,
      dateNaissance,
      rsac,
      id,
      badges,
      roles,
      reseauxSociaux,
      presentation,
      countPresentation,
    },
    thunkAPI,
  ) => {
    try {
      const response = await updateConsultant(
        actifPresentation,
        actif,
        actifMooveo,
        dateNaissance,
        rsac,
        id,
        badges,
        roles,
        reseauxSociaux,
        presentation,
        countPresentation,
      );
      const responseData = await response;
      return thunkAPI.fulfillWithValue(responseData.data);
    } catch (e) {
      throw thunkAPI.rejectWithValue(e.response);
    }
  },
);

const consultantsSlice = createSlice({
  name: 'consultants',
  initialState: {
    consultants: {},
    loading: false,
    error: false,
    errorMessage: '',
  },
  reducers: {},
  extraReducers: {
    [fetchConsultants.fulfilled]: (state, { payload }) => ({
      ...state,
      consultants: payload,
      loading: false,
      error: false,
      errorMessage: '',
    }),
    [fetchConsultants.rejected]: (state) => ({
      ...state,
      consultants: {},
      loading: false,
      error: true,
    }),
    [fetchConsultants.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchConsultantById.fulfilled]: (state, { payload }) => ({
      ...state,
      editConsultant: payload,
      loading: false,
      error: false,
      errorMessage: '',
    }),
    [fetchConsultantById.rejected]: (state) => ({
      ...state,
      editConsultant: {},
      loading: false,
      error: true,
    }),
    [fetchConsultantById.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchConsultantByEmail.fulfilled]: (state, { payload }) => ({
      ...state,
      consultant: payload,
      loading: false,
      error: false,
      errorMessage: '',
    }),
    [fetchConsultantByEmail.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchConsultantByEmail.rejected]: (state) => ({
      ...state,
      consultant: {},
      loading: false,
      error: true,
    }),
    [patchConsultant.fulfilled]: (state, { payload }) => ({
      ...state,
      consultant: payload,
      loading: false,
      error: false,
      errorMessage: '',
    }),
    [patchConsultant.rejected]: (state) => ({
      ...state,
      consultant: {},
      loading: false,
      error: true,
    }),
    [patchConsultant.pending]: (state) => ({
      ...state,
      loading: true,
    }),
  },
});

export default consultantsSlice.reducer;
