import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import ListItem from '@mui/material/ListItem';
import makeStyles from '@mui/styles/makeStyles';
import { CalendarMonthOutlined } from '@mui/icons-material';
import {
  AccountCircle,
  Home as HomeIcon,
  Score as ScoreIcon,
  Work,
} from '@material-ui/icons';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '../hooks/useWindowSize';
import breakpoints from '../helpers/breakpoints';
import {
  Dropdown,
  LinkItem,
  ListItems,
  Menu,
  Nav,
  NavResponsive,
} from './layout/navbar/styles';
import Logo from '../styles/global/Logo';
import Logout from './Logout';
import rolesConstants from '../constants/security';
import { fetchConsultantByEmail } from '../features/consultant/consultantsSlice';
import { menu, synergies } from '../constants/menu';
import SideBarItem from './SideBarItem';

function SideBar({ children }) {
  const [openDropdownMySpace, setOpenDropdownMySpace] = useState(false);
  const [openDropdownMyCompany, setOpenDropdownMyCompany] = useState(false);
  const [openDropdownMyTools, setOpenDropdownMyTools] = useState(false);
  const [openDropdownMyLinks, setOpenDropdownMyLinks] = useState(false);
  const [openDropdownMyContacts, setOpenDropdownMyContacts] = useState(false);
  const [openDropdownMyPartners, setOpenDropdownMyPartners] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const size = useWindowSize();
  const token = localStorage.getItem('token') || false;
  const user = token ? jwtDecode(token) : null;

  const { roles, username } = user;

  const { consultant, loading } = useSelector((state) => state.consultants);
  const dispatch = useDispatch();
  useEffect(() => {
    let isMounted = true;
    dispatch(fetchConsultantByEmail(username));
    return () => {
      isMounted = false; // Mark the component as unmounted
    };
  }, [dispatch]);
  function hasRole(userRoles, targetRoles) {
    return targetRoles.some((role) => userRoles.includes(role));
  }
  const handleOpenDropdownMyPartners = () => {
    setOpenDropdownMyPartners(true);
  };
  const handleHideDropdownMyPartners = () => {
    setOpenDropdownMyPartners(false);
  };
  const handleOpenDropdownMyContacts = () => {
    setOpenDropdownMyContacts(true);
  };
  const handleHideDropdownMyContacts = () => {
    setOpenDropdownMyContacts(false);
  };
  const handleOpenDropdownMySpace = () => {
    setOpenDropdownMySpace(true);
  };
  const handleHideDropdownMySpace = () => {
    setOpenDropdownMySpace(false);
  };
  const handleOpenDropdownMyTools = () => {
    setOpenDropdownMyTools(true);
  };
  const handleHideDropdownMyTools = () => {
    setOpenDropdownMyTools(false);
  };
  const handleOpenDropdownMyCompany = () => {
    setOpenDropdownMyCompany(true);
  };
  const handleHideDropdownMyCompany = () => {
    setOpenDropdownMyCompany(false);
  };

  const handleOpenDropdownMyLinks = () => {
    setOpenDropdownMyLinks(true);
  };
  const handleHideDropdownMyLinks = () => {
    setOpenDropdownMyLinks(false);
  };
  const useStyles = makeStyles(() => ({
    icons: {
      width: '3em',
      height: '100%',
      paddingRight: '1em',
      objectFit: 'contain',
    },
  }));
  const styles = useStyles();
  return (
    <>
      <Nav>
        <NavResponsive>
          <Link className="logo" to="/">
            <Logo src="/images/logo_bonagent.png" alt="" />
          </Link>
          <button
            type="button"
            title="Afficher le menu"
            id="nav-toggle"
            onClick={() => {
              setOpenMenu(!openMenu);
              setOpenDropdownMySpace(false);
              setOpenDropdownMyCompany(false);
              setOpenDropdownMyTools(false);
              setOpenDropdownMyLinks(false);
              setOpenDropdownMyContacts(false);
            }}
            className={openMenu ? 'active' : null}
          >
            <span />
          </button>
        </NavResponsive>
        <Menu className={openMenu ? 'open' : null} open>
          <ListItems>
            <LinkItem className="logo">
              <Link to="/">
                <Logo src="/images/logo_bonagent.png" alt="logo lebonagent" />
              </Link>
            </LinkItem>
            <LinkItem
              onMouseEnter={
                size.width < breakpoints.maxNotebook
                  ? null
                  : () => handleOpenDropdownMySpace()
              }
              onMouseLeave={
                size.width < breakpoints.maxNotebook
                  ? null
                  : () => handleHideDropdownMySpace()
              }
              onClick={() => setOpenDropdownMySpace(!openDropdownMySpace)}
            >
              <Link to="/">
                Mon espace
                <img className="chevron" src="/svg/chevron.svg" alt="" />
              </Link>
              <Dropdown className={openDropdownMySpace && 'open'}>
                <li>
                  {consultant && (
                    <ListItem
                      className="sidebarItem"
                      component={Link}
                      to={`/profil/${consultant && consultant.id}`}
                    >
                      <AccountCircle fontSize="small" className="mr-1" />
                      Mon profil
                    </ListItem>
                  )}
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu['Agenda reseau'].icon}
                    text={menu['Agenda reseau'].text}
                    target={menu['Agenda reseau'].target}
                    href={menu['Agenda reseau'].href}
                  />
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu.Formation.icon}
                    text={menu.Formation.text}
                    target={menu.Formation.target}
                    href={menu.Formation.href}
                  />
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu['Espace Pro'].icon}
                    text={menu['Espace Pro'].text}
                    target={menu['Espace Pro'].target}
                    href={menu['Espace Pro'].href}
                  />
                </li>
              </Dropdown>
            </LinkItem>
            <LinkItem
              onMouseEnter={
                size.width < breakpoints.maxNotebook
                  ? null
                  : () => handleOpenDropdownMyLinks()
              }
              onMouseLeave={
                size.width < breakpoints.maxNotebook
                  ? null
                  : () => handleHideDropdownMyLinks()
              }
              onClick={() => setOpenDropdownMyLinks(!openDropdownMyLinks)}
            >
              <Link to="/">
                Mon réseau
                <img className="chevron" src="/svg/chevron.svg" alt="" />
              </Link>
              <Dropdown className={openDropdownMyLinks && 'open'}>
                <li>
                  <ListItem
                    className="sidebarItem"
                    component={Link}
                    to="/inter-agent"
                  >
                    <SupervisorAccountIcon fontSize="small" className="mr-1" />
                    Inter Agent
                  </ListItem>
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu.Basile.icon}
                    text={menu.Basile.text}
                    target={menu.Basile.target}
                    href={menu.Basile.href}
                  />
                  <>
                    {hasRole(roles, [
                      rolesConstants.AMBASSADEUR,
                      rolesConstants.ADMIN,
                    ]) && (
                      <ListItem
                        className="sidebarItem"
                        component={Link}
                        to="/mes-evenements"
                      >
                        <CalendarMonthOutlined
                          fontSize="small"
                          className="mr-1"
                        />
                        Mes événements
                      </ListItem>
                    )}
                  </>
                </li>
              </Dropdown>
            </LinkItem>
            <LinkItem
              onMouseEnter={
                size.width < breakpoints.maxNotebook
                  ? null
                  : () => handleOpenDropdownMyTools()
              }
              onMouseLeave={
                size.width < breakpoints.maxNotebook
                  ? null
                  : () => handleHideDropdownMyTools()
              }
              onClick={() => setOpenDropdownMyTools(!openDropdownMyTools)}
            >
              <Link to="/">
                Mes outils
                <img className="chevron" src="/svg/chevron.svg" alt="" />
              </Link>
              <Dropdown className={openDropdownMyTools && 'open'}>
                <li>
                  <ListItem
                    className="sidebarItem"
                    component={Link}
                    to="/mes-estimations"
                  >
                    <HomeIcon fontSize="small" className="icon mr-1" />
                    Mes estimations
                  </ListItem>
                  <ListItem
                    className="sidebarItem"
                    component={Link}
                    to="/scoring"
                  >
                    <ScoreIcon fontSize="small" className="icon mr-1" />
                    SAM
                  </ListItem>
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu.Yanport.icon}
                    text={menu.Yanport.text}
                    target={menu.Yanport.target}
                    href={menu.Yanport.href}
                  />

                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu.Protexa.icon}
                    text={menu.Protexa.text}
                    target={menu.Protexa.target}
                    href={menu.Protexa.href}
                  />
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu['Rhinov pro'].icon}
                    text={menu['Rhinov pro'].text}
                    target={menu['Rhinov pro'].target}
                    href={menu['Rhinov pro'].href}
                  />
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu.TransacNet.icon}
                    text={menu.TransacNet.text}
                    target={menu.TransacNet.target}
                    href={menu.TransacNet.href}
                  />
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu.Nodalview.icon}
                    text={menu.Nodalview.text}
                    target={menu.Nodalview.target}
                    href={menu.Nodalview.href}
                  />
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu.ImmoData.icon}
                    text={menu.ImmoData.text}
                    target={menu.ImmoData.target}
                    href={menu.ImmoData.href}
                  />
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu.Danim.icon}
                    text={menu.Danim.text}
                    target={menu.Danim.target}
                    href={menu.Danim.href}
                  />
                </li>
              </Dropdown>
            </LinkItem>
            <LinkItem
              onMouseEnter={
                size.width < breakpoints.maxNotebook
                  ? null
                  : () => handleOpenDropdownMyCompany()
              }
              onMouseLeave={
                size.width < breakpoints.maxNotebook
                  ? null
                  : () => handleHideDropdownMyCompany()
              }
              onClick={() => setOpenDropdownMyCompany(!openDropdownMyCompany)}
            >
              <Link to="/">
                Mes actus
                <img className="chevron" src="/svg/chevron.svg" alt="chevron" />
              </Link>
              <Dropdown className={openDropdownMyCompany && 'open'}>
                <li>
                  <ListItem
                    className="sidebarItem"
                    component={Link}
                    to="/statistiques-entreprise"
                  >
                    <Work fontSize="small" className="icon mr-1" />
                    Statistiques
                  </ListItem>
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu.Presse.icon}
                    text={menu.Presse.text}
                    target={menu.Presse.target}
                    href={menu.Presse.href}
                  />
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    icon={menu['Au coeur '].icon}
                    text={menu['Au coeur '].text}
                    target={menu['Au coeur '].target}
                    href={menu['Au coeur '].href}
                  />
                </li>
              </Dropdown>
            </LinkItem>
            <LinkItem
              onMouseEnter={
                size.width < breakpoints.maxNotebook
                  ? null
                  : () => handleOpenDropdownMyContacts()
              }
              onMouseLeave={
                size.width < breakpoints.maxNotebook
                  ? null
                  : () => handleHideDropdownMyContacts()
              }
              onClick={() => setOpenDropdownMyContacts(!openDropdownMyContacts)}
            >
              <Link to="/">
                Mes Contacts
                <img className="chevron" src="/svg/chevron.svg" alt="chevron" />
              </Link>
              <Dropdown className={openDropdownMyContacts && 'open'}>
                <li>
                  <ListItem
                    className="sidebarItem"
                    component={Link}
                    to="/contact/citya"
                  >
                    Formulaire Citya
                  </ListItem>{' '}
                  <ListItem
                    className="sidebarItem"
                    component={Link}
                    to="/contact/saint-pierre-assurances"
                  >
                    Formulaire St Pierre Assurances
                  </ListItem>{' '}
                  <ListItem
                    className="sidebarItem"
                    component={Link}
                    to="/contact/snexi"
                  >
                    Formulaire Snexi
                  </ListItem>{' '}
                  <ListItem
                    className="sidebarItem"
                    component={Link}
                    to="/contact/sphere-immo"
                  >
                    Formulaire Sphère Immo
                  </ListItem>{' '}
                  <ListItem
                    className="sidebarItem"
                    component={Link}
                    to="/contact/api"
                  >
                    Formulaire API Financement
                  </ListItem>{' '}
                  <ListItem
                    className="sidebarItem"
                    component={Link}
                    to="/contact/as-et-associes"
                  >
                    Formulaire AS & Associes
                  </ListItem>
                  <ListItem
                    className="sidebarItem"
                    component={Link}
                    to="/contact/cousin-hub"
                  >
                    Formulaire Cousin HUB
                  </ListItem>
                </li>
              </Dropdown>
            </LinkItem>
            <LinkItem
              onMouseEnter={
                size.width < breakpoints.maxNotebook
                  ? null
                  : () => handleOpenDropdownMyPartners()
              }
              onMouseLeave={
                size.width < breakpoints.maxNotebook
                  ? null
                  : () => handleHideDropdownMyPartners()
              }
              onClick={() => setOpenDropdownMyPartners(!openDropdownMyPartners)}
            >
              <Link to="/">
                Mes Partenaires
                <img className="chevron" src="/svg/chevron.svg" alt="chevron" />
              </Link>
              <Dropdown className={openDropdownMyPartners && 'open'}>
                <li>
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    text={synergies.Citya.libelle}
                    href={synergies.Citya.link[1]}
                    target={synergies.Citya.blank}
                    icon={
                      <img
                        src="/svg/citya.svg"
                        alt="citya"
                        className={styles.icons}
                      />
                    }
                  />
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    text={synergies['St Pierre Assurances'].libelle}
                    href={synergies['St Pierre Assurances'].link[1]}
                    target={synergies['St Pierre Assurances'].blank}
                    icon={
                      <img
                        src="/svg/stpierre.svg"
                        alt="Stpierre"
                        className={styles.icons}
                      />
                    }
                  />{' '}
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    text={synergies.Snexi.libelle}
                    href={synergies.Snexi.link[1]}
                    target={synergies.Snexi.blank}
                    icon={
                      <img
                        src="/svg/snexi.svg"
                        alt="snexi"
                        className={styles.icons}
                      />
                    }
                  />{' '}
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    text={synergies['Sphere Immo'].libelle}
                    href={synergies['Sphere Immo'].link[1]}
                    target={synergies['Sphere Immo'].blank}
                    icon={
                      <img
                        src="/svg/sphere.svg"
                        alt="sphereImmo"
                        className={styles.icons}
                      />
                    }
                  />{' '}
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    text={synergies['Api Financement'].libelle}
                    href={synergies['Api Financement'].link[1]}
                    target={synergies['Api Financement'].blank}
                    icon={
                      <img
                        src="/svg/api.svg"
                        alt="API"
                        className={styles.icons}
                      />
                    }
                  />{' '}
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    text={synergies['As & Associes'].libelle}
                    href={synergies['As & Associes'].link[1]}
                    target={synergies['As & Associes'].blank}
                    icon={
                      <img
                        src="/svg/as.png"
                        alt="API"
                        className={styles.icons}
                      />
                    }
                  />
                  <SideBarItem
                    className="sidebarItem"
                    key={Math.random()}
                    text={synergies['Cousin HUB'].libelle}
                    href={synergies['Cousin HUB'].link[1]}
                    target={synergies['Cousin HUB'].blank}
                    icon={
                      <img
                        src="/svg/cousinhub.svg"
                        alt="Cousin-hub"
                        className={styles.icons}
                      />
                    }
                  />
                </li>
              </Dropdown>
            </LinkItem>
          </ListItems>
          <LinkItem>
            <Logout />
          </LinkItem>
        </Menu>
      </Nav>
      {children}
    </>
  );
}

export default SideBar;
