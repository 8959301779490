import {
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import HeightIcon from '@mui/icons-material/Height';
import Pagination from '@mui/material/Pagination';
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import { debounce } from 'lodash';
import Backdrop from '@mui/material/Backdrop';
import SideBar from '../components/SideBar';
import { API_LBA, ESTIMATION_API } from '../constants/routes';
import axios from '../helpers/axios';
import {
  deleteEstimation,
  findEstimation,
  findEstimationAdmin,
} from '../service/estimationApi';
import useErrorHandler from '../hooks/useErrorHandler';
import { useStyles } from '../styles/estimation/Classes';
import TitlePage from '../components/TitlePage';

function GestionEstimation() {
  const token = localStorage.getItem('token') || false;
  const user = token ? jwtDecode(token) : null;
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [order, setOrder] = useState('[dateReception]=desc');
  const [estimation, setEstimation] = useState([]);
  const [pdf, setPdf] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState(null);
  const { errorHandler } = useErrorHandler();

  const itemsPerPage = 12;

  useEffect(() => {
    setIsLoading(true);
    fetchEstimation();
    return () => {
      setEstimation([]);
    };
  }, [currentPage, order, pdf]);

  const fetchEstimation = async () => {
    let data;
    try {
      if (user.roles.includes('ROLE_ADMIN')) {
        data = await findEstimationAdmin(currentPage, itemsPerPage, order, pdf);
      } else {
        data = await findEstimation(
          currentPage,
          itemsPerPage,
          order,
          user.username,
          pdf,
        );
      }
      setEstimation(data['hydra:member']);
      setTotalPages(
        Math.ceil(estimation ? data['hydra:totalItems'] / itemsPerPage : 0),
      );
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  // debounce
  const updateQuery = async () => {
    let data;
    if (search === null) return;
    try {
      setIsLoading(true);
      data = await findEstimationAdmin(
        currentPage,
        itemsPerPage,
        order,
        pdf,
        search,
      );
      setEstimation(data['hydra:member']);
      setTotalPages(
        Math.ceil(estimation ? data['hydra:totalItems'] / itemsPerPage : 0),
      );
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const delayedQuery = useCallback(debounce(updateQuery, 500), [search]);

  useEffect(() => {
    delayedQuery();
    setCurrentPage(1);
    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel;
  }, [delayedQuery]);

  const handleSearch = ({ currentTarget }) => {
    setSearch(currentTarget.value);
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    const { myValue } = event.currentTarget.dataset;
    setOrder(myValue);
  };

  const handleChangeSwitch = (event) => {
    setPdf(event.target.checked);
  };

  const handleChangeStatut = async (id, etat) => {
    const originalEstimations = [...estimation];
    setEstimation(estimation.filter((estim) => estim.id !== id));
    try {
      const finalData = {
        edit: etat !== false,
        statut: !etat,
      };
      await putNewStatut(finalData, id);
    } catch (error) {
      errorHandler(error);
      setEstimation(originalEstimations);
    } finally {
      setIsLoading(false);
    }
  };

  const putNewStatut = (finalData, id) => {
    setIsLoading(true);
    return axios
      .put(`${ESTIMATION_API}/${id}`, finalData)
      .then(() => {
        toast.success("État de l'estimation modifié");
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  // Gestion de la suppression
  const handleDelete = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ?')) {
      setIsLoading(true);
      const originalEstimations = [...estimation];
      try {
        await deleteEstimation(id);
        setEstimation(estimation.filter((estim) => estim.id !== id));
      } catch (error) {
        errorHandler(error);
        setEstimation(originalEstimations);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const useStylesGestion = makeStyles((theme) => ({
    container: {
      padding: '50px',
      [theme.breakpoints.down('md')]: {
        padding: '10px',
      },
    },
    action: {
      marginRight: '5px',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '0',
      },
    },
    statut: {
      marginRight: '5px',
      height: '32px',
    },
    buttonDdl: {
      width: '150px',
      height: '32px',
    },
    ddl: {
      color: 'white',
      width: '150px',
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    textSuccess: {
      color: 'green',
    },
    textEnCours: {
      color: '#00AFE6',
    },
  }));

  const classesEstimation = useStylesGestion();
  const classes = useStyles();

  return (
    <SideBar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid>
          <TitlePage
            title="Mes estimations"
            underTitle={'Consultez toutes vos estimations déjà générées\n'}
          />
        </Grid>
        <Grid className="p-5" item xs={12}>
          <TableContainer component={Paper}>
            <Grid container display="flex" justifyContent="flex-start">
              <Link className="" to="/estimation/new">
                <Button className="m-1" variant="contained" color="primary">
                  Créer une estimation
                </Button>
              </Link>
              {user.roles.includes('ROLE_ADMIN') && (
                <Button
                  className="m-1"
                  variant="contained"
                  color="primary"
                  href={`${API_LBA}/estimation/export`}
                  download="export_estimation.csv"
                >
                  Export CSV
                </Button>
              )}{' '}
              {user.roles.includes('ROLE_ADMIN') && (
                <TextField
                  size="small"
                  className="m-1"
                  onChange={handleSearch}
                  value={search || ''}
                  label="Rechercher une estimation par nom du consultant"
                />
              )}
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Client</TableCell>
                  <TableCell>Email client</TableCell>
                  <TableCell>PDF</TableCell>
                  <TableCell>
                    État
                    <Switch
                      checked={pdf}
                      onChange={handleChangeSwitch}
                      name="checkedA"
                      color="primary"
                      disabled={isLoading}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </TableCell>
                  <TableCell>
                    Date
                    <Button
                      className="ml-1"
                      size="small"
                      color="primary"
                      onClick={handleClick}
                    >
                      <HeightIcon fontSize="small" />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem
                        data-my-value="[dateReception]=desc"
                        onClick={handleClose}
                      >
                        Décroissant
                      </MenuItem>
                      <MenuItem
                        data-my-value="[dateReception]=asc"
                        onClick={handleClose}
                      >
                        Croissant
                      </MenuItem>
                    </Menu>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              {estimation !== undefined &&
                estimation.map((item) => (
                  <TableBody key={Math.random()}>
                    <TableRow>
                      <TableCell>{item.client}</TableCell>
                      <TableCell>{item.emailClient}</TableCell>
                      <TableCell>
                        <Button
                          className={classesEstimation.buttonDdl}
                          variant="contained"
                          size="large"
                          color={item.path ? 'primary' : 'secondary'}
                          disabled={!item.path}
                        >
                          <Typography variant="caption">
                            <a
                              href={`${API_LBA}/estimation/pdf/${item.id}`}
                              download
                              className={classesEstimation.ddl}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Ouvrir le pdf
                            </a>
                          </Typography>
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Typography
                          className={
                            item.path
                              ? classesEstimation.textSuccess
                              : classesEstimation.textEnCours
                          }
                        >
                          {item.statut ? 'Terminée' : 'En cours'}
                          {!item.path ? '/Incomplet' : null}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {new Date(item.dateReception).toLocaleDateString(
                          'fr-FR',
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          className={classesEstimation.statut}
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => {
                            handleChangeStatut(item.id, item.statut);
                          }}
                        >
                          {!item.statut ? 'Terminer' : 'En cours'}
                        </Button>
                        {item.statut ? null : (
                          <Button
                            className={classesEstimation.action}
                            variant="contained"
                            color="info"
                            component={Link}
                            to={`/estimation/${item.id}`}
                          >
                            <EditIcon fontSize="small" />
                          </Button>
                        )}

                        <Button
                          className="m"
                          variant="contained"
                          color="error"
                          onClick={() => handleDelete(item.id)}
                        >
                          <DeleteForeverIcon fontSize="small" />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
            </Table>{' '}
            {!isLoading ? (
              <Pagination
                count={!Number.isNaN(totalPages) ? totalPages : 0}
                page={currentPage}
                onChange={handleChange}
                size="large"
                shape="rounded"
                siblingCount={0}
              />
            ) : null}
          </TableContainer>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={!!isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      ;
    </SideBar>
  );
}

export default GestionEstimation;
