import { ErrorMessage } from '@hookform/error-message';
import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useStyles } from '../../styles/estimation/Classes';

function ProjetImmo({ control, errors }) {
  const classes = useStyles();

  return (
    <Grid className={classes.field} item xs={12} md={12}>
      <Typography className="title text toto">Projet Immobilier</Typography>
      <Controller
        render={({ field }) => (
          <TextField
            placeholder="Maison"
            label="Type de bien"
            type="text"
            inputRef={field.ref}
            {...field}
          />
        )}
        name="typeBien"
        control={control}
        defaultValue=""
        rules={{
          required: 'Veuillez compléter ce champ.',
          maxLength: {
            value: 255,
            message: 'Le nombre maximum de caractères a été dépassé',
          },
        }}
      />
      <ErrorMessage
        errors={errors}
        name="typeBien"
        render={({ message }) => (
          <Typography className="error" color="secondary">
            {message}
          </Typography>
        )}
      />
      {/*  <Controller
        render={() => (
          <Autocomplete
            freeSolo
            id="adress"
            onInputChange={(event, request) => {
              setQuery(request);
              setLastAdress(query);
              setError((prevState) => ({
                ...prevState,
                localisation: '',
              }));
            }}
            disableClearable
            options={adress && adress ? adress : []}
            getOptionLabel={(option) => option.properties.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Localisation"
                helperText="Pour les adresses en bis ou ter veuillez écrire ainsi : 47bis ou 47ter."
                type="text"
                placeholder="47 rue nationale 37000 Tours"
                InputProps={{ ...params.InputProps, type: 'search' }}
              />
            )}
          />
        )}
        name="localisation"
        control={control}
        defaultValue=""
        rules={{
          required: false,
          maxLength: {
            value: 255,
            message: 'Le nombre maximum de caractères a été dépassé',
          },
        }}
      />
      <Typography color="secondary">{error.localisation}</Typography> */}
      <Controller
        render={({ field }) => (
          <TextField
            placeholder="Maison"
            label="Description du bien"
            type="text"
            inputRef={field.ref}
            {...field}
          />
        )}
        name="descriptionBien"
        control={control}
        defaultValue=""
        rules={{
          required: 'Veuillez compléter ce champ.',
          maxLength: {
            value: 255,
            message: 'Le nombre maximum de caractères a été dépassé',
          },
        }}
      />
      <ErrorMessage
        errors={errors}
        name="descriptionBien"
        render={({ message }) => (
          <Typography className="error" color="secondary">
            {message}
          </Typography>
        )}
      />
    </Grid>
  );
}

export default ProjetImmo;
