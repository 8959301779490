import styled from 'styled-components';

const MySpaceStyle = styled.div`
  .icon {
    transition: transform 0.2s ease-in-out;
    color: #3f525c;
  }

  .iconButtonLabel {
    display: flex;
    flex-direction: column;
  }

  .icon:hover {
    transform: scale(1.15);
    fill: rgb(0, 175, 230);
  }

  .MuiButtonBase-root:hover {
    background-color: transparent;
  }

  .icon-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  svg {
    font-size: 60px;
  }
`;
export default MySpaceStyle;
