import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import SideBar from '../../components/SideBar';
import Contact from '../../components/synergie/Contact';
import { SYNERGIES_API } from '../../constants/routes';
import axios from '../../helpers/axios';
import { style, useStyles } from '../../styles/estimation/Classes';
import AutocompleteAddress from '../../components/AutocompleteAddress';
import { COUSIN_HUB } from '../../constants/synergyCategories';

function SynergieCousinHub() {
  const form = useForm();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = form;

  const [load, setLoad] = useState(false);
  const [lastAdress, setLastAdress] = useState();
  const [error, setError] = useState({
    localisation: '',
    contact: '',
  });

  const onSubmit = (data) => {
    const finalData = {
      ...data,
      date: new Date(),
      localisation: lastAdress,
      synergieCategorie: COUSIN_HUB,
    };

    if (finalData.localisation === '' || finalData.localisation === undefined) {
      setError((prevState) => ({
        ...prevState,
        localisation: 'Veuillez compléter ce champ.',
      }));
    } else {
      setLoad(true);
      axios
        .post(SYNERGIES_API, finalData)
        .then(() => {
          setLoad(false);
          toast.success('Formulaire envoyé');
        })
        .catch(() => {
          setLoad(false);
          toast.error("Echec dans l'envoi du formulaire");
        });
    }
    reset();
  };

  const classes = useStyles();

  const iconClasses = style();

  return (
    <SideBar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} xl={12}>
          <Typography className="ariane mt-3" compoment="p" align="center">
            Déclarer un contact Cousin HUB
          </Typography>
          <Typography className="ariane m-1" compoment="p" align="center">
            <img className={iconClasses.icon} src="/svg/cousinhub.png" alt="" />
          </Typography>
        </Grid>
        <Grid className={classes.container} item xs={11} md={11} lg={8}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Contact
              name="nom"
              firstname="prenom"
              telephone="telephone"
              email="email"
              title="Contact"
              control={control}
              errors={errors}
              error={error}
              setError={setError}
              required
            />
            <AutocompleteAddress
              setLastAdress={setLastAdress}
              control={control}
              errors={errors}
              error={error}
              setError={setError}
              title="Localisation"
              label="Adresse complète"
            />
            <Grid className={classes.field} item xs={12} md={12}>
              <Typography className="title text">Ancien occupant</Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    placeholder="Maira"
                    label="Nom"
                    type="text"
                    inputRef={field.ref}
                    {...field}
                  />
                )}
                name="ancien_occupant_nom"
                control={control}
                defaultValue=""
                rules={{
                  maxLength: {
                    value: 255,
                    message: 'Le nombre maximum de caractères a été dépassé',
                  },
                }}
              />
              <ErrorMessage
                errors={errors}
                name="ancien_occupant_nom"
                render={({ message }) => (
                  <Typography className="error" color="secondary">
                    {message}
                  </Typography>
                )}
              />
              <Controller
                render={({ field }) => (
                  <TextField
                    placeholder="Maddy"
                    label="Prénom"
                    type="text"
                    inputRef={field.ref}
                    {...field}
                  />
                )}
                name="ancien_occupant_prenom"
                control={control}
                defaultValue=""
                rules={{
                  maxLength: {
                    value: 255,
                    message: 'Le nombre maximum de caractères a été dépassé',
                  },
                }}
              />
              <ErrorMessage
                errors={errors}
                name="ancien_occupant_prenom"
                render={({ message }) => (
                  <Typography className="error" color="secondary">
                    {message}
                  </Typography>
                )}
              />
              <Controller
                render={({ field }) => (
                  <TextField
                    placeholder="Il s’agit d’un numéro d’identification à 14 chiffres"
                    label="Numéro de PDL (ou PRM) ou numéro de compteur d’électricité :"
                    type="number"
                    inputRef={field.ref}
                    {...field}
                  />
                )}
                name="num_compteur_elec"
                control={control}
                defaultValue=""
                rules={{
                  maxLength: {
                    value: 14,
                    message: 'Le nombre maximum de caractères a été dépassé',
                  },
                }}
              />
              <ErrorMessage
                errors={errors}
                name="num_compteur_elec"
                render={({ message }) => (
                  <Typography className="error" color="secondary">
                    {message}
                  </Typography>
                )}
              />
              <Controller
                render={({ field }) => (
                  <TextField
                    placeholder="Il s’agit d’un numéro d’identification à 14 chiffres"
                    label="Numéro de PCE ou numéro de compteur gaz :"
                    type="number"
                    inputRef={field.ref}
                    {...field}
                  />
                )}
                name="num_compteur_gaz"
                control={control}
                defaultValue=""
                rules={{
                  maxLength: {
                    value: 14,
                    message: 'Le nombre maximum de caractères a été dépassé',
                  },
                }}
              />
              <ErrorMessage
                errors={errors}
                name="num_compteur_gaz"
                render={({ message }) => (
                  <Typography className="error" color="secondary">
                    {message}
                  </Typography>
                )}
              />
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              item
            >
              <Button
                color="primary"
                className="mt-1 mb-4"
                type="submit"
                size="large"
                variant="contained"
              >
                Envoyer
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={!!load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default SynergieCousinHub;
