export const parseJWT = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    return false;
  }
};

export const getExpirationDate = (jwtToken) => {
  const jwt = parseJWT(jwtToken);
  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};

export const isExpired = (exp) => Date.now() > exp;
