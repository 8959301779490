import { ErrorMessage } from '@hookform/error-message';
import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

function FormText({
  placeholder,
  label,
  name,
  errors,
  control,
  helperText,
  type,
}) {
  return (
    <Grid>
      <Controller
        render={({ field, fieldState }) => (
          <TextField
            helperText={helperText}
            fullWidth
            placeholder={placeholder}
            label={label}
            type={type}
            value={field.value}
            onChange={field.onChange}
            inputRef={field.ref}
            error={fieldState.error}
          />
        )}
        name={name}
        control={control}
        defaultValue=""
        rules={{
          required: 'Veuillez compléter ce champ.',
          maxLength: {
            value: 255,
            message: 'Le nombre maximum de caractères a été dépassé',
          },
        }}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Typography className="error" color="secondary">
            {message}
          </Typography>
        )}
      />
    </Grid>
  );
}

export default FormText;
