import { Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

function PositionImage({ pictures }) {
  const useStyles = makeStyles(() => ({
    root: {
      marginBottom: '30px',
      '& .positionImage': {
        height: '180px',
      },
    },
  }));

  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      {pictures && pictures[0] ? (
        <img
          className="ml-1 mt-1 positionImage"
          src="/images/defaultImageBleu-1.png"
          alt="position des images"
        />
      ) : (
        <img
          className="ml-1 mt-1 positionImage"
          src="/images/defaultImage-1.png"
          alt="position des images"
        />
      )}
      {pictures && pictures[1] ? (
        <img
          className="ml-1 mt-1 positionImage"
          src="/images/defaultImageBleu-2.png"
          alt="position des images"
        />
      ) : (
        <img
          className="ml-1 mt-1 positionImage"
          src="/images/defaultImage-2.png"
          alt="position des images"
        />
      )}
      {pictures && pictures[2] ? (
        <img
          className="ml-1 mt-1 positionImage"
          src="/images/defaultImageBleu-3.png"
          alt="position des images"
        />
      ) : (
        <img
          className="ml-1 mt-1 positionImage"
          src="/images/defaultImage-3.png"
          alt="position des images"
        />
      )}
      {pictures && pictures[3] ? (
        <a
          href="https://www.cadastre.gouv.fr/scpc/accueil.do"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="ml-1 mt-1 positionImage"
            src="/images/defaultImageBleu-4.png"
            alt="position des images"
          />
        </a>
      ) : (
        <a
          href="https://www.cadastre.gouv.fr/scpc/accueil.do"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="ml-1 mt-1 positionImage"
            src="/images/defaultImage-4.png"
            alt="position des images"
          />
        </a>
      )}
      {pictures && pictures[4] ? (
        <img
          className="ml-1 mt-1 positionImage"
          src="/images/defaultImageBleu-5.png"
          alt="position des images"
        />
      ) : (
        <img
          className="ml-1 mt-1 positionImage"
          src="/images/defaultImage-5.png"
          alt="position des images"
        />
      )}
    </Grid>
  );
}

export default PositionImage;
