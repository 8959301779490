import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  Typography,
  TextField,
  FormControl,
  IconButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { clearState, loginRequest } from '../features/auth/authSlice';
import TitlePage from '../components/TitlePage';
import LoginForm from '../styles/pages/login/LoginForm';

function LoginPage() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const history = useHistory();

  const { loading } = useSelector((state) => state.auth); // Update with the correct slice name

  const form = useForm();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = (data) => {
    const credentials = {
      username: data.email,
      password: data.password,
    };
    dispatch(loginRequest(credentials));
    setEmail(data.email);
  };

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, []);

  useEffect(() => {
    if (email) {
      dispatch(clearState());
      history.push({
        pathname: '/',
        state: {
          data: email,
        },
      });
    }
  }, [loading]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const useStyles = makeStyles((theme) => ({
    formContainer: {
      padding: theme.spacing(4),
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderRadius: theme.shape.borderRadius,
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    submitButton: {
      marginTop: theme.spacing(1),
    },

    forgotPasswordLink: {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
      marginTop: theme.spacing(1),
    },
    registerLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
      marginBottom: theme.spacing(0),
    },
    logo: {
      maxWidth: '100px',
      maxHeight: '100px',
    },
  }));
  const classes = useStyles();
  return (
    <LoginForm>
      <Container>
        <TitlePage
          title="MON ENTREPRISE"
          underTitle="            Connectez-vous
"
        />
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6} className={classes.formContainer}>
            <Grid container justifyContent="center" paddingBottom={2}>
              <img
                src="/svg/lba.svg"
                className={classes.logo}
                alt="lebonagent logo"
              />
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth variant="standard">
                <TextField
                  id="email"
                  autoComplete="mail"
                  name="email"
                  className="mb-2"
                  label="Email"
                  placeholder="Email"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors.email ? 'Ce champ est requis' : ''}
                  {...register('email', { required: true })}
                />
                <TextField
                  autoComplete="current-password"
                  id="password"
                  name="password"
                  className="inputLogin "
                  label="Mot de passe de connexion"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Mot de passe de connexion"
                  variant="outlined"
                  error={!!errors.password}
                  helperText={errors.password ? 'Ce champ est requis' : ''}
                  {...register('password', { required: true })}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        className={classes.passwordIcon}
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
              </FormControl>
              <Grid container justifyContent="center" marginTop={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                >
                  Se connecter
                </Button>{' '}
              </Grid>
            </form>
            <Grid container justifyContent="flex-end">
              <Link
                to="/forgotten-password"
                className={classes.forgotPasswordLink}
              >
                Mot de passe oublié ?
              </Link>
            </Grid>
            <Grid container justifyContent="center" marginTop={2}>
              <Typography variant="body2" gutterBottom>
                Devenez Consultant Immobilier Indépendant ?{' '}
                <a
                  className={classes.registerLink}
                  href="https://www.lebonagent.fr/recrutement/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Nous rejoindre{' '}
                </a>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className="containerArche mb-2"
          >
            <img
              className="mb-1 logoArche"
              src="https://media.sas-arche.com/apps_logos_white/arche.svg"
              alt="logo arche"
            />
            <Typography variant="caption" className="textWhite">
              ©{new Date().getFullYear()}, tous droits réservés
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </LoginForm>
  );
}

export default LoginPage;
