import styled from 'styled-components';
import breakpoints from '../../../../helpers/breakpoints';

const ListItems = styled.ul`
  @media only screen and (min-width: ${breakpoints.minLaptop}px) {
    display: flex;
    align-items: center;
    height: 100%;
  }

  @media only screen and (max-width: ${breakpoints.maxNotebook}px) {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 665ms cubic-bezier(0, 0, 0.1, 1),
      transform 665ms cubic-bezier(0, 0, 0.1, 1);
    transition-delay: 0ms, 0ms;

    &:last-child {
      margin-top: 2rem;
      padding-top: 2rem;
      border-top: 1px solid rgb(44, 44, 44);
    }
  }
`;

export default ListItems;
