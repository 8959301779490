import React from 'react';
import PrivateRoute from '../components/PrivateRoute';

/**
 * Retourne un tableau d'éléments `<Route>`
 * @param routes Un tableau de config de routes
 */
const renderRoutes = (routes) =>
  routes.map((route) => (
    <PrivateRoute
      key={route.name}
      name={route.name}
      authorized={route.public}
      rolesAuthorized={route.permission}
      path={route.path}
      exact
    >
      <route.component />
    </PrivateRoute>
  ));

export default renderRoutes;
