/* eslint-disable react/no-array-index-key */

import { FormGroup, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useStyles } from '../../styles/estimation/Classes';

function TypeProjet({ projets, handleChecked, error, register, title, name }) {
  const classes = useStyles();

  const styles = makeStyles(() => ({
    form: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  }));

  const classesContact = styles();

  return (
    <Grid className={classes.field} item xs={12} md={12}>
      <Typography className="title text toto">{title}</Typography>
      <FormGroup className={`p-1 ${classesContact.form}`}>
        {projets.map((text, index) => (
          <label key={index}>
            <input
              type="checkbox"
              data-id={index}
              placeholder={text}
              name={text}
              onClick={handleChecked}
              {...register(name, {
                required: false,
              })}
            />
            <p>{text}</p>
          </label>
        ))}
      </FormGroup>
      <Typography className="error" color="secondary">
        {error.projet}
      </Typography>
    </Grid>
  );
}

export default TypeProjet;
