import { Box, Grid } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SideBar from '../components/SideBar';
import TitlePage from '../components/TitlePage';

function PdfError() {
  const useStyles = makeStyles((theme) => ({
    title: { color: 'red', paddingTop: '50px' },
    img: {
      width: '100%',
      height: '100vh',
      objectFit: 'contain',
    },
  }));
  const classes = useStyles();
  return (
    <SideBar>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
      >
        <TitlePage
          classes={classes.title}
          title="Erreur constatée lors de la génération du fichier PDF."
          underTitle="      Pas de
            participants encore inscrit."
        />
        <Grid className={classes.container} item xs={12} xl={12}>
          <Box>
            <img
              className={classes.img}
              src="/images/manque_de_participant.jpg"
              alt="manque_de_participant"
            />
          </Box>
        </Grid>
      </Grid>
    </SideBar>
  );
}

export default PdfError;
