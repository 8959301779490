import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { findEventById, putEvent } from '../../service/eventApi';

export const fetchEventById = createAsyncThunk(
  'evenement/getById',
  async (id, thunkAPI) => {
    try {
      const response = await findEventById(id);
      const responseData = await response;
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(responseData.data);
      }
      throw thunkAPI.rejectWithValue(responseData);
    } catch (e) {
      throw thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const patchEvent = createAsyncThunk(
  'evenement/patch',
  async (
    { id, date, adresse, adresseComplement, titreEvenement },
    thunkAPI,
  ) => {
    try {
      const response = await putEvent({
        id,
        date,
        adresse,
        adresseComplement,
        titreEvenement,
      });
      const responseData = await response;
      if (response.status === 200) {
        toast.success('Évenement bien modifié.');
        return thunkAPI.fulfillWithValue(responseData.data);
      }
      throw thunkAPI.rejectWithValue(responseData);
    } catch (e) {
      throw thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

const eventSlice = createSlice({
  name: 'evenement',
  initialState: {
    event: {},
    loading: false,
    error: false,
    errorMessage: '',
  },
  reducers: {},
  extraReducers: {
    [fetchEventById.fulfilled]: (state, { payload }) => ({
      ...state,
      event: payload,
      loading: false,
      error: false,
      errorMessage: '',
    }),

    [fetchEventById.rejected]: (state) => ({
      ...state,
      event: {},
      loading: false,
      error: true,
    }),
    [fetchEventById.pending]: (state) => ({
      ...state,
      loading: true,
    }),
  },
});

export default eventSlice.reducer;
