import { Box, Button, FormGroup, Grid, Grow, Typography } from '@mui/material';
import React from 'react';

function MethodeDeux({
  etape,
  error,
  typeBien,
  classes,
  register,
  setRequired,
  setError,
  resultMethodeDeux,
  id,
}) {
  const onClickCheckbox = () => {
    setError((prevState) => ({
      ...prevState,
      checkbox: '',
    }));
  };

  return (
    <>
      {etape >= 4 ? (
        <Grow in>
          <Grid>
            <Typography className="titre" variant="h4" compoment="p">
              Descriptif du <span className="titreSpan">bien</span>
            </Typography>
            <Grid container>
              <Typography className="titleSection" variant="subtitle2">
                Méthode 2 : Pondération
              </Typography>
            </Grid>
            <Grid className={classes.border}>
              <Box className="blueBorder text" borderRight={5}>
                {typeBien !== undefined
                  ? typeBien.map((type) => (
                      <Box
                        key={Math.random()}
                        className="bottomBorder"
                        borderBottom={2}
                        borderLeft={15}
                      >
                        <Grid
                          container
                          item
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          className={classes.checkbox}
                        >
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={3}>
                            <Typography className="ml-1 pt-1 title">
                              {type.titre}
                            </Typography>
                          </Grid>
                          <Box className="cyanBorder" borderLeft={2}>
                            <Grid item xs>
                              <FormGroup className="p-1">
                                {type.check.map((check) => (
                                  <label key={Math.random()}>
                                    <input
                                      type="checkbox"
                                      onClick={onClickCheckbox}
                                      {...register(check.libelle, {
                                        required: false,
                                      })}
                                    />
                                    <p>{check.label}</p>
                                  </label>
                                ))}
                              </FormGroup>
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    ))
                  : null}
              </Box>
              <Typography color="secondary">
                {error ? error.checkbox : null}
              </Typography>
            </Grid>

            <Grid container className="mt-5">
              <Typography className="titleSection" variant="subtitle2">
                {etape < 6
                  ? 'Calculer le résultat de la méthode 2 en fonction des critères précédents'
                  : 'Résultat de la méthode 2 en fonction des critères précédents'}
              </Typography>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              item
              className="border"
            >
              {!id ? (
                <Button
                  color="primary"
                  className="mt-1 mb-2"
                  type="submit"
                  size="large"
                  variant="contained"
                  onClick={() => {
                    setRequired((prevState) => ({
                      ...prevState,
                      required: 'calcul',
                    }));
                  }}
                >
                  Calculer
                </Button>
              ) : null}
              <Typography className="title text">
                {resultMethodeDeux &&
                  resultMethodeDeux.toLocaleString('fr-FR', {
                    style: 'currency',
                    currency: 'EUR',
                  })}
              </Typography>
            </Grid>
            {etape < 5 ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  color="primary"
                  className="mt-1 mb-2"
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setRequired((prevState) => ({
                      ...prevState,
                      required: null,
                    }));
                  }}
                >
                  Suivant
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grow>
      ) : null}
    </>
  );
}

export default MethodeDeux;
