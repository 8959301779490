import {
  Backdrop,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Pagination from '@mui/material/Pagination';
import { LoadingButton } from '@mui/lab';
import Paper from '@mui/material/Paper';
import SideBar from '../../components/SideBar';
import { useStyles } from '../../styles/estimation/Classes';
import Cards from '../../components/interAgent/Cards';
import TitlePage from '../../components/TitlePage';
import {
  findAllConsultantsInArea,
  findAllRegions,
  findDepartementByRegion,
} from '../../service/consultantsApi';

function InterAgentReco() {
  const [consultantByArea, setConsultantByArea] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [region, setRegion] = useState([]);
  const [departements, setDepartements] = useState([]);
  const [regionValue, setRegionValue] = useState('');
  const [departementValue, setDepartementValue] = useState('');
  const [load, setLoad] = useState(true);
  const classes = useStyles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    fetchRegions().then((res) => {
      setRegion(res);
    });
  }, []);

  useEffect(() => {
    if (regionValue !== '') {
      fetchDepartements().then((r) => setDepartements(r));
    }
  }, [currentPage, regionValue]);

  useEffect(() => {
    if (departementValue !== '') {
      fetchConsultantInArea().then((r) => setConsultantByArea(r));
    }
  }, [currentPage, departementValue]);

  const fetchConsultantInArea = async () => {
    setLoad(true);
    try {
      return await findAllConsultantsInArea(departementValue, currentPage);
    } catch (e) {
      throw new Error(e);
    } finally {
      setLoad(false);
    }
  };

  const fetchRegions = async () => {
    setLoad(true);
    try {
      return await findAllRegions();
    } catch (e) {
      throw new Error(e);
    } finally {
      setLoad(false);
    }
  };

  const fetchDepartements = async () => {
    setLoad(true);
    try {
      setConsultantByArea([]);
      return await findDepartementByRegion(regionValue);
    } catch (e) {
      throw new Error(e);
    } finally {
      setLoad(false);
    }
  };

  const handlePagination = (e, value) => {
    setCurrentPage(value);
  };

  return (
    <SideBar>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs={12}
      >
        <TitlePage
          title="Inter Agent"
          underTitle="Merci de sélectionner l'agent avec qui vous souhaitez travailler."
        />
        <Grid
          container
          item
          direction="column"
          alignItems="center"
          component={Paper}
          xs={11}
        >
          <FormControl className="mt-5" sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">Régions</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              onChange={(e) => setRegionValue(e.target.value)}
              input={<OutlinedInput label="Régions" />}
              MenuProps={MenuProps}
              value={regionValue || ''}
            >
              {region.map((name) => (
                <MenuItem key={name.id} value={name.id}>
                  {name.libelle}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {departements.length > 0 ? (
            <FormControl className="mt-5" sx={{ m: 1, width: 300 }}>
              <InputLabel id="demo-multiple-name-label">
                Départements
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                onChange={(e) => setDepartementValue(e.target.value)}
                input={<OutlinedInput label="Régions" />}
                MenuProps={MenuProps}
                value={departementValue || ''}
              >
                {departements.map((name) => (
                  <MenuItem key={name.id} value={name.id}>
                    {name.libelle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <LoadingButton />
          )}
          {consultantByArea.length <= 0 ||
          consultantByArea.pagination['hydra:totalItems'] <= 0 ? (
            <Typography className="mt-5">
              Pas de consultants dans la région selectionnée
            </Typography>
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              className="mt-5"
            >
              {consultantByArea.data.map((consultant, index) => (
                <Cards
                  key={Math.random()}
                  consultant={consultantByArea.data[index]}
                  hiddenRegle={classes.hidden}
                />
              ))}
              <Grid container item alignItems="center" justifyContent="center">
                <Pagination
                  count={
                    !Number.isNaN(
                      consultantByArea.pagination['hydra:totalPages'],
                    )
                      ? consultantByArea.pagination['hydra:totalPages']
                      : 0
                  }
                  page={currentPage}
                  onChange={(e, value) => {
                    handlePagination(e, value);
                  }}
                  size="large"
                  shape="rounded"
                  siblingCount={0}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={!!load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default InterAgentReco;
