import { Button, CircularProgress, Grid, Menu, MenuItem } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import React, { useEffect, useState } from 'react';
import HeightIcon from '@mui/icons-material/Height';
import SideBar from '../../components/SideBar';
import {
  findContact,
  findContactConsultant,
  findContactEstimation,
  findContactProspect,
  findContactRecrutement,
  findContactVente,
} from '../../service/contactApi';
import useErrorHandler from '../../hooks/useErrorHandler';
import TitlePage from '../../components/TitlePage';

function Contact() {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDate, setAnchorElDate] = useState(null);
  const [trie, setTrie] = useState('contact');
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState(false);
  const [order, setOrder] = useState('[date]=desc');
  const { errorHandler } = useErrorHandler();

  // Verifie quand je le trie change pour dispatch les bonnes données en fonction.
  useEffect(() => {
    switch (trie) {
      case 'contact':
        fetchContact(findContact);
        break;
      case 'recrutement':
        fetchContact(findContactRecrutement);
        break;
      case 'estimation':
        fetchContact(findContactEstimation);
        break;
      case 'prospect':
        fetchContact(findContactProspect);
        break;
      case 'vente':
        fetchContact(findContactVente);
        break;
      case 'consultant':
        fetchContact(findContactConsultant);
        break;
      default:
        break;
    }
  }, [currentPage, trie, order]);

  const fetchContact = async (callback) => {
    setIsLoading(true);
    try {
      const data = await callback(currentPage, itemsPerPage, order);
      setContacts(data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    const { myValue } = event.currentTarget.dataset;
    setCurrentPage(1);
    setTrie(myValue);
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClickDate = (event) => {
    setAnchorElDate(event.currentTarget);
  };

  const handleCloseDate = (event) => {
    setAnchorElDate(null);
    const { myValue } = event.currentTarget.dataset;
    setOrder(myValue);
  };

  // pagination
  const totalPages = Math.ceil(contacts['hydra:totalItems'] / itemsPerPage);
  return (
    <SideBar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <TitlePage
          title={`Liste des contacts reçu : ${trie}`}
          underTitle="Consultez les contacts reçu"
        />
        <Grid className="p-5" item xs={12}>
          <TableContainer component={Paper}>
            <div className="m-1">
              <Button
                variant="contained"
                color="primary"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >
                Trier par
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem data-my-value="contact" onClick={handleClose}>
                  Tous les contacts
                </MenuItem>
                <MenuItem data-my-value="recrutement" onClick={handleClose}>
                  Recrutement
                </MenuItem>
                <MenuItem data-my-value="estimation" onClick={handleClose}>
                  Estimation
                </MenuItem>
                <MenuItem data-my-value="prospect" onClick={handleClose}>
                  Prospect
                </MenuItem>
                <MenuItem data-my-value="vente" onClick={handleClose}>
                  Vente
                </MenuItem>
                <MenuItem data-my-value="consultant" onClick={handleClose}>
                  Consultant
                </MenuItem>
              </Menu>
            </div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Prenom</TableCell>
                  <TableCell>Nom</TableCell>
                  <TableCell>Téléphone</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>
                    Date
                    <Button
                      className="ml-1"
                      size="small"
                      color="primary"
                      onClick={handleClickDate}
                    >
                      <HeightIcon fontSize="small" />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorElDate}
                      keepMounted
                      open={Boolean(anchorElDate)}
                      onClose={handleCloseDate}
                    >
                      <MenuItem
                        data-my-value="[date]=desc"
                        onClick={handleCloseDate}
                      >
                        Décroissant
                      </MenuItem>
                      <MenuItem
                        data-my-value="[dategit]=asc"
                        onClick={handleCloseDate}
                      >
                        Croissant
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading && contacts['hydra:member'] !== undefined ? (
                  contacts['hydra:member'].map((item) => (
                    <TableRow key={Math.random()}>
                      <TableCell>{item.prenom}</TableCell>
                      <TableCell>{item.nom}</TableCell>
                      <TableCell>{item.telephone}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.message}</TableCell>
                      <TableCell>
                        {new Date(item.date).toLocaleDateString('fr-FR')}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            {!isLoading ? (
              <Pagination
                count={!Number.isNaN(totalPages) ? totalPages : 0}
                page={currentPage}
                onChange={handleChange}
                size="large"
                shape="rounded"
                siblingCount={2}
              />
            ) : null}
          </TableContainer>
        </Grid>
      </Grid>
      <Backdrop className="backdrop" open={!!isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default Contact;
