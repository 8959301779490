import styled from 'styled-components';
import breakpoints from '../../../../helpers/breakpoints';
import colors from '../../../../styles/helpers/colors';

const LinkItem = styled.li`
  @media only screen and (min-width: ${breakpoints.minLaptop}px) {
    display: flex;
    margin-right: 2rem;
    font-weight: 500;
    height: 100%;
    max-width: fit-content;
    &:last-child {
      margin-right: 15px;
    }

    &.logo {
      width: 84px;
      height: 38px;
      margin-right: 3rem;
      display: flex;

      svg {
        width: 84px;
        height: 38px;
      }

      a:after {
        display: none;
      }
      &:hover svg {
        transform: none;
      }
    }

    a {
      display: inline-flex;
      position: relative;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      align-items: center;
      > img {
        margin-left: 0.2rem;
        padding-top: 3px;
        transition: all 0.5s ease;
      }

      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 2px;
        display: block;
        right: 0;
        bottom: 3px;
        background: ${colors.bgSecondaire};
        transition: width 0.5s ease;
      }

      &:hover {
        &:after {
          width: 100%;
          left: 0;
        }
      }
    }

    &:hover {
      > a,
      > span {
        .chevron {
          transform: rotate(-180deg);
        }

        &:after {
          width: 100%;
          left: 0;
        }
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.maxNotebook}px) {
    font-size: 1.2rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &.logo {
      display: none;
    }

    a,
    span {
      display: inline-flex;
      align-items: center;

      svg {
        margin-left: 0.3rem;
      }
    }
  }
`;

export default LinkItem;
