import {
  Avatar,
  AvatarGroup,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import { Configuration, OpenAIApi } from 'openai';
import { AddCircle, Save } from '@material-ui/icons';
import makeStyles from '@mui/styles/makeStyles';
import { toast } from 'react-toastify';
import FormReseauxSociaux from '../components/profile/FormReseauxSociaux';
import { API_LBA, OPENAI_KEY } from '../constants/routes';
import SideBar from '../components/SideBar';
import FormPresentation from '../components/profile/FormPresentation';
import { useStyles } from '../styles/estimation/Classes';
import { patchConsultant } from '../features/consultant/consultantsSlice';

function Profile() {
  const [openReseaux, setOpenReseaux] = useState(false);
  const [openPresentation, setOpenPresentation] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [textValue, setTextValue] = useState('');
  const [loadingAPI, setLoadingAPI] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const dispatch = useDispatch();
  const { consultant, loading } = useSelector((state) => state.consultants);
  const [editedConsultants, setEditedConsultants] = useState({
    countPresentation: '',
    presentation: null,
  });

  useEffect(() => {
    if (consultant || textValue !== '') {
      setEditedConsultants({
        id: consultant.id,
        countPresentation: parseInt(consultant.countPresentation, 10),
        presentation: textValue !== '' ? textValue : consultant.presentation,
      });
    }
  }, [consultant, textValue]);

  const handleFormSubmit = async (userPrompt) => {
    setLoadingAPI(true);

    // Configuration de l'API
    const configuration = new Configuration({
      apiKey: OPENAI_KEY,
    });
    const openai = new OpenAIApi(configuration);

    const messages = [
      { role: 'system', content: 'Vous êtes un assistant virtuel.' },
      { role: 'user', content: userPrompt },
    ];

    try {
      const resp = await openai.createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages,
      });

      // Traitement de la réponse
      if (
        resp &&
        resp.data &&
        resp.data.choices &&
        resp.data.choices.length > 0
      ) {
        const lastChoice = resp.data.choices[resp.data.choices.length - 1];
        setTextValue(lastChoice.message.content);
      } else {
        toast.error('La génération du texte a échoué ! Veuillez réessayer.');
      }
    } catch (error) {
      console.error("Erreur lors de l'appel à l'API OpenAI:", error);
      throw new Error('Failed to generate text. Please try again.');
    }

    setLoadingAPI(false);
    setOpenPresentation(false);
  };

  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };
  const handleSave = () => {
    setLoadingAPI(true);
    const patch = {
      id: editedConsultants.id,
      presentation:
        textValue !== '' ? textValue : editedConsultants.presentation,
    };
    dispatch(patchConsultant(patch));
    setLoadingAPI(false);
    setIsEditing(false);
    setConfirmOpen(false);
    toast.success('Présentation sauvegardé.');
  };

  function getColor() {
    if (consultant && consultant.countPresentation === 3) {
      return classe.green;
    }
    if (consultant && consultant.countPresentation === 2) {
      return classe.orange;
    }
    if (consultant && consultant.countPresentation === 1) {
      return classe.red;
    }
    return classe.red;
  }

  const styles = makeStyles((theme) => ({
    green: {
      color: 'green',
    },
    orange: {
      color: 'orange',
    },
    red: {
      color: 'red',
    },
  }));
  const classes = useStyles();
  const classe = styles();
  return (
    <SideBar>
      {consultant ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} xl={12}>
            <Typography
              className="ariane mt-5 mb-5"
              compoment="p"
              align="center"
            >
              Mon Profil
            </Typography>
          </Grid>
          <Grid item xs={10} className={classes.container}>
            <Paper elevation={4}>
              <Grid container sx={{ minHeight: '700px', textAlign: 'left' }}>
                <Grid
                  container
                  item
                  display="flex"
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  paddingTop={5}
                >
                  <Box paddingTop="30px">
                    <Grid item xs={10} display="flex" alignItems="center">
                      <CardMedia>
                        <AvatarGroup
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                          spacing={4}
                          total={4}
                        >
                          {consultant.reseauxSociaux &&
                            consultant.reseauxSociaux.map((reseau) => {
                              return (
                                <Avatar
                                  key={reseau.libelle}
                                  style={{
                                    backgroundColor: 'lightgray',
                                  }}
                                  src={
                                    reseau.path
                                      ? `${API_LBA}/build/front/static/reseaux/picto_${reseau.libelle.toLowerCase()}.png`
                                      : ''
                                  }
                                  alt={reseau.libelle}
                                  component="a"
                                  target="_blank"
                                  href={
                                    reseau.path
                                      ? `https://${reseau.libelle.toLowerCase()}.com${
                                          reseau.libelle.toLowerCase() ===
                                          'linkedin'
                                            ? '/in'
                                            : ''
                                        }/${reseau.path}`
                                      : '#'
                                  }
                                />
                              );
                            })}
                        </AvatarGroup>
                        <IconButton onClick={() => setOpenReseaux(true)}>
                          <EditIcon color="primary" />
                        </IconButton>
                      </CardMedia>
                      <div>
                        {consultant.civilite && consultant.civilite.id === 2 ? (
                          <img
                            alt="Real estate consultant profile "
                            src={
                              consultant.photoUrl
                                ? API_LBA + consultant.photoUrl
                                : '/images/avatar-f.png'
                            }
                            className={classes.profilePics}
                          />
                        ) : (
                          <img
                            alt="Real estate consultant profile "
                            src={
                              consultant.photoUrl && consultant.photoUrl
                                ? API_LBA + consultant.photoUrl
                                : '/images/avatar-h.png'
                            }
                            className={classes.profilePics}
                          />
                        )}
                      </div>
                    </Grid>
                  </Box>
                  <Grid item>
                    <Box paddingTop="40px">
                      <span>
                        <Typography variant="h4" fontWeight="bold" gutterBottom>
                          {consultant.prenom && consultant.prenom}{' '}
                          <Typography
                            variant="h4"
                            fontWeight="bold"
                            sx={{ color: '#00AFE6' }}
                          >
                            {consultant.nom && consultant.nom}
                          </Typography>
                        </Typography>
                      </span>
                      <Box>
                        <Typography gutterBottom>
                          Localisation :{' '}
                          {consultant.ville
                            ? consultant.ville.libelle
                            : 'Non renseignée.'}
                        </Typography>
                        <Typography gutterBottom>
                          Téléphone :{' '}
                          {consultant.telephone
                            ? consultant.telephone.match(/.{1,2}/g).join('-')
                            : 'Non renseignée.'}
                        </Typography>
                        <Typography gutterBottom>
                          Portable :{' '}
                          {consultant.portable
                            ? consultant.portable.match(/.{1,2}/g).join('-')
                            : 'Non renseignée.'}
                        </Typography>
                        <Typography gutterBottom>
                          Email :{' '}
                          {consultant.email
                            ? consultant.email
                            : 'Non renseignée.'}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>{' '}
                <Grid
                  container
                  display="flex"
                  justifyContent="center"
                  textAlign="center"
                >
                  <Typography variant="subtile2" sx={{ color: 'red' }}>
                    Votre utilisation de ChatGPT est restreinte à un certain
                    nombre de générations de texte.
                  </Typography>
                </Grid>
                <Grid
                  container
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Paper
                    elevation={6}
                    sx={{
                      backgroundColor: '#00AFE6',
                      color: 'white',
                      padding: '10px',
                      width: '90%',
                      textAlign: 'center',
                    }}
                  >
                    <Typography variant="h4" fontWeight="bold" gutterBottom>
                      Texte de présentation
                    </Typography>
                    {textValue && (
                      <Typography sx={{ color: 'red' }} variant="h6">
                        {`Il est important de conserver les balises "<>" et "</>" intactes lors de la modification de votre texte, car elles jouent un rôle essentiel dans l'affichage sur le site lebonagent.fr.`}
                      </Typography>
                    )}
                    <Typography variant="h5" gutterBottom>
                      <span>Création restante :</span>{' '}
                      <span className={getColor()}>
                        {consultant.countPresentation &&
                          consultant.countPresentation}
                      </span>
                    </Typography>
                    <Grid container display="flex" direction="column">
                      <Grid item xs={8}>
                        {isEditing ? (
                          <textarea
                            className={classes.textarea}
                            onChange={handleTextChange}
                            defaultValue={textValue || consultant.presentation}
                          />
                        ) : (
                          <>
                            {textValue ? (
                              <p>{textValue}</p>
                            ) : (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: editedConsultants.presentation,
                                }}
                              />
                            )}
                          </>
                        )}

                        <Grid item>
                          <IconButton onClick={() => setConfirmOpen(true)}>
                            <Tooltip title="Sauvegarder le texte">
                              <Save color="secondary" />
                            </Tooltip>
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setIsEditing(!isEditing);
                            }}
                          >
                            <Tooltip title="Modifier le texte">
                              <EditIcon color="warning" />
                            </Tooltip>
                          </IconButton>
                          {editedConsultants.countPresentation !== 0 && (
                            <IconButton
                              onClick={() => setOpenPresentation(true)}
                            >
                              <Tooltip title="Créer le texte">
                                <AddCircle color="primary" />
                              </Tooltip>
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Dialog
            open={openReseaux}
            onClose={() => setOpenReseaux(!openReseaux)}
            maxWidth="lg"
          >
            <Grid>
              <DialogContent dividers>
                <FormReseauxSociaux setOpen={setOpenReseaux} />
              </DialogContent>
            </Grid>
          </Dialog>
          ;
          <Dialog
            open={openPresentation}
            onClose={() => setOpenPresentation(!openPresentation)}
            maxWidth="lg"
          >
            <Grid>
              <DialogContent dividers>
                <FormPresentation
                  consultant={consultant}
                  handleSubmit={handleFormSubmit}
                  setOpen={loadingAPI}
                  count={editedConsultants.countPresentation}
                />
              </DialogContent>
            </Grid>
          </Dialog>
          <Dialog open={confirmOpen} aria-labelledby="confirm-dialog">
            <DialogTitle id="confirm-dialog">Confirmation</DialogTitle>
            <DialogContent>
              <Typography>
                Êtes - vous sûr de vouloir sauvegarder ? il vous reste `
                {consultant.countPresentation}` création(s) restante(s).
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setConfirmOpen(false)}>
                Non
              </Button>
              <Button variant="contained" onClick={() => handleSave()}>
                Oui
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      ) : (
        <Backdrop className={classes.backdrop} open={loading || loadingAPI}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress color="inherit" />
            <Typography>Chargement en cours, veuillez patienter.</Typography>
          </Grid>
        </Backdrop>
      )}
    </SideBar>
  );
}

export default Profile;
