import styled from 'styled-components';
import ButtonCountStyle from './ButtonCountStyle';

const ButtonCountRed = styled(ButtonCountStyle)`
  background-color: #f50057;
  border: solid 2px #f50057;
  .result {
    color: #f50057;
  }
`;

export default ButtonCountRed;
