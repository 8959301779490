import { toast } from 'react-toastify';
import {
  ESTIMATION_API,
  ESTIMATION_STATS_API,
  ESTIMATION_PHOTOS_API,
  API_LBA,
} from '../constants/routes';
import axios from '../helpers/axios';
import history from '../helpers/history';
import useErrorHandler from '../hooks/useErrorHandler';

const { errorHandler } = useErrorHandler();
export const findEstimationById = (id) => {
  return axios
    .get(`${ESTIMATION_API}/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
export const findEstimationPhotoById = (id) => {
  return axios
    .get(`${ESTIMATION_PHOTOS_API}?estimation.id=${id}`)
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
export const findEstimation = (page, itemsPerPage, order, username, pdf) => {
  return axios
    .get(
      `${ESTIMATION_API}?consultant.email=${username}&page=${page}&itemsPerPage=${itemsPerPage}&order${order}&statut=${pdf}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
export const findEstimationAdmin = (
  page,
  itemsPerPage,
  order,
  pdf,
  search = '',
) => {
  return axios
    .get(
      `${ESTIMATION_API}?page=${page}&itemsPerPage=${itemsPerPage}&order${order}&statut=${pdf}&consultant.nom=${search}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
export const deleteEstimation = (id) => {
  return axios
    .delete(`${ESTIMATION_API}/${id}`)
    .then(() => toast.success('Estimation supprimée'))
    .catch((error) => {
      errorHandler(error);
    });
};
export const findEstimationStats = () => {
  return axios
    .get(ESTIMATION_STATS_API)
    .then((response) => response.data['hydra:member'][0])
    .catch((error) => {
      errorHandler(error);
    });
};
export const findTotalEstimationByConsultant = (email) => {
  return axios
    .get(`${ESTIMATION_API}?consultant.email=${email}`)
    .then((response) => response.data['hydra:totalItems'])
    .catch((error) => {
      errorHandler(error);
    });
};
export const postNewEstimation = (finalData) => {
  return axios
    .post(ESTIMATION_API, finalData)
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
export const saveEstimationAndRedirect = (finalDataEdit) => {
  return axios
    .post(ESTIMATION_API, finalDataEdit)
    .then(() => {
      history.push('/mes-estimations');
    })
    .catch((error) => {
      errorHandler(error);
    });
};
export const updateEstimationAndRedirect = (id, finalDataEdit) => {
  findEstimationById(id)
    .then((res) => {
      const final = {
        ...finalDataEdit,
        consultant: res.consultant['@id'],
      };
      return axios
        .put(`${ESTIMATION_API}/${id}`, final)
        .then(() => {
          history.push('/mes-estimations');
        })
        .catch((error) => {
          errorHandler(error);
        });
    })
    .catch((error) => errorHandler(error));
};
export const updateEstimationAndMedia = (
  id,
  finalDataEdit,
  pictures,
  etape,
) => {
  findEstimationById(id)
    .then((res) => {
      const final = {
        ...finalDataEdit,
        consultant: res.consultant['@id'],
      };
      axios
        .put(`${ESTIMATION_API}/${id}`, final)
        .then((response) => {
          if (etape < 2) {
            const bodyFormData = new FormData();
            for (let index = 0; index < pictures.length; index += 1) {
              const currentFile = pictures[index];
              bodyFormData.append(`file${index}`, currentFile);
            }
            axios({
              method: 'post',
              url: `${API_LBA}/media/photo/estimation?id=${response.data.id}`,
              data: bodyFormData,
              headers: { 'Content-Type': 'multipart/form-data' },
            })
              .then(() => {
                history.push('/mes-estimations');
              })
              .catch((error) => {
                errorHandler(error);
              });
          } else {
            history.push('/mes-estimations');
          }
        })
        .catch((error) => {
          errorHandler(error);
        });
    })
    .catch((error) => errorHandler(error));
};
