import { ErrorMessage } from '@hookform/error-message';
import {
  Button,
  Grid,
  Grow,
  InputAdornment,
  TextField,
  Typography,
  Skeleton,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { checkboxIndividuel, checkboxTerrain } from '../../constants/checkbox';
import { API_URL } from '../../constants/routes';
import axios from '../../helpers/axios';
import MethodeUn from './MethodeUn';

function Situation({
  etape,
  setUpdateAdressBien,
  classes,
  control,
  required,
  errors,
  error,
  lastAdressBien,
  adressBien,
  typeBien,
  setRequired,
  loading,
  prixMin,
  prixMax,
  prixMoyen,
  cityBien,
  setError,
  setAdressBien,
  setCityBien,
  setPrixBien,
  type,
  surface,
}) {
  const [query, setQuery] = useState();
  const [loadingAddress, setLoadingAddress] = useState(false); // Fecth sur l'api gouvernement pour les adresses de l'autocomplete
  // debounce

  const updateQueryAdress = () => {
    if (query !== undefined && query !== '' && query.length > 3) {
      setError((prevState) => ({
        ...prevState,
        adresseBien: '',
      }));
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${query}`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          setAdressBien(json.features);
          setLoadingAddress(false);
        });
    }
  };

  const delayedQueryAdress = useCallback(debounce(updateQueryAdress, 1000), [
    query,
  ]);

  useEffect(() => {
    delayedQueryAdress();
    // Cancel the debounce on useEffect cleanup.
    return delayedQueryAdress.cancel;
  }, [delayedQueryAdress]);

  // debounce pour les statistiques et set le prix min max et moy en fonction du type de bien
  const updateQuery = () => {
    if (adressBien[0]) {
      setCityBien(adressBien[0].properties.city);
      axios
        .get(`${API_URL}/statistiques/${adressBien[0].properties.postcode}`)
        .then((response) => {
          switch (true) {
            case type === 'terrain':
              setPrixBien((prevState) => ({
                ...prevState,
                min: response.data.prixMinTerrain,
                max: response.data.prixMaxTerrain,
                moyen: response.data.prixMoyTerrain,
              }));
              break;
            case type === 'maison':
              setPrixBien((prevState) => ({
                ...prevState,
                min: response.data.prixMinMaison,
                max: response.data.prixMaxMaison,
                moyen: response.data.prixMoyMaison,
              }));
              break;
            case type === 'local commercial':
              setPrixBien((prevState) => ({
                ...prevState,
                min: response.data.prixMinCommercial,
                max: response.data.prixMaxCommercial,
                moyen: response.data.prixMoyCommercial,
              }));
              break;
            case type === 'appartement':
              setPrixBien((prevState) => ({
                ...prevState,
                min: response.data.prixMinAppart,
                max: response.data.prixMaxAppart,
                moyen: response.data.prixMoyAppart,
              }));
              break;
            default:
              break;
          }
        });
    }
  };

  const delayedQuery = useCallback(debounce(updateQuery, 500), [adressBien]);

  useEffect(() => {
    delayedQuery();
    return delayedQuery.cancel;
  }, [delayedQuery]);

  return (
    <>
      {etape >= 2 ? (
        <Grow in>
          <Grid id="section2" item xs={12} md={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Typography className="titleSection" variant="subtitle2">
                Situation
              </Typography>
            </Grid>

            <Grid className={classes.field} item xs={12} md={12}>
              <Typography className="title text">Bien estimé</Typography>
              {loading && <Skeleton variant="text" height={60} width="100%" />}
              {loading && <Skeleton variant="text" height={60} width="100%" />}
              {loading && <Skeleton variant="text" height={60} width="100%" />}
              {loading && <Skeleton variant="text" height={60} width="100%" />}
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="center"
              >
                {loading && (
                  <Skeleton variant="rectangular" width={125} height={125} />
                )}
                {loading && (
                  <Skeleton variant="rectangular" width={125} height={125} />
                )}
                {loading && (
                  <Skeleton variant="rectangular" width={125} height={125} />
                )}
                {loading && (
                  <Skeleton variant="rectangular" width={125} height={125} />
                )}
              </Grid>

              {!loading && (
                <>
                  <Controller
                    render={() => (
                      <Autocomplete
                        loading={loadingAddress}
                        freeSolo
                        id="adress"
                        onInputChange={(event, request) => {
                          setQuery(request);
                          setUpdateAdressBien(request);
                        }}
                        value={lastAdressBien}
                        disableClearable
                        options={adressBien}
                        getOptionLabel={(option) =>
                          option.properties
                            ? option.properties.label
                            : lastAdressBien
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Adresse complète"
                            type="text"
                            helperText="Pour les adresses en bis ou ter veuillez écrire ainsi : 47bis ou 47ter."
                            placeholder="47 rue nationale 37000 Tours"
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                          />
                        )}
                      />
                    )}
                    name="adresseBien"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: false,
                      maxLength: 255,
                    }}
                  />
                  <Typography color="secondary">
                    {error ? error.adresseBien : null}
                  </Typography>
                  <Controller
                    render={({ field }) => (
                      <TextField
                        label="Surface précise de votre bien"
                        type="number"
                        placeholder="75"
                        inputRef={field.ref}
                        // onChange={setstate(prixMoyen * field.value)}
                        {...field}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">m²</InputAdornment>
                          ),
                        }}
                      />
                    )}
                    name="surface"
                    control={control}
                    defaultValue=""
                    rules={{
                      required:
                        required.required === true
                          ? false
                          : 'Veuillez compléter ce champ.',
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="surface"
                    render={({ message }) => (
                      <Typography className="error" color="secondary">
                        {message}
                      </Typography>
                    )}
                  />

                  {typeBien === checkboxTerrain ? null : (
                    <>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            label="Nombre de pièces"
                            type="number"
                            placeholder="3"
                            inputRef={field.ref}
                            {...field}
                          />
                        )}
                        name="pieces"
                        control={control}
                        defaultValue=""
                        rules={{
                          required:
                            required.required === true
                              ? false
                              : 'Veuillez compléter ce champ.',
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="pieces"
                        render={({ message }) => (
                          <Typography className="error" color="secondary">
                            {message}
                          </Typography>
                        )}
                      />
                    </>
                  )}

                  {typeBien === checkboxIndividuel ? (
                    <>
                      <Controller
                        render={({ field }) => (
                          <TextField
                            label="Surface du terrain"
                            type="number"
                            placeholder="126"
                            inputRef={field.ref}
                            {...field}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">
                                  m²
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                        name="surfaceTerrain"
                        control={control}
                        defaultValue=""
                        rules={{
                          required:
                            required.required === true
                              ? false
                              : 'Veuillez compléter ce champ.',
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="surfaceTerrain"
                        render={({ message }) => (
                          <Typography className="error" color="secondary">
                            {message}
                          </Typography>
                        )}
                      />
                    </>
                  ) : null}

                  <Typography align="center" variant="subtitle1">
                    Un bien est unique, la surface précise{' '}
                    {typeBien === checkboxTerrain
                      ? null
                      : 'et le nombre de pièces '}
                    nous aide pour obtenir le meilleur prix.
                  </Typography>
                </>
              )}
            </Grid>

            {cityBien && (
              <MethodeUn
                surface={surface}
                cityBien={cityBien}
                prixMax={prixMax}
                prixMin={prixMin}
                prixMoyen={prixMoyen}
                errors={errors}
                control={control}
                required={required}
                etape={etape}
                setRequired={setRequired}
              />
            )}

            {etape < 3 ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  color="primary"
                  className="mt-1 mb-2"
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setRequired((prevState) => ({
                      ...prevState,
                      required: null,
                    }));
                  }}
                >
                  Suivant
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grow>
      ) : null}
    </>
  );
}

export default Situation;
