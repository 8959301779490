import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Typography,
  Skeleton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ImageUploader from 'react-images-upload';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import SideBar from '../../components/SideBar';
import { API_LBA } from '../../constants/routes';
import { fetchConsultantById } from '../../features/consultant/consultantsSlice';
import axios from '../../helpers/axios';

import { useStyles } from '../../styles/estimation/Classes';

function AdminPhotoConsultant() {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { consultant, loading } = useSelector((state) => state.consultants);
  useEffect(() => {
    dispatch(fetchConsultantById(parseInt(id, 10)));
  }, [dispatch]);
  const [pictures, setPictures] = useState();
  const [isLoadingPicture, setIsLoadingPicture] = useState(false);

  const onDrop = (picture) => {
    setPictures(picture);
  };

  const handleSubmit = () => {
    if (pictures && pictures[0]) {
      setIsLoadingPicture(true);
      const currentFile = pictures[0];
      const bodyFormData = new FormData();
      bodyFormData.append('file', currentFile);
      axios({
        method: 'post',
        url: `${API_LBA}/media/photo?id=${id}`,
        data: bodyFormData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then(function (response) {
          setIsLoadingPicture(false);
          toast.success(response.data);
        })
        .catch(function (response) {
          toast.error(response.data);
        });
    } else {
      setIsLoadingPicture(false);
      toast.error('Veuillez choisir une photo');
    }
  };

  const backdrop = () => {
    if (isLoadingPicture || loading) {
      return true;
    }
    return false;
  };

  return (
    <SideBar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        {loading ? (
          <Grid
            className="mt-3"
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Skeleton variant="text" height={60} width={600} />
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className="mt-5"
          >
            <Typography className="ariane" compoment="p" align="center">
              Gestion de la photo de profil : {consultant && consultant.nom}{' '}
              {consultant && consultant.prenom}
            </Typography>
            <Typography align="center" component="p">
              Administrez la photo de profil
            </Typography>
          </Grid>
        )}
        <Grid container item xs={8}>
          <ImageUploader
            singleImage
            withIcon
            onChange={onDrop}
            buttonText="Choissisez une image."
            imgExtension={['.jpg', '.png', '.jpeg']}
            maxFileSize={2242880}
            label="Poids maximum : 2mb, format accepté : jpg, png, jpeg"
            fileSizeError="Poids de l'image incorrect."
            fileTypeError="Format de fichier incorrect"
            withPreview
          />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
          >
            <Button
              disabled={!!isLoadingPicture}
              style={{ width: '150px', height: '40px' }}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
            >
              Enregistrer
            </Button>
            <Link className="ml-1" to="/consultants">
              <Button variant="contained">Retour</Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={backdrop()}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default AdminPhotoConsultant;
