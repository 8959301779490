import makeStyles from '@mui/styles/makeStyles';
import { frFR } from '@mui/material/locale';

export const style = makeStyles(() => ({
  icon: {
    height: '100%',
    width: '100px',
  },
}));

export const useStyles = makeStyles((theme) => ({
  frFR,
  container: {
    '& .titre': {
      color: '#3f525c',
      fontWeight: 'bold',
      marginTop: '40px',
      marginBottom: '40px',
      [theme.breakpoints.down('lg')]: {
        fontSize: '1.4rem',
        textAlign: 'center',
        marginTop: '20px',
        marginBottom: '20px',
      },
    },
    '& .titreSpan': {
      color: '#00AFE6',
    },
  },
  form: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
    '& .MuiSelect-select': {
      margin: theme.spacing(1),
      width: '35ch',
      [theme.breakpoints.down('lg')]: {
        margin: 0,
        width: '20ch',
      },
    },
    '& .Mui-selected:hover': {
      backgroundColor: 'background-color: rgb(239 0 0 / 8%)',
    },
    '& .text': {
      color: '#3f525c',
    },
    '& .textCyan': {
      color: '#00AFE6',
      fontWeight: 'bold',
    },
    '& .title': {
      fontWeight: 'bold',
    },
    '& input:checked': {
      color: 'red',
    },
    '& .border': {
      padding: '20px',
      background: 'white',
      borderRadius: '0px',
      borderLeft: 'solid 15px #00AFE6',
      borderRight: 'solid 5px #3f525c',
      borderBottom: 'solid #00AFE6',
      borderTop: 'solid 5px #3f525c',
      boxShadow: '2px 2px 3px 0 rgb(0 0 0 / 5%)',
    },
  },
  field: {
    // height: "356px",
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '95%',
    },
    '& .MuiGrid-root': {
      padding: '10px;',
    },
    '& .text': {
      color: '#3f525c',
    },
    '& .textCyan': {
      color: '#00AFE6',
      fontWeight: 'bold',
    },
    '& .title': {
      fontWeight: 'bold',
    },
    marginBottom: '25px',
    [theme.breakpoints.down('lg')]: {
      marginBottom: '25px',
    },
    marginRight: '-10px',
    padding: '20px',
    background: 'white',
    borderRadius: '0px',
    borderLeft: 'solid 15px #00AFE6',
    borderRight: 'solid 5px #3f525c',
    borderBottom: 'solid #00AFE6',
    borderTop: 'solid 5px #3f525c',
    boxShadow: '2px 2px 3px 0 rgb(0 0 0 / 5%)',
  },
  border: {
    '& .blueBorder': {
      borderTop: '5px solid #3f525c',
      backgroundColor: 'white',
    },
    '& .cyanBorder': {
      borderColor: '#00AFE6',
      [theme.breakpoints.down('xl')]: {
        borderLeft: 'none',
      },
    },
    '& .bottomBorder': {
      borderColor: '#00AFE6',
    },
  },
  cross: {
    position: 'relative',
    top: '100px',
    zIndex: '100',
  },
  ddl: {
    color: 'white',
    width: '150px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkbox: {
    [theme.breakpoints.down('lg')]: {
      display: 'block',
    },
  },
  analyse: {
    marginRight: '20px',
    [theme.breakpoints.down('lg')]: {
      marginRight: '0px',
    },
  },
  arrowUp: {
    position: 'fixed',
    bottom: '220px',
    right: '85px',
    [theme.breakpoints.down('lg')]: {
      bottom: '100px',
      right: '10px',
    },
  },
  arrowDown: {
    position: 'fixed',
    bottom: '170px',
    right: '85px',
    [theme.breakpoints.down('lg')]: {
      bottom: '50px',
      right: '10px',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  btn: {
    width: '150px',
    height: '40px',
  },
  btnEditer: {
    width: '100px',
    height: '40px',
  },
  hidden: {
    display: 'none',
  },
  profilePics: {
    maxWidth: '200px',
    width: '100%',
    borderRadius: '50%',
  },
  text: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  overlayContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  textField: {
    margin: theme.spacing(1),
  },
  textarea: {
    width: '95%',
    height: '200px',
    fontSize: 'inherit',
    fontFamily: 'inherit',
    resize: 'none',
    padding: '5px',
    border: 'solid 1px rgba(0,0,0,0.2)',
  },
}));
