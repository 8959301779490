import { TextField, Autocomplete, Typography, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { frFR } from '@mui/material/locale';
import { useStyles } from '../styles/estimation/Classes';
import useDebounce from '../hooks/useDebounce';

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  frFR,
);

function AutocompleteAddress({
  setError,
  setLastAdress,
  control,
  title,
  label,
  required,
}) {
  const classes = useStyles();
  const [adress, setAdress] = useState('');
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);
  // Fecth sur l'api gouvernement pour les adresses de l'autocomplete
  // debounce
  const updateQueryAdress = () => {
    if (query !== undefined && query !== '') {
      setLoading(true);
      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${query}type=&autocomplete=0`,
      )
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          setLoading(false);
          setAdress(json.features.flatMap((item) => item.properties));
        });
    }
  };
  const delayedQueryAdress = useDebounce(query, 1000);

  useEffect(() => {
    if (delayedQueryAdress) {
      updateQueryAdress();
    }
    // Cancel the debounce on useEffect cleanup.
  }, [delayedQueryAdress]);

  return (
    <Grid className={classes.field} item xs={12} md={12}>
      <Typography className="title text">{title}</Typography>
      <ThemeProvider theme={theme}>
        <Controller
          render={() => (
            <Autocomplete
              freeSolo
              loading={loading}
              id="adress"
              onInputChange={(event, request) => {
                setQuery(request);
                setError((prevState) => ({
                  ...prevState,
                  localisation: '',
                }));
              }}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label
              }
              onChange={(event, value) => setLastAdress(value.label)}
              options={adress && adress ? adress : []}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  helperText="Pour les adresses en bis ou ter veuillez écrire ainsi : 47bis ou 47ter."
                  type="text"
                  placeholder="8 Rue du Dr Herpin, 37000 Tours"
                  InputProps={{ ...params.InputProps, type: 'search' }}
                />
              )}
            />
          )}
          name="localisation"
          control={control}
          rules={{
            required: required ? 'Veuillez compléter ce champ.' : '',
            maxLength: {
              value: 255,
              message: 'Le nombre maximum de caractères a été dépassé',
            },
          }}
        />{' '}
      </ThemeProvider>
    </Grid>
  );
}

export default AutocompleteAddress;
