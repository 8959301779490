/* eslint-disable no-unreachable-loop */

import React, { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { Redirect, Route } from 'react-router-dom';
import axios from 'axios';
import { LinearProgress } from '@mui/material';
import useDocumentTitle from '../hooks/useDocumentTitle';
import { API_URL } from '../constants/routes';
import { getExpirationDate, isExpired } from '../utils/token';

function PrivateRoute({ rolesAuthorized, name, ...rest }) {
  useDocumentTitle(`Le Bon Agent - ${name}`);

  const token = localStorage.getItem('token') || false;
  const user = token ? jwtDecode(token) : null;
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  /**
   * Vérifie en backend que le token présent dans le localstorage est valide lorsqu'on arrive pour
   * la 1ere fois sur l'application directement depuis une route privée (par ex : suite à un F5 ou
   * à un copié/collé de l'url dans le navigateur)
   */
  useEffect(() => {
    if (token) {
      axios
        .get(API_URL, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((res) => {
          return res.status;
        })
        .then((status) => {
          if (status === 200) {
            setIsAuthenticated(true);
          }
        })
        .catch(() => {
          setIsAuthenticated(false);
          localStorage.removeItem('token');
        })
        .then(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setIsAuthenticated(false);
    }
  }, []);

  if (!user || !user.username) {
    return <Redirect to="/login" />;
  }
  const expired = isExpired(getExpirationDate(token));
  if (expired) {
    return <Redirect to="/login" />;
  }

  if (isLoading) {
    return <LinearProgress />;
  }
  if (isAuthenticated) {
    for (let index = 0; index < user.roles.length; index++) {
      if (rolesAuthorized.indexOf(user.roles[index]) !== -1) {
        return <Route {...rest} />;
      }
    }
    return <Redirect to="/login" />;
  }
}

export default PrivateRoute;
