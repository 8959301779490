import { toast } from 'react-toastify';
import axios from '../helpers/axios';
import {
  API_LBA,
  API_URL,
  CONSULTANT_STATS_API,
  CONSULTANTS_API,
} from '../constants/routes';
import useErrorHandler from '../hooks/useErrorHandler';
import history from '../helpers/history';

const { errorHandler } = useErrorHandler();

export const findAll = (currentPage, search, itemsPerPage, actif) => {
  return axios
    .get(
      `${CONSULTANTS_API}?page=${currentPage}&itemsPerPage=${itemsPerPage}&search=${search}&actif=${actif}&roles=ROLE_CONSULTANT`,
    )
    .then((response) => response)
    .catch((error) => {
      errorHandler(error);
    });
};

export const findConsultantStats = () => {
  return axios
    .get(CONSULTANT_STATS_API)
    .then((response) => response.data['hydra:member'][0])
    .catch((error) => {
      errorHandler(error);
    });
};

export const findActif = (search) => {
  return axios
    .get(
      `${CONSULTANTS_API}?page=1&actif=1&roles=ROLE_CONSULTANT&search=${search}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};

export const findConsultantById = (id) => {
  return axios
    .get(`${CONSULTANTS_API}/${id}`)
    .then((response) => response)
    .catch((error) => {
      errorHandler(error);
    });
};
export const findConsultantByMail = (email) => {
  return axios
    .get(`${CONSULTANTS_API}?search=${email}`)
    .then((response) => response)
    .catch((error) => {
      errorHandler(error);
    });
};

export const updateConsultant = (
  // consultant,
  actifPresentation,
  actif,
  actifMooveo,
  dateNaissance,
  rsac,
  id,
  badges,
  roles,
  reseauxSociaux,
  presentation,
  countPresentation,
) => {
  const patch = {
    actifPresentation,
    actif,
    actifMooveo,
    rsac,
    dateNaissance,
    id,
    badges,
    roles,
    reseauxSociaux,
    presentation,
    countPresentation,
  };

  return axios
    .put(`${CONSULTANTS_API}/${id}`, patch)
    .then((response) => response)
    .catch((error) => {
      errorHandler(error);
    });
};
export const updateReseaux = (id, patch) => {
  return axios
    .put(`${CONSULTANTS_API}/${id}`, patch)
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};

export const findAllConsultantsInArea = (deptValue, page) => {
  return axios
    .get(`${API_LBA}/interagent/${deptValue}?page=${page}`)
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};

export const findAllRegions = () => {
  return axios
    .get(`${API_URL}/regions`)
    .then((response) => response.data['hydra:member'])
    .catch((error) => {
      errorHandler(error);
    });
};

export const findDepartementByRegion = (id) => {
  return axios
    .get(`${API_URL}/departements?region.id=${id}`)
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};

export const findInterAgentById = (id) => {
  return axios
    .get(`${API_URL}/inter_agent_contacts/${id}`)
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};

export const interAgentEtat = (id, data) => {
  return axios
    .put(`${API_URL}/inter_agent_contacts/${id}`, data)
    .then((response) => {
      const alert = response.data.etat
        ? toast.success('Recommandation accepté')
        : toast.success('Recommandation refusé');
      history.push('/inter-agent');
      return alert;
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const postInterAgent = (data) => {
  return axios
    .post(`${API_URL}/inter_agent_contacts`, data)
    .then(() => {
      toast.success('Recommandation envoyé');
      history.push('/inter-agent');
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const findInterAgent = (
  page,
  itemsPerPage,
  order,
  typeReco,
  etat = '',
) => {
  return axios
    .get(
      `${API_URL}/inter_agent_contacts?${typeReco}&page=${page}&itemsPerPage=${itemsPerPage}&order${order}&etat=${etat}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};

export const findInterAgentAdmin = (
  page,
  itemsPerPage,
  order,
  etat = '',
  search = '',
) => {
  return axios
    .get(
      `${API_URL}/inter_agent_contacts?page=${page}&itemsPerPage=${itemsPerPage}&order${order}&consultant.nom=${search}&etat=${etat}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
