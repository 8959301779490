import styled from 'styled-components';
import colors from '../../../../styles/helpers/colors';
import breakpoints from '../../../../helpers/breakpoints';

const Dropdown = styled.ul`
  @media only screen and (min-width: ${breakpoints.minLaptop}px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 2rem 0;
    left: 0;
    right: 0;
    position: absolute;
    overflow: hidden;
    top: 90px;
    width: 200px;
    max-height: 0;
    min-height: 0;
    background: ${colors.bgPrincipal};
    transition: all 1s ease-in-out;
    padding: 0 2rem;
    z-index: 1;
    li {
      opacity: 0;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;

      .title {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }

      a {
        font-weight: 300;
        &:after {
          bottom: 0;
        }

        &:hover {
          svg {
            transform: none;
          }
        }
      }
    }

    &.open {
      max-height: 100vh;
      min-height: 0;
      padding: 3rem 2rem;

      li {
        opacity: 1;
        transition: opacity 0.8s;
      }
    }
  }

  @media only screen and (max-width: ${breakpoints.maxNotebook}px) {
    visibility: hidden;
    height: 0;
    padding: 0;
    opacity: 0;
    transform: translateY(30px);
    transition: opacity 665ms cubic-bezier(0, 0, 0.1, 1),
      transform 665ms cubic-bezier(0, 0, 0.1, 1);
    transition-delay: 0ms, 0ms;

    &.open {
      opacity: 1;
      height: auto;
      transform: translateY(0);
      visibility: visible;
      padding: 1rem 1rem 0;
    }

    li {
      .title {
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    }
  }
`;

export default Dropdown;
