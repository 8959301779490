import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import makeStyles from '@mui/styles/makeStyles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Delete,
  DeleteForeverOutlined,
  ImageOutlined,
  PersonAdd,
  PictureAsPdf,
  ThumbDown,
  ThumbUp,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { ModeEdit } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { API_LBA } from '../../constants/routes';
import { deleteEvent, downloadPng } from '../../service/eventApi';
import {
  deleteParticipant,
  updateParticipant,
} from '../../service/participantEventApi';
import useErrorHandler from '../../hooks/useErrorHandler';
import AddParticipant from './AddParticipant';
import rolesConstants from '../../constants/security';

function EventParticipants({ props }) {
  const { event, setIsLoading, user, fetchEvent } = props;
  const [open, setOpen] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [openInput, setOpenInput] = useState(false);
  const [listOfParticipants, setlistOfParticipants] = useState(
    event.listeParticipants,
  );
  const [participantEvent, setParticipantEvent] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const { errorHandler } = useErrorHandler();

  const onTicked = (participant, e) => {
    setParticipantEvent({ ...participant, isActif: e });
  };

  const onDeleteParticipant = (participant) => {
    deleteParticipant(participant).then(async () => {
      const newList = listOfParticipants.filter(
        (participantOfList) => participantOfList.id !== participant.id,
      );
      setlistOfParticipants(newList);
      toast.success('Participant(e) supprimé');
    });
  };
  useEffect(() => {
    let activ = true;
    if (event) {
      setEventId(event.id);
    }
    return () => {
      activ = false;
    };
  }, []);

  function updateListParticipants(participantRes) {
    const newList = listOfParticipants.map((participant) => {
      if (participant.id === participantRes.id) {
        return participantRes;
      }
      return participant;
    });
    setlistOfParticipants(newList);
  }

  const onSubmitStatus = () => {
    updateParticipant(participantEvent).then(async (participantRes) => {
      updateListParticipants(participantRes);
      toast.success('Status modifié');
    });
  };

  useEffect(() => {
    if (participantEvent) {
      onSubmitStatus();
    }
  }, [participantEvent]);
  const onDelete = async (id) => {
    setIsLoading(true);
    try {
      await deleteEvent(id);
      toast.success('Évènement effacé');
      await fetchEvent();
    } catch (e) {
      errorHandler(e);
    }
    setIsLoading(false);
  };
  // filedowloader pour télécharger l'image blob directement apres le click
  const onClick = async (id) => {
    // Pas besoin d'imageData car on récupère tout depuis l'evenement (une seule image donc on la définit en "dur" côté back
    setIsLoading(true);
    try {
      const data = await downloadPng(id);
      if (data) {
        fileDownload(data, `event-${Date.now()}.png`);
        toast.success('Visuel téléchargé');
      }
    } catch (e) {
      throw new Error('Une erreur est survenue');
    }
    setIsLoading(false);
  };

  const useStylesGestion = makeStyles((theme) => ({
    container: {
      padding: '50px',
      [theme.breakpoints.down('md')]: {
        padding: '10px',
      },
    },
    action: {
      margincenter: '5px',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '0',
      },
    },
    statut: {
      margincenter: '5px',
      height: '32px',
    },
    buttonDdl: {
      width: '150px',
      height: '32px',
    },
    ddl: {
      color: 'white',
      width: '150px',
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignitems: 'center',
    },
    textSuccess: {
      color: 'green',
    },
  }));
  const classesEstimation = useStylesGestion();
  return (
    <>
      {event && (
        <>
          <TableRow>
            <TableCell align="center">{event.titreEvenement}</TableCell>
            <TableCell align="center">
              {event.consultant.prenom} {event.consultant.nom}
            </TableCell>
            <TableCell align="center">{event.adresse}</TableCell>
            <TableCell align="center">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </TableCell>
            <TableCell align="center">
              {new Date(event.date).getHours().toLocaleString('fr-FR')}H
              {new Date(event.date).getMinutes() > 0
                ? new Date(event.date).getMinutes().toLocaleString('fr-FR')
                : null}
            </TableCell>
            <TableCell align="center">
              {new Date(event.date).toLocaleDateString('fr-FR')}
            </TableCell>
            <TableCell align="center">
              <Box>
                <Tooltip title="Liste des participants">
                  <IconButton
                    className={classesEstimation.statut}
                    color="warning"
                    target="_self"
                    href={`${API_LBA}/evenements/pdf/${event.id}`}
                  >
                    <PictureAsPdf />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Visuel événement">
                  <IconButton
                    className={classesEstimation.statut}
                    color="primary"
                    onClick={() => onClick(event.id)}
                  >
                    <ImageOutlined />
                  </IconButton>
                </Tooltip>
                {user.roles.includes(rolesConstants.ADMIN) && (
                  <>
                    <Tooltip title="Modifier événement">
                      <IconButton
                        component={Link}
                        variant="contained"
                        color="success"
                        to={`/modifier-evenement/${event.id}`}
                      >
                        <ModeEdit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer événement">
                      <IconButton
                        variant="outlined"
                        color="error"
                        onClick={() => setConfirmOpen(true)}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ajouter participant">
                      <IconButton
                        aria-label="Ajouter participant"
                        size="small"
                        onClick={() => setOpenInput(!openInput)}
                      >
                        <PersonAdd style={{ fill: 'lightseagreen' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                )}
                {/* Condition qui test si l'ambassadeur affiche les boutons uniquement pour ses événements */}
                {user.roles.includes(rolesConstants.AMBASSADEUR) &&
                !user.roles.includes(rolesConstants.ADMIN) &&
                user.username === event.consultant.email ? (
                  <>
                    <Tooltip title="Modifier événement">
                      <IconButton
                        component={Link}
                        variant="contained"
                        color="success"
                        to={`/modifier-evenement/${event.id}`}
                      >
                        <ModeEdit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Supprimer événement">
                      <IconButton
                        variant="outlined"
                        color="error"
                        onClick={() => setConfirmOpen(true)}
                      >
                        <Delete color="error" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Ajouter participant">
                      <IconButton
                        aria-label="Ajouter participant"
                        size="small"
                        onClick={() => setOpenInput(!openInput)}
                      >
                        <PersonAdd style={{ fill: 'lightseagreen' }} />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </TableCell>
          </TableRow>
        </>
      )}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Liste des participants
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Prénom</TableCell>
                    <TableCell align="center">Téléphone</TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Actif</TableCell>
                    <TableCell align="center">Suppréssion</TableCell>
                  </TableRow>
                </TableHead>
                {listOfParticipants.map((participant) => (
                  <TableBody key={participant.id}>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {participant.prenom}
                      </TableCell>
                      <TableCell>{participant.nom}</TableCell>
                      <TableCell align="center">
                        {participant.telephone}
                      </TableCell>
                      <TableCell align="center">{participant.email}</TableCell>
                      <TableCell align="center">
                        {user.username === event.consultant.email && (
                          <>
                            {participant.isActif ? (
                              <Tooltip title="Cette personne participe à l'évenement.">
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    onTicked(participant, !participant.isActif)
                                  }
                                >
                                  <ThumbUp
                                    fontSize="small"
                                    style={{ color: 'forestgreen' }}
                                  />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip title="Cette personne ne participe pas à l'évenement.">
                                <IconButton
                                  size="small"
                                  onClick={() =>
                                    onTicked(participant, !participant.isActif)
                                  }
                                >
                                  <ThumbDown
                                    fontSize="small"
                                    style={{ fill: 'indianred' }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </TableCell>
                      {user.username === event.consultant.email && (
                        <Tooltip title="Supprimer ce participant.">
                          <TableCell align="center">
                            <IconButton
                              size="small"
                              onClick={() => onDeleteParticipant(participant)}
                            >
                              <DeleteForeverOutlined color="error" />
                            </IconButton>
                          </TableCell>
                        </Tooltip>
                      )}
                    </TableRow>
                  </TableBody>
                ))}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={openInput}
        onClose={() => setOpenInput(!openInput)}
        maxWidth="xl"
      >
        <DialogContent dividers>
          <AddParticipant event={event} />
        </DialogContent>
      </Dialog>
      {eventId && (
        <Dialog open={confirmOpen} aria-labelledby="confirm-dialog">
          <DialogTitle id="confirm-dialog">Confirmation</DialogTitle>
          <DialogContent>Êtes-vous sûr de vouloir supprimer ?</DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setConfirmOpen(false)}>
              Non
            </Button>
            <Button variant="contained" onClick={() => onDelete(eventId)}>
              Oui
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default EventParticipants;
