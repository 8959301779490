import { Grid, Grow, InputLabel, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import Select from 'react-select';
import {
  checkboxIndividuel,
  groupedOptions,
  groupedOptionsMaison,
} from '../../constants/checkbox';

function AnalyseQualitative({
  etape,
  error,
  valorisation,
  limitation,
  typeBien,
  loading,
  setValorisation,
  setError,
  setLimitation,
}) {
  const useStyles = makeStyles(() => ({
    root: {
      color: 'green',
    },
  }));

  const classes = useStyles();

  const handleChangeValorisation = (event) => {
    setValorisation(event);
    setError((prevState) => ({
      ...prevState,
      valorisation: '',
    }));
  };

  const handleChangeLimitation = (event) => {
    setLimitation(event);
    setError((prevState) => ({
      ...prevState,
      limitation: '',
    }));
  };

  // check le nombre dans le tableau si supérieur a 4 affiche en rouge
  const handleCountValorisation = () => {
    if (valorisation.length === 4) {
      return <Typography className={classes.root}>4/4</Typography>;
    }
    if (valorisation.length > 4) {
      return (
        <Typography color="error">{`${valorisation.length}/4`}</Typography>
      );
    }
    if (valorisation.length > 0 && valorisation.length <= 4) {
      return <Typography>{`${valorisation.length}/4`}</Typography>;
    }
    return false;
  };

  // check le nombre dans le tableau si supérieur a 4 affiche en rouge
  const handleCountLimitation = () => {
    if (limitation.length === 4) {
      return <Typography className={`ml-1 ${classes.root}`}>4/4</Typography>;
    }
    if (limitation.length > 4) {
      return (
        <Typography color="error" className="ml-1">
          {`${limitation.length}/4`}
        </Typography>
      );
    }
    if (limitation.length > 0 && limitation.length <= 4) {
      return (
        <Typography className="ml-1">{`${limitation.length}/4`}</Typography>
      );
    }
    return false;
  };

  return (
    <>
      {etape >= 6
        ? !loading && (
            <Grow in>
              <Grid>
                <Typography className="titre" variant="h4" compoment="p">
                  Analyse
                  <span className="titreSpan"> qualitative</span>
                </Typography>
                <Grid container>
                  <Typography
                    className="error"
                    color="error"
                    variant="subtitle1"
                  >
                    4 choix maximum par champs*
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  item
                  className="border"
                >
                  <Grid className="mr-2" item>
                    {handleCountValorisation()}
                  </Grid>
                  <Grid className="analyse" item xs={12} md={5}>
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Valorisons votre prix
                    </InputLabel>
                    <Select
                      defaultValue={valorisation}
                      isMulti
                      name="colors"
                      options={
                        typeBien === checkboxIndividuel
                          ? groupedOptionsMaison
                          : groupedOptions
                      }
                      onChange={handleChangeValorisation}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                    <Typography className="error" color="secondary">
                      {error ? error.valorisation : null}
                    </Typography>
                  </Grid>
                  <Grid className="mr-2" item>
                    {handleCountLimitation()}
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Limitons la négociation
                    </InputLabel>
                    <Select
                      defaultValue={limitation}
                      isMulti
                      name="colors"
                      options={
                        typeBien === checkboxIndividuel
                          ? groupedOptionsMaison
                          : groupedOptions
                      }
                      onChange={handleChangeLimitation}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                    <Typography className="error" color="secondary">
                      {error ? error.limitation : null}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grow>
          )
        : null}
    </>
  );
}

export default AnalyseQualitative;
