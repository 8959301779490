import styled from 'styled-components';

const Logo = styled.img`
  margin: 0 auto;
  height: 3.5rem;
  width: 3.5rem;
  padding-left: 2rem;
`;

export default Logo;
