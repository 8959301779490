import { ErrorMessage } from '@hookform/error-message';
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

function MethodeUn({
  cityBien,
  prixMax,
  prixMin,
  prixMoyen,
  errors,
  control,
  required,
  etape,
  setRequired,
  surface,
}) {
  const [prixCalculer, setPrixCalculer] = useState(0);
  useEffect(() => {
    setPrixCalculer(prixMoyen * surface);
    return () => {
      setPrixCalculer(0);
    };
  }, [prixMax, prixMin, prixMoyen, surface]);
  return (
    <>
      {etape >= 3 ? (
        <>
          <Grid container className="mt-5">
            <Typography className="titleSection" variant="subtitle2">
              Méthode 1 : Prix de référence{' '}
              {cityBien && `au m² pour la ville de ${cityBien}`}
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            item
            className="border"
          >
            <Grid
              container
              item
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                item
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Typography variant="subtitle1">
                  Prix maximum :{' '}
                  <strong className="title text">
                    {prixMax
                      ? prixMax.toLocaleString('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                        })
                      : 0}
                  </strong>
                </Typography>
                <Typography style={{ width: '225px' }} variant="subtitle1">
                  Prix moyen :{' '}
                  <strong className="title text">
                    {prixMoyen
                      ? prixMoyen.toLocaleString('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                        })
                      : 0}
                  </strong>
                </Typography>
                <Typography variant="subtitle1">
                  Prix minimum :{' '}
                  <strong className="title text">
                    {prixMin
                      ? prixMin.toLocaleString('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                        })
                      : 0}
                  </strong>
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                item
                className="mt-3"
              >
                <Typography style={{ width: '225px' }} variant="subtitle1">
                  Prix calculé :{' '}
                  <strong className="title text">
                    {!Number.isNaN(prixCalculer)
                      ? prixCalculer.toLocaleString('fr-FR', {
                          style: 'currency',
                          currency: 'EUR',
                        })
                      : 0}
                  </strong>
                </Typography>
              </Grid>
              <Grid item className="mt-3">
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Prix de vente à confirmer"
                      type="number"
                      placeholder="250000"
                      inputRef={field.ref}
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                      }}
                    />
                  )}
                  name="methodeUn"
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      required.required === true
                        ? false
                        : 'Veuillez compléter ce champ.',
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="methodeUn"
                  render={({ message }) => (
                    <Typography className="error" color="secondary">
                      {message}
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          {etape < 4 ? (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Button
                color="primary"
                className="mt-1 mb-2"
                size="large"
                type="submit"
                variant="contained"
                onClick={() => {
                  setRequired((prevState) => ({
                    ...prevState,
                    required: null,
                  }));
                }}
              >
                Suivant
              </Button>
            </Grid>
          ) : null}
        </>
      ) : null}
    </>
  );
}

export default MethodeUn;
