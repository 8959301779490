import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import jwtDecode from 'jwt-decode';
import SideBar from '../../components/SideBar';
import { useStyles } from '../../styles/estimation/Classes';
import Cards from '../../components/interAgent/Cards';
import {
  findInterAgentById,
  interAgentEtat,
} from '../../service/consultantsApi';
import history from '../../helpers/history';
import TitlePage from '../../components/TitlePage';

function InterAgentRecepteur() {
  const token = localStorage.getItem('token') || false;
  const user = token ? jwtDecode(token) : null;
  const { id } = useParams();
  const form = useForm();
  const { handleSubmit } = form;
  const [etat, setEtat] = useState(null);
  const [load, setLoad] = useState(false);
  const [interAgent, setInterAgent] = useState(null);

  const onSubmit = (data, e) => {
    putEtatInterAgent();
  };

  const putEtatInterAgent = async () => {
    setLoad(true);
    try {
      const finalData = {
        etat,
      };
      await interAgentEtat(id, finalData);
    } catch (e) {
      throw new Error(e);
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    fectchInterAgent();
  }, []);

  const fectchInterAgent = async () => {
    setLoad(true);
    try {
      const data = await findInterAgentById(id);

      if (
        !user.roles.includes('ROLE_ADMIN') &&
        user.username !== data.recepteur.email
      ) {
        history.push('/');
      } else {
        setInterAgent(data);
      }
    } catch (e) {
      throw new Error(e);
    } finally {
      setLoad(false);
    }
  };
  const classes = useStyles();
  return (
    <SideBar>
      {interAgent && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Grid item xs={12} xl={12}>
            <TitlePage
              title="Inter Agent"
              underTitle="Réception d'une recommandation"
            />
          </Grid>
          <Grid className={classes.container} item xs={11} md={11} lg={8}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {interAgent && interAgent.consultant && (
                <Cards
                  className="m-0"
                  hidden={classes.hidden}
                  consultant={interAgent.consultant}
                  regle={interAgent.regle}
                />
              )}
              <DoubleArrowIcon className="m-2" fontSize="large" />
              {interAgent && interAgent.recepteur && (
                <Cards
                  hidden={classes.hidden}
                  consultant={interAgent.recepteur}
                  regle={100 - interAgent.regle}
                />
              )}
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12} xl={12}>
                  <TitlePage underTitle=" Résumé de la recommandation" />
                </Grid>
                <Grid gap={4} className={classes.field} item xs={12} md={12}>
                  {interAgent && interAgent.etat === 'accepter' && (
                    <Grid item>
                      <Typography className="title text mb-2">
                        Information sur le client :
                      </Typography>
                      <Typography className="text">
                        <span className="title">Nom et prénom :</span>{' '}
                        {interAgent.nom}
                      </Typography>
                      <Typography className="text">
                        <span className="title">Téléphone :</span>{' '}
                        {interAgent.telephone}
                      </Typography>
                      <Typography className="text">
                        <span className="title">Email :</span>{' '}
                        {interAgent.email}
                      </Typography>
                      <Typography className="text">
                        <span className="title">Localisation :</span>{' '}
                        {interAgent.localisation}.
                      </Typography>
                      {interAgent.commentaire ? (
                        <Typography className="text">
                          <span className="title">Commentaire :</span>{' '}
                          {interAgent.commentaire}
                        </Typography>
                      ) : null}
                    </Grid>
                  )}
                  <Grid item>
                    <Typography className="title text mb-2">
                      Information sur le projet :
                    </Typography>
                    <Typography className="text">
                      <span className="title">Projet :</span>{' '}
                      {interAgent.projet.map((projet) => {
                        return (
                          <span key={projet.id}>
                            {projet.toLowerCase()}
                            {interAgent.projet[interAgent.projet.length - 1] !==
                            projet
                              ? ', '
                              : '.'}
                          </span>
                        );
                      })}
                    </Typography>
                    <Typography className="text">
                      <span className="title">Jours de contact :</span>{' '}
                      {interAgent.preferenceContactJour.map(
                        (preferenceContactJour) => {
                          return (
                            <span key={preferenceContactJour.id}>
                              {preferenceContactJour.toLowerCase()}
                              {interAgent.preferenceContactJour[
                                interAgent.preferenceContactJour.length - 1
                              ] !== preferenceContactJour
                                ? ', '
                                : '.'}
                            </span>
                          );
                        },
                      )}
                    </Typography>
                    <Typography className="text">
                      <span className="title">Plage horaire :</span>{' '}
                      {interAgent.preferenceContact.map((prefContact) => {
                        return (
                          <span key={prefContact.id}>
                            {prefContact.toLowerCase()}
                            {interAgent.preferenceContact[
                              interAgent.preferenceContact.length - 1
                            ] !== prefContact
                              ? ', '
                              : '.'}
                          </span>
                        );
                      })}
                    </Typography>
                    {interAgent.commentaire ? (
                      <Typography className="text">
                        <span className="title">Commentaire :</span>{' '}
                        {interAgent.commentaire}
                      </Typography>
                    ) : null}
                  </Grid>
                  {interAgent.etat === 'refuser' && (
                    <Grid item>
                      <Typography className="title text mb-2">
                        État de la recomandation:
                      </Typography>
                      <Typography className="text">
                        {interAgent.etat}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {interAgent.recepteur.email === user.username &&
                  interAgent.etat === 'en cours' && (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      item
                    >
                      <Button
                        color="primary"
                        className="mt-1 mr-1 mb-2"
                        type="submit"
                        size="large"
                        variant="contained"
                        onClick={() => setEtat('accepter')}
                      >
                        Accepter
                      </Button>
                      <Button
                        color="primary"
                        className="mt-1 mb-2"
                        type="submit"
                        size="large"
                        variant="contained"
                        onClick={() => setEtat('refuser')}
                      >
                        Refuser
                      </Button>
                    </Grid>
                  )}
              </form>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Backdrop className={classes.backdrop} open={!!load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default InterAgentRecepteur;
