import { Button, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link } from 'react-router-dom';

function GroupeBtn({ classes, isLoading, onClick, onSubmit }) {
  const useStyles = makeStyles(() => ({
    btn: {
      width: '150px',
      // height: '40px',
    },
    btnDdl: {
      width: '230px',
      // height: '40px',
    },
    btnRetour: {
      // height: '40px',
    },
  }));

  const btnClasse = useStyles();

  return (
    <Grid
      className={classes.containerButton}
      container
      direction="row"
      justifyContent="center"
    >
      <Button
        size="small"
        disabled={isLoading}
        variant="contained"
        color="primary"
        className={`envoyer button mr-2 ${btnClasse.btn}`}
        onClick={onSubmit}
      >
        Envoyer
      </Button>
      <Button
        size="small"
        disabled={isLoading}
        className={`button mr-2 ${btnClasse.btnDdl}`}
        onClick={onClick}
        variant="contained"
      >
        Télécharger le visuel
      </Button>
      <Link className="button mr-2" to="/consultants">
        <Button
          size="small"
          className={btnClasse.btnRetour}
          variant="contained"
        >
          Retour
        </Button>
      </Link>
    </Grid>
  );
}

export default GroupeBtn;
