import React, { useState } from 'react';
import {
  Backdrop,
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import data from '../../constants/dataSelectForSyllabs';
import { useStyles } from '../../styles/estimation/Classes';
import { patchConsultant } from '../../features/consultant/consultantsSlice';

function FormPresentation({ consultant, count, setOpen, handleSubmit }) {
  const [qualities, setQualities] = useState([]);
  const [experiences, setExperiences] = useState('');
  const [crossExperiences, setCrossExperience] = useState([]);
  const [skills, setSkills] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [area, setArea] = useState('');
  const [type, setType] = useState([]);
  const [localInvolve, setLocalInvolve] = useState([]);
  const dispatch = useDispatch();

  const prompt = `
Rédigez un texte de présentation pour un conseiller immobilier indépendant. 
Le formater avec des balises HTML paragraphe. n'hésite pas a faire plusieurs phrases. donc plusieurs balises p
Voici les détails à intégrer :


- Genre : ${
    consultant.civilite && consultant.civilite.id ? 'Masculin' : 'Féminin'
  }
- Nom : ${consultant && consultant.nom} ${consultant && consultant.prenom}
- Localisation : ${consultant.ville && consultant.ville.libelle}
- Qualités : ${qualities}
- Expériences : ${experiences}
- Zone de travail : ${area} et ses environs
- Compétences professionnelles : ${skills}
- Compétences transversales : ${crossExperiences}
- Implication locale : ${localInvolve}
- Langues parlées : ${languages}
- Types de biens gérés : ${type}

Le texte doit :
- Être formaté avec des balises HTML.
- Suivre les bonnes pratiques de référencement (SEO).
- Être écrit à la première personne du singulier.
- Éviter les répétitions de mots et d'expressions.
- Être concis (environ 500 caractères).
- Corriger les fautes d'orthographe et de grammaire en français.
`;

  const handleFormSubmit = (e) => {
    e.preventDefault();
    handleSubmit(prompt);
    handleCountPresentation();
    toast.success(
      `${
        consultant.countPresentation - 1
      } chances restantes pour générer le texte.`,
    );
  };
  const handleCountPresentation = () => {
    const patch = {
      id: consultant.id,
      countPresentation: count - 1,
    };
    dispatch(patchConsultant(patch));
  };
  const handleChange = (event) => {
    const {
      target: { value, name },
    } = event;
    const isInteger = Number.isInteger(Number(value));

    if (name === 'qualities') {
      setQualities(typeof value === 'string' ? value.split(',') : value);
    }
    if (name === 'experiences') {
      setExperiences(typeof value === 'string' ? value.split(',') : value);
    }
    if (name === 'languages') {
      setLanguages(typeof value === 'string' ? value.split(',') : value);
    }
    if (name === 'skills') {
      setSkills(typeof value === 'string' ? value.split(',') : value);
    }
    if (name === 'area') {
      setArea(typeof value === 'string' ? value.split(',') : value);
    }
    if (name === 'type') {
      setType(typeof value === 'string' ? value.split(',') : value);
    }
    if (name === 'crossExperiences') {
      setCrossExperience(typeof value === 'string' ? value.split(',') : value);
    }
    if (name === 'localInvolve') {
      setLocalInvolve(typeof value === 'string' ? value.split(',') : value);
    }
  };
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography className="titleCategorie" compoment="p" align="center">
        Ma présentation
      </Typography>
      <Grid container display="flex" justifyContent="center">
        {consultant && (
          <Grid item>
            <form onSubmit={handleFormSubmit}>
              <FormControl fullWidth className="mb-1">
                <InputLabel id="qualities_label">Qualité(s)</InputLabel>
                <Select
                  fullWidth
                  label="qualities_label"
                  id="qualities"
                  name="qualities"
                  multiple
                  value={qualities}
                  onChange={handleChange}
                  required
                  InputLabelProps={{
                    style: { whiteSpace: 'pre-wrap' },
                  }}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Qualités" />
                  }
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 0.5,
                      }}
                    >
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {data.QUALITES_HUMAINE.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  Sélectionner au moins une qualité.
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth className="mb-1">
                <InputLabel id="skills_label">
                  Compétence(s) professionnelle(s)
                </InputLabel>
                <Select
                  fullWidth
                  id="competences"
                  name="skills"
                  multiple
                  required
                  InputLabelProps={{
                    style: { whiteSpace: 'pre-wrap' },
                  }}
                  value={skills}
                  onChange={handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Qualités" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {data.COMPETENCES_PRO.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  Sélectionner au moins une compétences.
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth className="mb-1">
                <InputLabel id="skills_label">
                  Compétence(s) transversale(s)
                </InputLabel>
                <Select
                  fullWidth
                  label=" Compétences transversales"
                  type="text"
                  name="crossExperiences"
                  id="experiences_label"
                  multiple
                  required
                  value={crossExperiences}
                  onChange={handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Qualités" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {data.COMPETENCES_TRANSVERSALES.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  Sélectionner au moins une compétences.
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth className="mb-1">
                <TextField
                  label=" Expériences précédentes"
                  type="text"
                  required
                  fullWidth
                  name="experiences"
                  id="experiences_label"
                  onChange={handleChange}
                />
                <FormHelperText>
                  Ex: &rdquo;J&apos;ai exercé 2 ans dans la vente&rdquo;,
                  &rdquo;J&apos;étais aide soignante&rdquo; etc...
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth className="mb-1">
                <TextField
                  label="Implication local"
                  type="text"
                  required
                  fullWidth
                  name="localInvolve"
                  id="experiences_label"
                  onChange={handleChange}
                />
                <FormHelperText>
                  Ex: &rdquo;Je suis bénévole dans une association&rdquo;,
                  &rdquo;Je suis élu(e) local&rdquo; etc...
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth className="mb-1">
                <TextField
                  label="Langues"
                  type="text"
                  fullWidth
                  name="languages"
                  id="experiences_label"
                  onChange={handleChange}
                />
                <FormHelperText>
                  Ex: &rdquo;Anglais, Polonais, Portugais, Espagnole,
                  Japonais&rdquo; etc...
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth className="mb-1">
                <TextField
                  label="Zone d'activité"
                  type="text"
                  fullWidth
                  required
                  name="area"
                  id="experiences_label"
                  onChange={handleChange}
                />
                <FormHelperText>
                  La ville ou le département dans laquelle j&rdquo;exerce.
                </FormHelperText>
              </FormControl>
              <FormControl fullWidth className="mb-1">
                <InputLabel id="type_label">Type de biens</InputLabel>
                <Select
                  fullWidth
                  label="Type de bien"
                  type="text"
                  name="type"
                  id="type_label"
                  multiple
                  required
                  value={type}
                  onChange={handleChange}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Bien" />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                >
                  {data.TYPE_DE_BIENS.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  Ex: Appartement, Maison, Parking, Terrain,etc...
                </FormHelperText>
              </FormControl>
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                direction="column"
                marginTop="-15px"
              >
                {setOpen ? (
                  <>
                    <Backdrop className={classes.backdrop} open>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <iframe
                          src="https://giphy.com/embed/JIX9t2j0ZTN9S"
                          style={{ borderRadius: '50%' }}
                          height="200px"
                          width="200px"
                          className="giphy-embed"
                          allowFullScreen
                          title="waiting gif"
                        />

                        <Typography>
                          ChatGPT génère le texte, cela peut prendre quelques
                          secondes...⌛️
                        </Typography>
                      </Grid>
                    </Backdrop>
                  </>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ width: '150px', height: '40px' }}
                  >
                    Générer
                  </Button>
                )}
              </Grid>
            </form>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default FormPresentation;
