import { ListItem } from '@mui/material';
import React from 'react';

function SideBarItem({ className, href, text, icon, target }) {
  return (
    <ListItem className={className} component="a" href={href} target={target}>
      {icon}
      {text}
    </ListItem>
  );
}

export default SideBarItem;
