import { ErrorMessage } from '@hookform/error-message';
import {
  Grid,
  Grow,
  InputAdornment,
  TextField,
  Typography,
  Button,
  ListItem,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Controller } from 'react-hook-form';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

function MethodeTrois({
  id,
  etape,
  control,
  required,
  errors,
  setRequired,
  methodeQ,
}) {
  const useStyles = makeStyles(() => ({
    etudeMarche: {
      width: '240px',
    },
    etudeLoyer: {
      width: '160px',
    },
  }));

  const classes = useStyles();
  return (
    <>
      {etape >= 5 ? (
        <Grow in>
          <Grid>
            <Typography className="titre" variant="h4" compoment="p">
              Déterminer la valeur
              <span className="titreSpan"> du bien</span>
            </Typography>
            <Grid container>
              <Typography className="titleSection" variant="subtitle2">
                Méthode 3 : Analyse des demandes acquéreurs
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              item
              className="border"
            >
              <ListItem
                className={classes.etudeMarche}
                button
                component="a"
                href="https://app.yanport.com/login"
                target="_blank"
              >
                <HomeWorkIcon
                  style={{ paddingRight: '0px!important' }}
                  fontSize="small"
                  className="mr-1"
                />
                Étude du marché apparent
              </ListItem>
              <Grid item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Demandes aquéreurs"
                      type="number"
                      placeholder="280 000"
                      inputRef={field.ref}
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                      }}
                    />
                  )}
                  name="methodeTrois"
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      required.required === true
                        ? false
                        : 'Veuillez compléter ce champ.',
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="methodeTrois"
                  render={({ message }) => (
                    <Typography className="error" color="secondary">
                      {message}
                    </Typography>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container className="mt-5">
              <Typography className="titleSection" variant="subtitle2">
                Méthode 4 : Marché de l&apos;investissement.
              </Typography>
            </Grid>

            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              item
              className="border"
            >
              <ListItem
                className={classes.etudeLoyer}
                button
                component="a"
                href="https://app.yanport.com/login"
                target="_blank"
              >
                <HomeWorkIcon fontSize="small" className="mr-1" />
                Étude de loyer
              </ListItem>
              <Grid item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Montant du loyer"
                      type="number"
                      placeholder="680"
                      inputRef={field.ref}
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                      }}
                    />
                  )}
                  name="montantLoyer"
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      required.required === true
                        ? false
                        : 'Veuillez compléter ce champ.',
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="montantLoyer"
                  render={({ message }) => (
                    <Typography className="error" color="secondary">
                      {message}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  render={({ field }) => (
                    <TextField
                      label="Rentabilité"
                      type="number"
                      placeholder="7"
                      inputRef={field.ref}
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                    />
                  )}
                  name="rentabilite"
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      required.required === true
                        ? false
                        : 'Veuillez compléter ce champ.',
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="rentabilite"
                  render={({ message }) => (
                    <Typography className="error" color="secondary">
                      {message}
                    </Typography>
                  )}
                />
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
              >
                {!id ? (
                  <Button
                    color="primary"
                    className="mt-1 mb-2"
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={() => {
                      setRequired((prevState) => ({
                        ...prevState,
                        required: 'calculRentabiliter',
                      }));
                    }}
                  >
                    Calculer
                  </Button>
                ) : null}
                {methodeQ && (
                  <Typography className="title text">
                    {methodeQ.toLocaleString('fr-FR', {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </Typography>
                )}
              </Grid>
            </Grid>

            {etape < 6 ? (
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  color="primary"
                  className="mt-1 mb-2"
                  size="large"
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    setRequired((prevState) => ({
                      ...prevState,
                      required: null,
                    }));
                  }}
                >
                  Suivant
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grow>
      ) : null}
    </>
  );
}

export default MethodeTrois;
