export const checkboxCollectif = [
  {
    titre: 'Type construction',
    check: [
      {
        label: 'Béton, aggloméré, briques (des années 50/60/70)',
        libelle: 'logementBeton',
      },
      {
        label: 'De caractère (pierres, pierres de tailles, haussmannien...)',
        libelle: 'logementCaractere',
      },
      {
        label: 'Récent',
        libelle: 'logementRecent',
      },
      {
        label: 'Neuf (moins de 10)',
        libelle: 'logementMoins10Ans',
      },
      {
        label: 'Autre',
        libelle: 'immeubleAutre',
      },
    ],
  },
  {
    titre: 'Classe',
    check: [
      {
        label:
          'Grand standing : quartier résidentiel, vue exceptionnelle, très grand confort, finitions parfaites',
        libelle: 'grandStanding',
      },
      {
        label:
          'Standing : quartier agréable, vue, balcon, grand confort, excellentes finitions',
        libelle: 'standing',
      },
      {
        label:
          'Normal : environnement acceptable, confortable, finitions normales, situation moyenne, commodités',
        libelle: 'standingNormal',
      },
      {
        label:
          'Moyenne : environnement quelconque, confort et finition bas de gamme, mal situé',
        libelle: 'moyenne',
      },
    ],
  },
  {
    titre: 'Surface',
    check: [
      {
        label: 'Jusqu’à 40 m2',
        libelle: 'moins40',
      },
      {
        label: 'De 41 à 60 m2',
        libelle: 'de40A60',
      },
      {
        label: 'De 61 à 80 m2',
        libelle: 'de60A80',
      },
      {
        label: 'De 81 à 120 m2',
        libelle: 'de80A120',
      },
      {
        label: 'Plus de 120 m2',
        libelle: 'plus120',
      },
    ],
  },
  {
    titre: 'Étage',
    check: [
      {
        label: 'RDC',
        libelle: 'rdc',
      },
      {
        label: 'Au delà du 3ème étage (par niveau)',
        libelle: 'plus3',
      },
      {
        label: 'Sans ascenseur (par niveau)',
        libelle: 'pasAscenseur',
      },
    ],
  },
  {
    titre: 'Cadre',
    check: [
      {
        label: 'Environnement difficile',
        libelle: 'environnementDifficile',
      },
      {
        label: 'Appartement calme',
        libelle: 'appartementCalme',
      },
      {
        label: 'Appartement bruyant',
        libelle: 'appartementBruyant',
      },
      {
        label: 'Espaces vert dans la résidence',
        libelle: 'appartementEspacesVerts',
      },
      {
        label: 'Immeuble sur artére roulante',
        libelle: 'immeubleRoute',
      },
      {
        label: 'Immeuble dégradé',
        libelle: 'immeubleDegrade',
      },
    ],
  },
  {
    titre: 'Exposition, Vue, Agencement',
    check: [
      {
        label: 'Vue dégagée',
        libelle: 'vueDegagee',
      },
      {
        label: 'Vue sur monument',
        libelle: 'vueMonument',
      },
      {
        label: 'Jardin',
        libelle: 'jardin',
      },
      {
        label: 'Plage',
        libelle: 'plage',
      },
      {
        label: 'Vue sur colline',
        libelle: 'colline',
      },
      {
        label: 'Vue sur vallée, pistes, montagne, village',
        libelle: 'vallee',
      },
      {
        label: 'Vue sur rue',
        libelle: 'rue',
      },
      {
        label: 'Vue sur cour',
        libelle: 'cour',
      },
      {
        label: 'Vis-à-vis, promiscuité',
        libelle: 'visAvis',
      },
      {
        label: 'Pièce de vie au Nord',
        libelle: 'vieNord',
      },
      {
        label: 'Pièce de vie au Sud',
        libelle: 'vieSud',
      },
      {
        label: 'Double orientation',
        libelle: 'doubleOrientation',
      },
      {
        label: 'Piéces mansardées',
        libelle: 'mansardees',
      },
      {
        label: 'Piéces en enfilades',
        libelle: 'enfilades',
      },
    ],
  },
  {
    titre: 'Stationnement',
    check: [
      {
        label: 'Absence de stationnement',
        libelle: 'pasStationnement',
      },
      {
        label: 'Stationnement collectif dans la résidence',
        libelle: 'stationnementCollectif',
      },
      {
        label: 'Stationnement privatif',
        libelle: 'stationnementPrivatif',
      },
      {
        label: 'Box, garage privatif',
        libelle: 'box',
      },
      {
        label: 'Garage pour 2 voitures et plus',
        libelle: 'plus2Places',
      },
    ],
  },
  {
    titre: 'Commodités Transports, Sécurité',
    check: [
      {
        label: 'Accès transport en commun à moins de 300 m',
        libelle: 'communs300MoinsM',
      },
      {
        label: 'Accès transport en commun entre 300 et 500 m',
        libelle: 'communs300Moins500',
      },
      {
        label: 'Accès transport en commun à plus de 500 m',
        libelle: 'communs500PlusM',
      },
      {
        label: 'Écoles, établissements scolaires accessibles à pied',
        libelle: 'ecoles',
      },
      {
        label: 'Gardien',
        libelle: 'gardien',
      },
      {
        label: 'Alarme',
        libelle: 'alarme',
      },
      {
        label: 'Pas d’interphone ni de code d’accès',
        libelle: 'pasInterphone',
      },
    ],
  },
  {
    titre: 'Chauffage principal',
    check: [
      {
        label: 'Individuel gaz',
        libelle: 'individuelGaz',
      },
      {
        label: 'Collectif gaz',
        libelle: 'communGaz',
      },
      {
        label: 'Électricité (convecteurs majoritairement)',
        libelle: 'electricite',
      },
      {
        label: 'Électricité système de diffusion moderne',
        libelle: 'electriciteModerne',
      },
      {
        label: 'Collectif sol au gaz',
        libelle: 'collectifSol',
      },
      {
        label: 'Solaire, éolien, géothermie...',
        libelle: 'renouvelable',
      },
    ],
  },
  {
    titre: 'Isolation, insonorisation',
    check: [
      {
        label: 'Parfait',
        libelle: 'isolationParfait',
      },
      {
        label: 'Bien',
        libelle: 'isolationBien',
      },
      {
        label: 'Moyen',
        libelle: 'isolationMoyen',
      },
      {
        label: 'À améliorer',
        libelle: 'isolationAmeliorer',
      },
    ],
  },
  {
    titre: 'Équipements intérieurs',
    check: [
      {
        label: 'Hautes prestations',
        libelle: 'hautInterieur',
      },
      {
        label: 'Bonnes prestations',
        libelle: 'bonInterieur',
      },
      {
        label: 'Absence de prestations',
        libelle: 'aucunInterieur',
      },
    ],
  },
  {
    titre: 'Équipements extérieurs',
    check: [
      {
        label: 'Hautes prestations',
        libelle: 'hautExterieur',
      },
      {
        label: 'Bonnes prestations',
        libelle: 'bonExterieur',
      },
      {
        label: 'Absence de prestations',
        libelle: 'aucunExterieur',
      },
    ],
  },
  {
    titre: 'Vétusté',
    check: [
      {
        label: 'État parfait',
        libelle: 'parfaitEtat',
      },
      {
        label: 'Bon état',
        libelle: 'bonEtat',
      },
      {
        label: 'État moyen',
        libelle: 'moyenEtat',
      },
      {
        label: 'État à revoir',
        libelle: 'mauvaisEtat',
      },
    ],
  },
  {
    titre: 'Surface annexes',
    check: [
      {
        label: 'Cave',
        libelle: 'cave',
      },
      {
        label: 'Combles aménageables',
        libelle: 'combles',
      },
      {
        label: 'Balcon',
        libelle: 'balcon',
      },
      {
        label: 'Terrasse',
        libelle: 'terrasse',
      },
    ],
  },
  {
    titre: 'Travaux à prévoir',
    check: [
      {
        label: 'Parties communes',
        libelle: 'partieCommunes',
      },
      {
        label: 'Ravalement de façade',
        libelle: 'ravalementDeFacade',
      },
      {
        label: 'Petits travaux',
        libelle: 'petitsTravaux',
      },
      {
        label: 'Gros travaux (électricité, chauffage)',
        libelle: 'grosTravaux',
      },
    ],
  },
  {
    titre: 'Charges, Impôts, Occupation ',
    check: [
      {
        label: 'Charges importantes ( cumul des charges supérieur à 30€/m2)',
        libelle: 'chargesImportantes',
      },
      {
        label: 'Impôts fonciers élevés',
        libelle: 'fonciers',
      },
      {
        label: 'Logement loué',
        libelle: 'loue',
      },
      {
        label: 'Logement loué avec difficultés juridiques et/ou financières',
        libelle: 'loueComplique',
      },
    ],
  },
];

export const checkboxIndividuel = [
  {
    titre: 'Type construction',
    check: [
      {
        label: 'Béton, aggloméré, briques (des années 50/60/70)',
        libelle: 'logementBeton',
      },
      {
        label: 'De caractère (pierres, pierres de tailles, haussmannien...)',
        libelle: 'logementCaractere',
      },
      {
        label: 'Récent',
        libelle: 'logementRecent',
      },
      {
        label: 'Neuf (moins de 10)',
        libelle: 'logementMoins10Ans',
      },
      {
        label: "Maison d'architecte",
        libelle: 'maisonArchitecte',
      },
      {
        label: 'Construction sur terre-plein',
        libelle: 'terremoinsPlein',
      },
      {
        label: 'Autre',
        libelle: 'immeubleAutre',
      },
    ],
  },
  {
    titre: 'Classe',
    check: [
      {
        label:
          'Grand standing : quartier résidentiel, vue exceptionnelle, très grand confort, finitions parfaites',
        libelle: 'grandStanding',
      },
      {
        label:
          'Standing : quartier agréable, vue, balcon, grand confort, excellentes finitions',
        libelle: 'standing',
      },
      {
        label:
          'Normal : environnement acceptable, confortable, finitions normales, situation moyenne, commodités',
        libelle: 'standingNormal',
      },
      {
        label:
          'Moyenne : environnement quelconque, confort et finition bas de gamme, mal situé',
        libelle: 'moyenne',
      },
    ],
  },
  {
    titre: 'Surface',
    check: [
      {
        label: 'Jusqu’à 40 m2',
        libelle: 'moins40',
      },
      {
        label: 'De 41 à 60 m2',
        libelle: 'de40A60',
      },
      {
        label: 'De 61 à 80 m2',
        libelle: 'de60A80',
      },
      {
        label: 'De 81 à 120 m2',
        libelle: 'de80A120',
      },
      {
        label: 'Plus de 120 m2',
        libelle: 'plus120',
      },
    ],
  },
  {
    titre: 'Cadre',
    check: [
      {
        label: 'Environnement difficile',
        libelle: 'environnementDifficile',
      },
      {
        label: 'Calme',
        libelle: 'maisonCalme',
      },
      {
        label: 'Bruyant',
        libelle: 'maisonBruyant',
      },
      {
        label: 'Terrain arboré , Paysagé',
        libelle: 'terrainPaysage',
      },
      {
        label: 'Maison sur artére roulante',
        libelle: 'maisonRoulante',
      },
      {
        label: 'Dégradé',
        libelle: 'maisonDegrade',
      },
    ],
  },
  {
    titre: 'Exposition, Vue, Agencement',
    check: [
      {
        label: 'Vue dégagée',
        libelle: 'vueDegagee',
      },
      {
        label: 'Vue sur monument',
        libelle: 'vueMonument',
      },
      {
        label: 'Jardin',
        libelle: 'jardin',
      },
      {
        label: 'Plage',
        libelle: 'plage',
      },
      {
        label: 'Vue sur colline',
        libelle: 'colline',
      },
      {
        label: 'Vue sur vallée, pistes, montagne, village',
        libelle: 'vallee',
      },
      {
        label: 'Vue sur rue',
        libelle: 'rue',
      },
      {
        label: 'Vue sur cour',
        libelle: 'cour',
      },
      {
        label: 'Vis-à-vis, promiscuité',
        libelle: 'visAvis',
      },
      {
        label: 'Pièce de vie au Nord',
        libelle: 'vieNord',
      },
      {
        label: 'Pièce de vie au Sud',
        libelle: 'vieSud',
      },
      {
        label: 'Mitoyen 1 coté',
        libelle: 'mitoyen1',
      },
      {
        label: 'Mitoyen 2 cotés',
        libelle: 'mitoyen2',
      },
      {
        label: 'Piéces mansardées',
        libelle: 'mansardees',
      },
      {
        label: 'Double orientation',
        libelle: 'doubleOrientation',
      },
      {
        label: 'Limite propriété',
        libelle: 'limitePropriete',
      },
      {
        label: 'Piéces en enfilades',
        libelle: 'enfilades',
      },
    ],
  },
  {
    titre: 'Stationnement',
    check: [
      {
        label: 'Absence de stationnement',
        libelle: 'pasStationnement',
      },
      {
        label: 'Stationnement collectif dans la résidence',
        libelle: 'stationnementCollectif',
      },
      {
        label: 'Stationnement privatif',
        libelle: 'stationnementPrivatif',
      },
      {
        label: 'Box, garage privatif',
        libelle: 'box',
      },
      {
        label: 'Garage pour 2 voitures et plus',
        libelle: 'plus2Places',
      },
    ],
  },
  {
    titre: 'Commodités Transports, Sécurité',
    check: [
      {
        label: 'Accès transport en commun à moins de 300 m',
        libelle: 'communs300MoinsM',
      },
      {
        label: 'Accès transport en commun entre 300 et 500 m',
        libelle: 'communs300Moins500',
      },
      {
        label: 'Accès transport en commun à plus de 500 m',
        libelle: 'communs500PlusM',
      },
      {
        label: 'Écoles, établissements scolaires accessibles à pied',
        libelle: 'ecoles',
      },
      {
        label: 'Gardien',
        libelle: 'gardien',
      },
      {
        label: 'Alarme',
        libelle: 'alarme',
      },
      {
        label: 'Pas d’interphone ni de code d’accès',
        libelle: 'pasInterphone',
      },
    ],
  },
  {
    titre: 'Chauffage principal',
    check: [
      {
        label: 'Individuel gaz',
        libelle: 'individuelGaz',
      },
      {
        label: 'Électricité (convecteurs majoritairement)',
        libelle: 'electricite',
      },
      {
        label: 'Électricité système de diffusion moderne',
        libelle: 'electriciteModerne',
      },
      {
        label: 'Collectif sol au gaz',
        libelle: 'collectifSol',
      },
      {
        label: 'Solaire, éolien, géothermie...',
        libelle: 'renouvelable',
      },
    ],
  },
  {
    titre: 'Isolation, insonorisation',
    check: [
      {
        label: 'Parfait',
        libelle: 'isolationParfait',
      },
      {
        label: 'Bien',
        libelle: 'isolationBien',
      },
      {
        label: 'Moyen',
        libelle: 'isolationMoyen',
      },
      {
        label: 'À améliorer',
        libelle: 'isolationAmeliorer',
      },
    ],
  },
  {
    titre: 'Équipements intérieurs',
    check: [
      {
        label: 'Hautes prestations',
        libelle: 'hautInterieur',
      },
      {
        label: 'Bonnes prestations',
        libelle: 'bonInterieur',
      },
      {
        label: 'Absence de prestations',
        libelle: 'aucunInterieur',
      },
    ],
  },
  {
    titre: 'Équipements extérieurs',
    check: [
      {
        label: 'Hautes prestations',
        libelle: 'hautExterieur',
      },
      {
        label: 'Bonnes prestations',
        libelle: 'bonExterieur',
      },
      {
        label: 'Absence de prestations',
        libelle: 'aucunExterieur',
      },
    ],
  },
  {
    titre: 'Vétusté',
    check: [
      {
        label: 'État parfait',
        libelle: 'parfaitEtat',
      },
      {
        label: 'Bon état',
        libelle: 'bonEtat',
      },
      {
        label: 'État moyen',
        libelle: 'moyenEtat',
      },
      {
        label: 'État à revoir',
        libelle: 'mauvaisEtat',
      },
    ],
  },
  {
    titre: 'Surface annexes',
    check: [
      {
        label: 'Cave',
        libelle: 'cave',
      },
      {
        label: 'Combles aménageables',
        libelle: 'combles',
      },
      {
        label: 'Sous sol total accéssible intérieur exclusivement',
        libelle: 'sousmoinsSolInterieur',
      },
      {
        label: 'Sous sol total accéssible extérieurement ',
        libelle: 'sousmoinsSolExterieur',
      },
    ],
  },
  {
    titre: 'Travaux à prévoir',
    check: [
      {
        label: 'Ravalement de façade',
        libelle: 'ravalementDeFacade',
      },
      {
        label: 'Petits travaux',
        libelle: 'petitsTravaux',
      },
      {
        label: 'Gros travaux (électricité, chauffage)',
        libelle: 'grosTravaux',
      },
    ],
  },
  {
    titre: 'Charges, Impôts, Occupation ',
    check: [
      {
        label: 'Charges importantes ( cumul des charges supérieur à 30€/m2)',
        libelle: 'chargesImportantes',
      },
      {
        label: 'Impôts fonciers élevés',
        libelle: 'fonciers',
      },
      {
        label: 'Logement loué',
        libelle: 'loue',
      },
      {
        label: 'Logement loué avec difficultés juridiques et/ou financières',
        libelle: 'loueComplique',
      },
    ],
  },
];

export const checkboxTerrain = [
  {
    titre: 'Classe',
    check: [
      {
        label:
          'Grand standing : quartier résidentiel, vue exceptionnelle, très grand confort, finitions parfaites',
        libelle: 'grandStanding',
      },
      {
        label:
          'Standing : quartier agréable, vue, balcon, grand confort, excellentes finitions',
        libelle: 'standing',
      },
      {
        label:
          'Normal : environnement acceptable, confortable, finitions normales, situation moyenne, commodités',
        libelle: 'standingNormal',
      },
      {
        label:
          'Moyenne : environnement quelconque, confort et finition bas de gamme, mal situé',
        libelle: 'moyenne',
      },
    ],
  },
  {
    titre: 'Cadre',
    check: [
      {
        label: 'Environnement difficile',
        libelle: 'environnementDifficile',
      },
      {
        label: 'Terrain arboré , Paysagé',
        libelle: 'terrainPaysage',
      },
    ],
  },
  {
    titre: 'Exposition, Vue, Agencement',
    check: [
      {
        label: 'Vue dégagée',
        libelle: 'vueDegagee',
      },
      {
        label: 'Vue sur monument',
        libelle: 'vueMonument',
      },
      {
        label: 'Jardin',
        libelle: 'jardin',
      },
      {
        label: 'Plage',
        libelle: 'plage',
      },
      {
        label: 'Vue sur colline',
        libelle: 'colline',
      },
      {
        label: 'Vue sur vallée, pistes, montagne, village',
        libelle: 'vallee',
      },
      {
        label: 'Vue sur rue',
        libelle: 'rue',
      },
      {
        label: 'Vis-à-vis, promiscuité',
        libelle: 'visAvis',
      },
      {
        label: 'Mitoyen 1 coté',
        libelle: 'mitoyen1',
      },
      {
        label: 'Mitoyen 2 cotés',
        libelle: 'mitoyen2',
      },

      {
        label: 'Limite propriété',
        libelle: 'limitePropriete',
      },
    ],
  },
  {
    titre: 'Stationnement',
    check: [
      {
        label: 'Absence de stationnement',
        libelle: 'pasStationnement',
      },
      {
        label: 'Box, garage privatif',
        libelle: 'box',
      },
      {
        label: 'Garage pour 2 voitures et plus',
        libelle: 'plus2Places',
      },
    ],
  },
  {
    titre: 'Commodités Transports, Sécurité',
    check: [
      {
        label: 'Accès transport en commun à moins de 300 m',
        libelle: 'communs300MoinsM',
      },
      {
        label: 'Accès transport en commun entre 300 et 500 m',
        libelle: 'communs300Moins500',
      },
      {
        label: 'Accès transport en commun à plus de 500 m',
        libelle: 'communs500PlusM',
      },
      {
        label: 'Écoles, établissements scolaires accessibles à pied',
        libelle: 'ecoles',
      },
    ],
  },
  // {
  //   titre: "Chauffage principal",
  //   check: [
  //     {
  //       label: "Individuel gaz",
  //       libelle: "individuelGaz",
  //     },
  //   ],
  // },
  {
    titre: 'Type de terrain',
    check: [
      {
        label: 'En lotissement',
        libelle: 'lotissement',
      },
      {
        label: 'Diffus (isolé, hors lotissement)',
        libelle: 'diffus',
      },
    ],
  },
  {
    titre: 'Constructible',
    check: [
      {
        label: 'Oui',
        libelle: 'constructibleOui',
      },
      {
        label: 'Non',
        libelle: 'constructionNon',
      },
    ],
  },
  {
    titre: 'Servitudes',
    check: [
      {
        label: 'De passage',
        libelle: 'dePassage',
      },
      {
        label: "D'écoulement",
        libelle: 'ecoulements',
      },
      {
        label: 'De service',
        libelle: 'service',
      },
    ],
  },
  {
    titre: 'Qualité du sol',
    check: [
      {
        label: 'Roche',
        libelle: 'solRoche',
      },
      {
        label: 'Argile',
        libelle: 'solArgile',
      },
      {
        label: 'Normal',
        libelle: 'solNormal',
      },
      {
        label: 'Humide, sabloneux, sources',
        libelle: 'solHumide',
      },
    ],
  },
  {
    titre: 'Forme du terrain',
    check: [
      {
        label: 'Pente importante',
        libelle: 'formeDuTerrainPenteImportante',
      },
      {
        label: 'Pente moyenne',
        libelle: 'formeDuTerrainPenteFaible',
      },
      {
        label: 'Plat',
        libelle: 'formeDuTerrainPlat',
      },
      {
        label: 'En drapeaux',
        libelle: 'formeDuTerrainDrapeau',
      },
      {
        label: 'Rectiligne',
        libelle: 'formeDuTerrainRectiligne',
      },
    ],
  },
  {
    titre: 'Viabilité',
    check: [
      {
        label: 'Sur rue',
        libelle: 'viabiliteSurRue',
      },
      {
        label: 'Terrain raccordé',
        libelle: 'viabiliteTerrainRaccorde',
      },
    ],
  },
  {
    titre: 'Coefficient',
    check: [
      {
        label: 'Trés haut',
        libelle: 'tresHautCoef',
      },
      {
        label: 'Haut',
        libelle: 'hautCoef',
      },
      {
        label: 'Moyenne',
        libelle: 'moyenCoef',
      },
      {
        label: 'Faible',
        libelle: 'faibleCoef',
      },
    ],
  },
  {
    titre: 'Assainissement',
    check: [
      {
        label: 'Collectif',
        libelle: 'assainissementCollectif',
      },
      {
        label: 'Individuel',
        libelle: 'assainissementIndividuel',
      },
    ],
  },
  {
    titre: 'Végétation',
    check: [
      {
        label: 'Contraignante (devoir élaguer ou abatre des arbres)',
        libelle: 'vegetationContraignante',
      },
      {
        label: 'Agréable',
        libelle: 'vegetationAgreable',
      },
    ],
  },
];

const appartement = [
  { value: 'étage', label: 'Etage' },
  { value: 'ascenseur', label: 'Ascenseur' },
  { value: 'charges copropriété', label: 'Charges copropriété' },
  { value: 'gardien', label: 'Gardien' },
  { value: 'balcon', label: 'Balcon' },
  { value: 'duplex', label: 'Duplex' },
  { value: 'triplex', label: 'Triplex' },
];

const maison = [
  { value: 'mitoyenneté', label: 'Mitoyenneté' },
  { value: 'cour', label: 'Cour' },
  { value: 'terrain', label: 'Terrain' },
];

const terrain = [
  { value: 'facade', label: 'Facade' },
  { value: 'constructible', label: 'Constructible' },
  { value: 'urbanisme', label: 'Urbanisme' },
  { value: 'viabilisé', label: 'Viabilisé' },
  { value: 'coup de pêche', label: 'Coup de pêche' },
  { value: "point d'eau", label: "Point d'eau" },
];

const local = [
  { value: 'surface exploitation', label: 'Surface exploitation' },
  { value: 'potentiel', label: 'Potentiel' },
  { value: 'activité en cours', label: 'Activité en cours' },
];

const immeuble = [
  { value: 'rentabilité', label: 'Rentabilité' },
  { value: 'logements vacants', label: 'Logements vacants' },
  { value: 'logements loués', label: 'Logements loués' },
  { value: 'copropriété', label: 'Copropriétés' },
];

const environnement = [
  { value: 'emplacement', label: 'Emplacement' },
  { value: 'commerces', label: 'Commerces' },
  { value: 'écoles', label: 'Ecoles' },
  { value: 'transports', label: 'Transports' },
  { value: 'exposition', label: 'Exposition' },
  { value: 'acces routiers', label: 'Acces routiers' },
  { value: 'acces au bien', label: 'Acces au bien' },
];

const travaux = [
  { value: 'gros oeuvre', label: 'Gros oeuvre' },
  { value: 'toiture', label: 'Toiture' },
  { value: 'sols', label: 'Sols' },
  { value: 'Murs', label: 'Murs' },
  { value: 'menuiseries extérieures', label: 'Menuiseries extérieures' },
  { value: 'chauffage', label: 'Chauffage' },
  { value: 'cuisine', label: 'Cuisine' },
  { value: 'sanitaires', label: 'Sanitaires' },
  { value: 'électricité', label: 'Electricité' },
  { value: 'état intérieur général', label: 'Etat intérieur général' },
  { value: 'ravalement', label: 'Ravalement' },
];

const agencement = [
  { value: 'distribution', label: 'Distribution' },
  { value: 'surface annexe', label: 'Surface annexe' },
  { value: 'stationnement', label: 'Stationnement' },
  { value: 'commodites', label: 'Commodites' },
  { value: 'équipement', label: 'Equipement' },
  { value: 'style du bien', label: 'Style du bien' },
  { value: 'standing', label: 'Standing' },
];

const situation = [
  { value: 'vide', label: 'Vide' },
  { value: 'loue', label: 'Loue' },
];

export const groupedOptions = [
  {
    label: 'Appartement',
    options: appartement,
  },
  {
    label: 'Maison',
    options: maison,
  },
  {
    label: 'Terrain',
    options: terrain,
  },
  {
    label: 'Local commercial',
    options: local,
  },
  {
    label: 'Immeuble',
    options: immeuble,
  },
  {
    label: 'Environnement',
    options: environnement,
  },
  {
    label: 'Travaux',
    options: travaux,
  },
  {
    label: 'Agencement',
    options: agencement,
  },
  {
    label: 'Situation du bien',
    options: situation,
  },
];

export const groupedOptionsMaison = [
  {
    label: 'Maison',
    options: maison,
  },
  {
    label: 'Terrain',
    options: terrain,
  },
  {
    label: 'Environnement',
    options: environnement,
  },
  {
    label: 'Travaux',
    options: travaux,
  },
  {
    label: 'Agencement',
    options: agencement,
  },
  {
    label: 'Situation du bien',
    options: situation,
  },
];
