import {
  Button,
  CircularProgress,
  Grid,
  Slider,
  TextField,
  Typography,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import SideBar from '../../components/SideBar';
import Contact from '../../components/synergie/Contact';
import PreferenceContact from '../../components/synergie/PreferenceContact';
import Projet from '../../components/synergie/Projet';
import TypeProjet from '../../components/synergie/TypeProjet';
import { useStyles } from '../../styles/estimation/Classes';
import { postInterAgent } from '../../service/consultantsApi';
import AutocompleteAddress from '../../components/AutocompleteAddress';
import TitlePage from '../../components/TitlePage';

function InterAgentEmetteur() {
  const { id } = useParams();
  const form = useForm();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = form;
  const [load, setLoad] = useState(false);
  const [lastAdress, setLastAdress] = useState();
  const [checkedValuesJour, setCheckedValuesJour] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [checkedValuesProjet, setCheckedValuesProjet] = useState([]);
  const [rgpd, setRgpd] = useState(false);
  const [regle, setRegle] = useState(50);
  const [comment, setComment] = useState('');
  const [error, setError] = useState({
    localisation: '',
    projet: '',
    jour: '',
    contact: '',
    commentaire: '',
  });
  const marks = [
    {
      value: 20,
      label: '20%',
    },
    {
      value: 30,
      label: '30%',
    },
    {
      value: 40,
      label: '40%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 60,
      label: '60%',
    },
    {
      value: 70,
      label: '70%',
    },
    {
      value: 80,
      label: '80%',
    },
  ];
  const onSubmit = (data, e) => {
    const finalData = {
      ...data,
      preferenceContactJour: checkedValuesJour,
      preferenceContact: checkedValues,
      projet: checkedValuesProjet,
      date: new Date(),
      localisation: lastAdress,
      regle,
      recepteur: `/api/users/${id}`,
      etat: 'en cours',
      commentaire: comment,
    };
    if (finalData.localisation === '' || finalData.localisation === undefined) {
      setError((prevState) => ({
        ...prevState,
        localisation: 'Veuillez compléter ce champ.',
      }));
    } else if (checkedValuesProjet.length === 0) {
      setError((prevState) => ({
        ...prevState,
        projet: 'Veuillez compléter ce champ.',
      }));
    } else if (checkedValuesJour.length === 0) {
      setError((prevState) => ({
        ...prevState,
        jour: 'Veuillez compléter ce champ.',
      }));
    } else if (checkedValues.length === 0) {
      setError((prevState) => ({
        ...prevState,
        contact: 'Veuillez compléter ce champ.',
      }));
    } else {
      sendInterAgent(finalData);
    }
    reset();
  };
  const sendInterAgent = async (data) => {
    setLoad(true);
    try {
      await postInterAgent(data);
    } catch (e) {
      throw new Error(e);
    } finally {
      setLoad(false);
    }
  };

  const week = [' Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

  const contacts = [
    'Début de matinée',
    'Fin de matinée',
    "Début d'après-midi",
    "Fin d'après-midi",
  ];

  const projets = ['Mise en vente', 'Achat', 'Estimation'];

  const handleCheckedJour = (e) => {
    const jour = week[e.target.dataset.id];
    const newCheckedValuesJour = checkedValuesJour.filter(
      (item) => item !== jour,
    );
    if (e.target.checked) newCheckedValuesJour.push(jour);
    setError((prevState) => ({
      ...prevState,
      jour: '',
    }));
    setCheckedValuesJour(newCheckedValuesJour);
  };

  const handleChecked = (e) => {
    const contact = contacts[e.target.dataset.id];
    const newCheckedValues = checkedValues.filter((item) => item !== contact);
    if (e.target.checked) newCheckedValues.push(contact);
    setError((prevState) => ({
      ...prevState,
      contact: '',
    }));
    setCheckedValues(newCheckedValues);
  };

  const handleCheckedProjet = (e) => {
    const projet = projets[e.target.dataset.id];
    const newCheckedValuesProjet = checkedValuesProjet.filter(
      (item) => item !== projet,
    );
    if (e.target.checked) newCheckedValuesProjet.push(projet);
    setError((prevState) => ({
      ...prevState,
      projet: '',
    }));
    setCheckedValuesProjet(newCheckedValuesProjet);
  };

  const classes = useStyles();
  return (
    <SideBar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <TitlePage
          title="Inter Agent"
          underTitle="Envoi d'une recommandation"
        />
        <Grid className="p-5" item xs={11}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Contact
              name="nom"
              firstname="prenom"
              telephone="telephone"
              email="email"
              title="Contact"
              control={control}
              errors={errors}
              error={error}
              setError={setError}
              required
            />
            <AutocompleteAddress
              setLastAdress={setLastAdress}
              control={control}
              errors={errors}
              error={error}
              setError={setError}
              title="Localisation"
              label="Adresse ou secteur du projet ( ville )"
            />
            <Projet control={control} errors={errors} />
            <TypeProjet
              projets={projets}
              handleChecked={handleCheckedProjet}
              error={error}
              register={form.register}
              title="Type de projet"
              name="projet"
            />
            <PreferenceContact
              week={week}
              handleCheckedJour={handleCheckedJour}
              register={form.register}
              error={error}
              contacts={contacts}
              handleChecked={handleChecked}
            />
            <Grid className={classes.field} item xs={12} md={12}>
              <Typography className="title text">
                Règle de partage pour la recommandation
              </Typography>
              <Slider
                size="small"
                min={20}
                max={80}
                defaultValue={50}
                step={10}
                aria-label="Small"
                valueLabelDisplay="on"
                onChange={(value) => setRegle(value.target.value)}
                marks={marks}
              />
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                wrap="wrap"
              >
                <Grid>
                  <Typography className="title text">
                    Pour lui / elle : {100 - regle}%
                  </Typography>
                </Grid>
                <Grid>
                  <Typography className="title text">
                    Pour moi : {regle}%
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.field} item xs={12} md={12}>
              <Typography className="title text">Commentaire</Typography>
              <TextField
                type="text"
                control={control}
                errors={errors}
                name="commentaire"
                onChange={(e) => setComment(e.currentTarget.value)}
              />
            </Grid>
            <Grid className={classes.field} item xs={12} md={12}>
              <Typography className="title text">
                <Checkbox name="rgpd" onClick={() => setRgpd(!rgpd)} />*
                J’accepte la politique de protection des données personnelles du
                réseau Le Bon Agent accessible{' '}
                <Typography
                  className="title text"
                  component={Link}
                  to="/mentions-legales"
                >
                  &quot;ici&quot;.
                </Typography>
              </Typography>

              <details open="">
                <summary>Voir plus...</summary>
                Le Bon Agent traite les données recueillies afin de donner suite
                à votre demande de prise de contact dans les meilleurs délais.
                Pour en savoir plus sur la gestion de vos données et vos droits,
                veuillez dérouler ci-dessous.Les informations que j’inscris dans
                ce formulaire sont indispensables à la prise de contact que je
                demande. Elles sont exclusivement destinées au réseau Le Bon
                Agent, et sont conservées sur une durée de 10 ans à compter du
                dernier échange. Je dispose du droit de demander l’accès, la
                rectification, la portabilité ou l’effacement de mes données,
                mais également la limitation ou l’opposition au traitement de
                celles-ci, ainsi que la liste de leurs destinataires, au délégué
                à la protection des données <strong>rgpd@sas-arche.com</strong>.
                Je peux introduire à tout moment une réclamation auprès de la
                CNIL. Pour plus d’informations, se rapporter à la politique de
                confidentialité.
              </details>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              item
            >
              {rgpd && (
                <Button
                  color="primary"
                  className="mt-1 mb-4"
                  type="submit"
                  size="large"
                  variant="contained"
                >
                  Envoyer
                </Button>
              )}
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={!!load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default InterAgentEmetteur;
