import _ from 'lodash';
import AdminAnimationConsultant from '../pages/admin/AdminAnimationConsultant';
import AdminConsultant from '../pages/admin/AdminConsultant';
import AdminContact from '../pages/admin/Contact';
import AdminEditConsultant from '../pages/admin/AdminEditConsultant';
import AdminEstimation from '../pages/Estimation';
import AdminGestionEstimation from '../pages/GestionEstimation';
import AdminPhotoConsultant from '../pages/admin/AdminPhotoConsultant';
import AdminScoring from '../pages/Scoring';
import HomePage from '../pages/HomePage';
import SyngergieAPI from '../pages/synergie/SynergieAPI';
import SyngergieAsAssocie from '../pages/synergie/SynergieAsAssocie';
import SyngergieCitya from '../pages/synergie/SynergieCitya';
import SyngergieSPA from '../pages/synergie/SynergieSPA';
import SyngergieSphereImmo from '../pages/synergie/SynergieSphereImmo';
import UndefinedPage from '../pages/UndefinedPage';
import Sam from '../pages/Sam';
import rolesConstants from './security';
import InterAgentReco from '../pages/interAgent/InterAgentReco';
import InterAgentEmetteur from '../pages/interAgent/InterAgentEmetteur';
import InterAgentRecepteur from '../pages/interAgent/InterAgentRecepteur';
import GestionInterAgent from '../pages/interAgent/GestionInterAgent';
import Event from '../pages/Event';
import EventCreate from '../components/evenement/FormEvent';
import EventEdit from '../components/evenement/EventEdit';
import PdfError from '../pages/PdfError';
import Profile from '../pages/Profile';
import FormProfile from '../components/profile/FormPresentation';
import StatsCompany from '../pages/StatsCompany';
import SynergieCousinHub from '../pages/synergie/SynergieCousinHub';

export const API_URL = process.env.REACT_APP_URL_API;
export const API_LBA = process.env.REACT_APP_URL_LBA;

export const OPENAI_KEY = process.env.REACT_APP_OPENAI_API_KEY;

export const CONSULTANTS_API = `${API_URL}/users`;
export const BADGES_API = `${API_URL}/badges`;
export const ESTIMATION_API = `${API_URL}/estimations`;
export const ESTIMATION_STATS_API = `${API_URL}/estimation_stats`;
export const CONSULTANT_STATS_API = `${API_URL}/consultant_stats`;
export const ESTIMATION_PHOTOS_API = `${API_URL}/estimation_photos`;
export const CONTACTS_API = `${API_URL}/contacts`;
export const CONTACTS_API_RECRUTEMENT = `${API_URL}/contact_recrutements`;
export const CONTACTS_API_ESTIMATION = `${API_URL}/contact_estimations`;
export const CONTACTS_API_PROSPECT = `${API_URL}/contact_prospects`;
export const CONTACTS_API_VENTE = `${API_URL}/contact_vendres`;
export const CONTACTS_API_CONSULTANT = `${API_URL}/contact_consultants`;
export const SCORING_MANDATS = `${API_URL}/scoring_mandats`;
export const LOGIN_API = `${API_URL}/login_check`;
export const FORGOTTEN_PASSWORD = `${API_LBA}/forgotten-password`;
export const RESET_PASSWORD = `${API_LBA}/reset-password`;
export const EVENEMENT_API = `${API_URL}/evenements`;
export const SYNERGIES_API = `${API_URL}/synergies`;
export const PARTICIPANT_API = `${API_URL}/participant_evenements`;

// routes de l'appli
export const routes = [
  {
    path: '/',
    component: HomePage,
    name: 'Le Bon Agent',
    public: false,
    permission: [rolesConstants.ADMIN, rolesConstants.CONSULTANT],
  },
  {
    path: '/consultants/photo/:id',
    component: AdminPhotoConsultant,
    name: 'Photo consultant',
    public: false,
    permission: [rolesConstants.ADMIN],
  },
  {
    path: '/consultants/:id',
    component: AdminEditConsultant,
    name: 'Edition consultant',
    public: false,
    permission: [rolesConstants.ADMIN],
  },
  {
    path: '/estimation/:idEstimation',
    component: AdminEstimation,
    name: 'Estimation',
    public: false,
    permission: [rolesConstants.ADMIN, rolesConstants.CONSULTANT],
  },
  {
    path: '/mes-estimations',
    component: AdminGestionEstimation,
    name: 'Gestion Estimation',
    public: false,
    permission: [rolesConstants.ADMIN, rolesConstants.CONSULTANT],
  },
  {
    path: '/consultants',
    component: AdminConsultant,
    name: 'Administration consultant',
    public: false,
    permission: [rolesConstants.ADMIN],
  },
  {
    path: '/scoring',
    component: AdminScoring,
    name: 'Scoring',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/scoring-mandats',
    component: Sam,
    name: 'Scoring mandats',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/contact',
    component: AdminContact,
    name: 'Contact',
    public: false,
    permission: [rolesConstants.ADMIN],
  },
  {
    path: '/animation/consultants',
    component: AdminAnimationConsultant,
    name: 'Animation consultant',
    public: false,
    permission: [rolesConstants.ADMIN],
  },
  {
    path: '/contact/api',
    component: SyngergieAPI,
    name: 'Contact API',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/contact/citya',
    component: SyngergieCitya,
    name: 'Contact Citya',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/contact/sphere-immo',
    component: SyngergieSphereImmo,
    name: 'Contact Sphere-Immo',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/contact/saint-pierre-assurances',
    component: SyngergieSPA,
    name: 'Contact Saint Pierre Assurances',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/contact/as-et-associes',
    component: SyngergieAsAssocie,
    name: 'Contact As et Associe',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/contact/cousin-hub',
    component: SynergieCousinHub,
    name: 'Contact Cousin HUB',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/inter-agent',
    component: GestionInterAgent,
    name: 'Inter Agent Emetteur',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/inter-agent/recherche',
    component: InterAgentReco,
    name: 'Inter Agent',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/inter-agent/recommandation/:id',
    component: InterAgentEmetteur,
    name: 'Inter Agent Emetteur',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/inter-agent/recommandation/reception/:id',
    component: InterAgentRecepteur,
    name: 'Inter Agent Emetteur',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/creer-evenement/',
    component: EventCreate,
    name: 'Evenement créer',
    public: false,
    permission: [rolesConstants.AMBASSADEUR, rolesConstants.ADMIN],
  },
  {
    path: '/modifier-evenement/:id',
    component: EventEdit,
    name: 'Evenement modifier',
    public: false,
    permission: [rolesConstants.AMBASSADEUR, rolesConstants.ADMIN],
  },
  {
    path: '/mes-evenements',
    component: Event,
    name: 'Evenements',
    public: false,
    permission: [rolesConstants.AMBASSADEUR, rolesConstants.ADMIN],
  },
  {
    path: '/undefined',
    component: UndefinedPage,
    name: 'Undefined',
    public: false,
    permission: [rolesConstants.CONSULTANT, rolesConstants.ADMIN],
  },
  {
    path: '/pdf/:id',
    component: PdfError,
    name: 'Error pdf',
    public: false,
    permission: [rolesConstants.AMBASSADEUR, rolesConstants.ADMIN],
  },
  {
    path: '/profil/:id',
    component: Profile,
    name: 'profil individuel',
    public: false,
    permission: [rolesConstants.ADMIN, rolesConstants.CONSULTANT],
  },
  {
    path: '/formulaire-presentation/:id',
    component: FormProfile,
    name: 'formulaire de présentation',
    public: false,
    permission: [rolesConstants.ADMIN, rolesConstants.CONSULTANT],
  },
  {
    path: '/statistiques-entreprise',
    component: StatsCompany,
    name: "Statistiques de l'entreprise",
    public: false,
    permission: [rolesConstants.ADMIN, rolesConstants.CONSULTANT],
  },
];

export const routesObj = _.mapKeys(routes, 'path');
