/* eslint-disable react/no-unstable-nested-components, react/function-component-definition, no-unused-expressions */

import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Form from '../components/sam/Form';
import FormText from '../components/sam/FormText';
import SideBar from '../components/SideBar';
import * as sam from '../constants/sam';
import { useStyles } from '../styles/estimation/Classes';
import { postScoring } from '../service/scoringApi';
import { API_LBA } from '../constants/routes';
import useErrorHandler from '../hooks/useErrorHandler';

function Sam() {
  const [dataResult, setDataResult] = useState({ id: null, resultat: null });
  const [load, setLoad] = useState(false);
  const { errorHandler } = useErrorHandler();
  const form = useForm();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = form;

  const classes = useStyles();

  const onSubmit = async (data, e) => {
    setLoad(true);
    let res;
    try {
      res = await postScoring(data);
      setDataResult((prevState) => ({
        ...prevState,
        id: res.id,
        resultat: res.resultat,
      }));
    } catch (error) {
      errorHandler(error);
    } finally {
      setLoad(false);
    }
    reset();
  };

  useEffect(() => {
    dataResult.id &&
      toast.success(ToastWithLink, {
        position: 'top-center',
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

    return () => {
      setDataResult((prevState) => ({
        ...prevState,
        id: null,
        resultat: null,
      }));
    };
  }, [dataResult.id]);

  const ToastWithLink = () => (
    <a
      className={classes.ddl}
      href={`${API_LBA}/scoring-mandats/pdf/${dataResult.id}`}
      download
      target="_blank"
      rel="noreferrer"
      style={{ textAlign: 'center', width: '100%', lineHeight: '25px' }}
    >
      <div>
        <p>
          Vous avez {dataResult.resultat}% de chances de vendre votre mandat en
          moins de 3 mois.
        </p>
        <p style={{ color: '#3f525c' }}>
          Téléchargez le résultat au format PDF
        </p>
      </div>
    </a>
  );

  return (
    <SideBar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} xl={12}>
          <Typography className="ariane mt-3 mb-3" compoment="p" align="center">
            Sam (Scoring analyse mandat)
          </Typography>
        </Grid>
        <Grid className={classes.container} item xs={11} md={11} lg={8}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Grid className={classes.field} item xs={12} md={12}>
              <Typography className="title text toto">
                Calculez le pourcentage moyen de chances de vendre votre mandats
                dans un délai inférieur à 3 mois.
              </Typography>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Form
                  title="Prix de la commercialisation"
                  name="prixCommercialisation"
                  control={control}
                  errors={errors}
                  array={sam.prixCommercialisation}
                />
                <Form
                  title="Fourchette de prix"
                  name="fourchettePrix"
                  control={control}
                  errors={errors}
                  array={sam.fourchettePrix}
                />
                <Form
                  title="Type de mandat"
                  name="typeMandat"
                  control={control}
                  errors={errors}
                  array={sam.typeMandat}
                />
                <Form
                  title="Motivation"
                  name="motivation"
                  control={control}
                  errors={errors}
                  array={sam.motivation}
                />
                <Form
                  title="Périmétre agent"
                  name="perimetreAgent"
                  control={control}
                  errors={errors}
                  array={sam.perimetreAgent}
                />
                <Form
                  title="Clés"
                  name="cles"
                  control={control}
                  errors={errors}
                  array={sam.cles}
                />
              </Grid>
            </Grid>
            <Grid className={classes.field} item xs={12} md={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <Form
                  title="Dominance du marché"
                  name="dominanceMarche"
                  control={control}
                  errors={errors}
                  array={sam.dominanceMarche}
                />
                <Form
                  title="Type de bien"
                  name="typeBien"
                  control={control}
                  errors={errors}
                  array={sam.typeBien}
                />
                <Form
                  title="Disponibilité du bien"
                  name="disponibiliteBien"
                  control={control}
                  errors={errors}
                  array={sam.disponibiliteBien}
                />
                <Form
                  title="Situation géographique"
                  name="situationGeographique"
                  control={control}
                  errors={errors}
                  array={sam.situationGeographique}
                />
                <Form
                  title="Dossier complet ?"
                  name="dossierComplet"
                  control={control}
                  errors={errors}
                  array={sam.dossierComplet}
                />
                <Form
                  title="Panneau"
                  name="panneau"
                  control={control}
                  errors={errors}
                  array={sam.panneau}
                />
              </Grid>
            </Grid>
            <Grid className={classes.field} item xs={12} md={12}>
              <Grid
                container
                direction="row"
                justifyContent="space-around"
                alignItems="center"
              >
                <FormText
                  placeholder="Numéro + rue"
                  label="Adresse du bien"
                  name="adresse"
                  errors={errors}
                  control={control}
                />
                <FormText
                  placeholder="CP"
                  label="Code postal"
                  name="codePostal"
                  errors={errors}
                  control={control}
                />
                <FormText
                  placeholder="Ville du bien"
                  label="Ville"
                  name="ville"
                  errors={errors}
                  control={control}
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              item
            >
              <Button
                color="primary"
                className="mt-1 mb-4"
                type="submit"
                size="large"
                variant="contained"
              >
                Faites le calcul !
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default Sam;
