import { Grid, IconButton, Typography } from '@mui/material';
import { Work } from '@material-ui/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import SideBarItem from '../SideBarItem';
import { menu } from '../../constants/menu';

function MyCompany() {
  return (
    <Grid
      container
      item
      spacing={1}
      alignItems="flex-end"
      justifyContent="center"
      gap="10%"
    >
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">Stats</Typography>
          <IconButton
            className="iconButton"
            component={Link}
            to="statistiques-entreprise"
          >
            <Work fontSize="large" className="icon" />
          </IconButton>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">Presse</Typography>
          <SideBarItem
            key={Math.random()}
            icon={menu.Presse.icon}
            target={menu.Presse.target}
            href={menu.Presse.href}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">
            Au
            <span
              style={{
                color: '#7ad0ef',
                paddingLeft: '3.2px',
                paddingRight: '3.2px',
                fontSize: 'large',
              }}
            >
              &#x2665;
            </span>
            de l&apos;annonce
          </Typography>
          <SideBarItem
            key={Math.random()}
            icon={menu['Au coeur '].icon}
            target={menu['Au coeur '].target}
            href={menu['Au coeur '].href}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default MyCompany;
