import styled from 'styled-components';

const ContainerImages = styled.div`
  position: relative;
  .image {
    opacity: 1;
    display: block;
    width: 300px;
    height: 300px;
    transition: 0.5s ease;
    backface-visibility: hidden;
    object-fit: contain;
  }

  .overlay {
    transition: 0.5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  &:hover .image-hover {
    opacity: 0.3;
  }

  &:hover .overlay {
    opacity: 1;
  }
`;

export default ContainerImages;
