import { Backdrop, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import fileDownload from 'js-file-download';
import Paper from '@mui/material/Paper';
import ChoiceTemplate from '../../components/animation/ChoiceTemplate';
import SideBar from '../../components/SideBar';
import TitlePage from '../../components/TitlePage';
import { API_LBA } from '../../constants/routes';
import axios from '../../helpers/axios';

function AdminAnimationConsultant() {
  const form = useForm();
  const {
    control,
    formState: { errors },
  } = form;
  const [isLoading, setIsLoading] = useState(false);
  const [imageData, setImageData] = useState({
    template: '',
    consultant: '',
  });

  const handleChangeRadio = (event) => {
    setImageData((prevState) => ({
      ...prevState,
      template: event.target.value,
    }));
  };

  const onSubmit = () => {
    if (imageData.template && imageData.consultant) {
      setIsLoading(true);
      axios({
        method: 'post',
        url: `${API_LBA}/media/send`,
        data: JSON.stringify(imageData),
      })
        .then((response) => {
          setIsLoading(false);
          toast.success(response.data);
        })
        .catch(() => {
          setIsLoading(false);
          toast.error('Une erreur est survenue');
        });
    } else {
      toast.error('Template ou consultant manquant');
    }
  };
  // filedowloader pour télécharger l'image blob directement apres le click
  const onClick = () => {
    // Pas besoin d'imageData car on récupère tout depuis l'evenement (une seule image donc on la définit en "dur" côté back
    if (imageData.template && imageData.consultant) {
      setIsLoading(true);
      axios({
        method: 'post',
        url: `${API_LBA}/media/download`,
        data: JSON.stringify(imageData),
        responseType: 'blob',
      })
        .then(function (response) {
          setIsLoading(false);
          toast.success(response.data);
          fileDownload(response.data, `${Date.now()}.png`);
        })
        .catch(function (e) {
          setIsLoading(false);
          const reader = new FileReader();
          reader.onload = function () {
            toast.error(decodeURIComponent(JSON.parse(reader.result)));
          };
          reader.readAsText(e.response.data);
        });
    } else {
      toast.error('Template ou consultant manquant');
    }
  };
  const useStyles = makeStyles((theme) => ({
    container: {
      [theme.breakpoints.down('lg')]: {
        marginTop: '25px',
      },
    },
    containerButton: {
      [theme.breakpoints.down('lg')]: {
        marginTop: '80px',
      },
      '& .button': {
        [theme.breakpoints.down('lg')]: {
          marginBottom: '25px',
        },
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }));

  const classes = useStyles();

  const Styles = makeStyles(() => ({
    grid: {
      height: '500px',
    },
  }));

  const style = Styles();

  return (
    <SideBar>
      <Grid
        className={style.grid}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
      >
        <TitlePage
          title="Animation réseau consultants"
          underTitle="Animez votre réseau de consultants en leur envoyant des emails
          pour célébrer leurs accomplissements"
        />

        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          xs={11}
          component={Paper}
        >
          <Grid className="p-5" item xs={12}>
            <ChoiceTemplate
              classes={classes}
              handleChangeRadio={handleChangeRadio}
              errors={errors}
              setImageData={setImageData}
              onSubmit={onSubmit}
              onClick={onClick}
              isLoading={isLoading}
              control={control}
            />
          </Grid>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={!!isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default AdminAnimationConsultant;
