import { Grid, Typography } from '@mui/material';
import React from 'react';

function TitlePage({ title, underTitle }) {
  return (
    <Grid>
      <Typography
        sx={{ colors: 'white' }}
        className="ariane mt-3"
        compoment="p"
        align="center"
      >
        {title}
      </Typography>
      <Typography align="center" component="p" className="subAriane">
        {underTitle}
      </Typography>
    </Grid>
  );
}

export default TitlePage;
