import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import LoginForm from '../styles/pages/login/LoginForm';
import { sendForgottenPassword } from '../features/auth/authSlice';
import TitlePage from '../components/TitlePage';

function ForgottenPassword() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth); // Update with the correct slice name
  const form = useForm();
  const { register, handleSubmit, reset } = form;

  const onSubmit = async (data) => {
    const credentials = {
      email: data.email,
    };
    dispatch(sendForgottenPassword(credentials));
    reset();
  };
  const useStyles = makeStyles((theme) => ({
    container: {
      marginTop: theme.spacing(10),
      // maxWidth: 600,
    },
    formContainer: {
      maxWidth: 600,
      padding: theme.spacing(4),
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderRadius: theme.shape.borderRadius,
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    submitButton: {
      marginTop: theme.spacing(1),
    },

    forgotPasswordLink: {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
      marginTop: theme.spacing(1),
    },
    registerLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
      marginBottom: theme.spacing(0),
    },
    logo: {
      maxWidth: '100px',
      maxHeight: '100px',
    },
  }));
  const classes = useStyles();
  return (
    <LoginForm>
      <Container>
        <TitlePage title="MON ENTREPRISE" underTitle="Mot de passe oublié" />
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6} className={classes.formContainer}>
            <Grid container justifyContent="center" paddingBottom={2}>
              <img
                src="/svg/lba.svg"
                className={classes.logo}
                alt="lebonagent logo"
              />
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth variant="standard">
                <TextField
                  id="email"
                  name="email"
                  className="mb-2"
                  placeholder="Adresse mail de connexion"
                  variant="outlined"
                  helperText="Ce champ est requis"
                  {...register('email', { required: true })}
                />
                <Grid container justifyContent="center">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    size="large"
                  >
                    {!loading ? (
                      'envoi'
                    ) : (
                      <CircularProgress
                        style={{ color: 'white' }}
                        size="15px"
                      />
                    )}
                  </Button>{' '}
                </Grid>
              </FormControl>
            </form>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="containerArche mb-2"
      >
        <img
          className="mb-1 logoArche"
          src="https://media.sas-arche.com/apps_logos_white/arche.svg"
          alt="logo arche"
        />
        <Typography variant="caption" className="textWhite">
          ©{new Date().getFullYear()}, tous droits réservés
        </Typography>
      </Grid>
    </LoginForm>
  );
}

export default ForgottenPassword;
