import { ErrorMessage } from '@hookform/error-message';
import { Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import { useStyles } from '../../styles/estimation/Classes';

function Projet({ control, errors }) {
  const classes = useStyles();
  return (
    <Grid className={classes.field} item xs={12} md={12}>
      <Typography className="title text toto">Projet</Typography>
      <Controller
        render={({ field }) => (
          <TextField
            placeholder="Tours 37"
            label="Adresse ou secteur du projet ( ville et département )"
            type="text"
            inputRef={field.ref}
            {...field}
          />
        )}
        name="secteur"
        control={control}
        defaultValue=""
        rules={{
          required: 'Veuillez compléter ce champ.',
          maxLength: {
            value: 255,
            message: 'Le nombre maximum de caractères a été dépassé',
          },
        }}
      />
      <ErrorMessage
        errors={errors}
        name="secteur"
        render={({ message }) => (
          <Typography className="error" color="secondary">
            {message}
          </Typography>
        )}
      />
    </Grid>
  );
}

export default Projet;
