import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link } from 'react-router-dom';
import { API_LBA } from '../../constants/routes';
import ContainerImages from '../../styles/pages/consultants/ContainerImages';

function Cards({ consultant, hidden, hiddenRegle, regle }) {
  const useStyles = makeStyles((theme) => ({
    card: {
      marginBottom: '20px',
      maxWidth: '300px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
      },
      '& .bareme-link': {
        color: 'black',
      },
    },
  }));

  const classes = useStyles();
  return (
    <Card key={Math.random()} className={classes.card}>
      <ContainerImages>
        <CardMedia
          className="image"
          component="img"
          height="240"
          image={
            consultant.photoName
              ? `${API_LBA}/media/images/consultants/${consultant.num}/${consultant.photoName}`
              : '/images/avatar-h.png'
          }
        />
      </ContainerImages>
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {consultant.prenom} {consultant.nom}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {consultant.ville ? consultant.ville.ville : null}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {consultant.ville ? consultant.ville.departement.libelle : null}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {consultant.email}
        </Typography>
        <Typography
          className={hiddenRegle}
          variant="body2"
          color="text.secondary"
        >
          <span className="title">Régle de partage :</span>{' '}
          <Typography sx={{ fontWeight: 'bold', fontSize: 'large' }}>
            {regle} %
          </Typography>
        </Typography>
      </CardContent>
      <Link
        className={hidden}
        to={`/inter-agent/recommandation/${consultant.id}`}
        props={consultant}
      >
        <Button fullWidth variant="contained" color="primary">
          Faire une recommandation
        </Button>
      </Link>
    </Card>
  );
}

export default Cards;
