import styled from 'styled-components';
import breakpoints from '../../../../helpers/breakpoints';
import colors from '../../../../styles/helpers/colors';

const NavResponsive = styled.div`
  @media only screen and (min-width: ${breakpoints.minLaptop}px) {
    display: none;
  }
  @media only screen and (max-width: ${breakpoints.maxNotebook}px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .logo {
      img {
        transform: scale(0.8);
      }
    }

    #arrow-back {
      display: flex;
      visibility: hidden;
    }

    #nav-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      margin: 13px;
      border: none;
      cursor: pointer;
      width: 20px;
      height: 20px;

      span,
      span:before,
      span:after {
        cursor: pointer;
        border-radius: 1px;
        height: 3px;
        width: 20px;
        position: absolute;
        background: ${colors.white};
        display: block;
        content: '';
        transition: all 300ms ease-in-out;
      }

      span:before {
        top: -7px;
      }

      span:after {
        bottom: -7px;
      }

      &.active span {
        background-color: transparent;

        &:before,
        &:after {
          top: 0;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
`;

export default NavResponsive;
