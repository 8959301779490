/* import { combineReducers } from 'redux';
import { applyMiddleware, compose, createStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import authReducer from '../features/auth/authSlice';
import consultantsSlice from '../features/consultant/consultantsSlice';
import eventsSlice from '../features/event/eventsSlice';

const reducers = combineReducers({
  auth: authReducer,
  consultants: consultantsSlice,
  event: eventsSlice,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const middlewares = [thunkMiddleware];
const middlewareEnhancer = applyMiddleware(...middlewares);

const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

const store =
  process.env.REACT_APP_ENV === 'prod'
    ? createStore(
        persistedReducer,
        undefined,
        compose(applyMiddleware(thunkMiddleware)),
      )
    : createStore(persistedReducer, undefined, composedEnhancers);
const persistor = persistStore(store);

export { store, persistor }; */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import authReducer from '../features/auth/authSlice';
import consultantsSlice from '../features/consultant/consultantsSlice';
import eventsSlice from '../features/event/eventsSlice';

// Combine your reducers
const rootReducer = combineReducers({
  auth: authReducer,
  consultants: consultantsSlice,
  events: eventsSlice,
});

// Configuration for redux-persist
const persistConfig = {
  key: 'root',
  storage,
};

// Create a persisted reducer using redux-persist
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store and persistor
const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunkMiddleware],
  devTools: process.env.REACT_APP_ENV !== 'prod',
});

const persistor = persistStore(store);

export { store, persistor };
