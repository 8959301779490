/* eslint-disable */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../helpers/axios';
import {
  authenticate,
  forgottenPassword,
  resetPassword,
} from '../../service/authApi';

export const loginRequest = createAsyncThunk(
  'auth/login',
  async ({ username, password }, thunkAPI) => {
    try {
      const response = await authenticate({
        username,
        password,
      });
      const responseData = await response;
      if (response.status === 200) {
        localStorage.setItem('token', responseData.data.token);
        localStorage.setItem('email', username);

        return (axios.defaults.headers.common.Authorization = `Bearer ${responseData.data.token}`);
      }
      throw thunkAPI.rejectWithValue(responseData);
    } catch (e) {
      throw thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const sendForgottenPassword = createAsyncThunk(
  'auth/forgotten-password',
  async ({ email }, thunkAPI) => {
    try {
      const response = await forgottenPassword({ email });
      const responseData = await response;
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(responseData.data);
      }
      throw thunkAPI.rejectWithValue(responseData);
    } catch (e) {
      throw thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const sendResetPassword = createAsyncThunk(
  'users/reset-password',
  async ({ token, password, password2 }, thunkAPI) => {
    try {
      const response = await resetPassword({
        token,
        password,
        password2,
      });
      const responseData = await response;
      if (response.status === 200) {
        return thunkAPI.fulfillWithValue(responseData.data);
      }
      throw thunkAPI.rejectWithValue(responseData);
    } catch (e) {
      throw thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const initialState = {
  username: '',
  roles: [],
  loading: false,
  success: false,
  error: false,
  successMessage: '',
  errorMessage: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: (state) => ({
      ...state,
      username: '',
      error: false,
      success: false,
      loading: false,
      successMessage: '',
      errorMessage: '',
    }),
    wipeState: () => initialState,
  },
  extraReducers: {
    [loginRequest.fulfilled]: (state, { username, roles }) => ({
      ...state,
      username,
      roles,
      loading: false,
      success: true,
    }),
    [loginRequest.rejected]: (state, { payload }) => ({
      ...state,
      username: '',
      roles: [],
      loading: false,
      error: true,
      errorMessage: payload?.message,
    }),
    [loginRequest.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [sendForgottenPassword.fulfilled]: (state, { message }) => ({
      ...state,
      loading: false,
      success: true,
      successMessage: message,
    }),
    [sendForgottenPassword.rejected]: (state, { message }) => ({
      ...state,
      loading: false,
      error: true,
      errorMessage: message,
    }),
    [sendForgottenPassword.pending]: (state) => ({
      ...state,
      loading: true,
    }),
    [sendResetPassword.fulfilled]: (state, { message }) => ({
      ...state,
      loading: false,
      success: true,
      successMessage: message,
    }),
    [sendResetPassword.rejected]: (state, { message }) => ({
      ...state,
      loading: false,
      error: true,
      errorMessage: message,
    }),
    [sendResetPassword.pending]: (state) => ({
      ...state,
      loading: true,
    }),
  },
});

export const { clearState, wipeState } = authSlice.actions;

export const userSelector = (state) => state.auth;

export default authSlice.reducer;
