import {
  Badge,
  Button,
  Container,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Chat, Visibility } from '@material-ui/icons';
import Grid2 from '@mui/material/Unstable_Grid2';
import { API_LBA } from '../constants/routes';
import ContainerImages from '../styles/pages/consultants/ContainerImages';

function CardsConsultants({ consultants, loading }) {
  const [open, setOpen] = useState(false);
  const [presentation, setPresentation] = useState(null);

  const useStyles = makeStyles((theme) => ({
    card: {
      marginLeft: '20px',
      marginBottom: '20px',
      maxWidth: '300px',
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0px',
      },
      '& .bareme-link': {
        color: 'black',
      },
    },
  }));

  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        {loading || consultants === undefined ? (
          <>
            <Skeleton
              animation="pulse"
              className="mr-2"
              variant="rectangular"
              width={300}
              height={568}
            />
            <Skeleton
              animation="pulse"
              className="mr-2"
              variant="rectangular"
              width={300}
              height={568}
            />
            <Skeleton
              animation="pulse"
              className="mr-2"
              variant="rectangular"
              width={300}
              height={568}
            />
            <Skeleton
              animation="pulse"
              className="mr-2"
              variant="rectangular"
              width={300}
              height={568}
            />
          </>
        ) : (
          Object.values(consultants).map((item) => (
            <Card key={Math.random()} className={classes.card}>
              {item.civilite && item.civilite.id === 1 ? (
                <ContainerImages>
                  <CardMedia
                    className="image image-hover"
                    component="img"
                    image={
                      item.photoUrl
                        ? API_LBA + item.photoUrl
                        : '/images/avatar-h.png'
                    }
                    title="consultant photo"
                  />
                  <div className="overlay">
                    <Link to={`/consultants/photo/${item.id}`}>
                      <Button size="small" variant="outlined" color="primary">
                        Changer la photo
                      </Button>
                    </Link>
                  </div>
                </ContainerImages>
              ) : (
                <ContainerImages>
                  <CardMedia
                    className="image"
                    height="240"
                    component="img"
                    image={
                      item.photoUrl
                        ? API_LBA + item.photoUrl
                        : '/images/avatar-f.png'
                    }
                    title="consultant photo"
                  />
                  <div className="overlay">
                    <Link to={`/consultants/photo/${item.id}`}>
                      <Button size="small" variant="outlined" color="primary">
                        Changer la photo
                      </Button>
                    </Link>
                  </div>
                </ContainerImages>
              )}

              <CardContent>
                <Typography align="center" variant="h6" component="h2">
                  {item.prenom} {item.nom}
                </Typography>
                <Typography component="p" color="textSecondary">
                  {item.ville && item.ville.ville !== null
                    ? item.ville.ville
                    : 'Ville non renseignée.'}
                </Typography>
                <Typography component="p">{item.email}</Typography>
                <Typography component="p">
                  {item.dateNaissance !== null
                    ? `Né le ${new Date(item.dateNaissance).toLocaleDateString(
                        'fr-FR',
                      )}`
                    : 'Date de naissance non renseignée.'}
                </Typography>
                <Typography component="p">
                  {item.rsac !== null
                    ? `Rsac : ${item.rsac}`
                    : 'Rsac non renseignée.'}
                </Typography>

                {item.actif ? (
                  <Typography component="p" color="primary">
                    Consultant actif.
                  </Typography>
                ) : (
                  <Typography component="p" className="error">
                    Consultant inactif.
                  </Typography>
                )}

                {item.actifPresentation ? (
                  <>
                    <Typography
                      variant="span"
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ color: 'green' }}>
                        Présentation active.{' '}
                      </Typography>
                      <Badge
                        badgeContent={item.countPresentation}
                        color="secondary"
                      >
                        <Tooltip
                          title="Nombre de token(s) Chagpt restant."
                          placement="top"
                        >
                          <Chat />
                        </Tooltip>
                      </Badge>

                      {item.presentation && (
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setPresentation(
                              item.presentation ?? 'Pas de présentation',
                            );
                          }}
                          sx={{ marginLeft: '2px' }}
                        >
                          <Tooltip
                            title="Cliquez pour voir le texte du consultant(e)."
                            placement="top"
                          >
                            <Visibility style={{ fill: '#00AFE6' }} />
                          </Tooltip>
                        </IconButton>
                      )}
                    </Typography>
                  </>
                ) : (
                  <Grid2>
                    <Typography
                      variant="span"
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography color="error">
                        {' '}
                        Présentation inactive.{' '}
                      </Typography>
                      <Badge
                        badgeContent={item.countPresentation}
                        color="secondary"
                      >
                        <Tooltip
                          title="Nombre de token(s) Chagpt restant."
                          placement="top"
                        >
                          <Chat />
                        </Tooltip>
                      </Badge>
                      {item.presentation && (
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setPresentation(item.presentation);
                          }}
                          sx={{ marginLeft: '2px' }}
                        >
                          <Tooltip
                            title="Cliquez pour voir le texte du consultant(e)."
                            placement="top"
                          >
                            <Visibility style={{ fill: '#00AFE6' }} />
                          </Tooltip>
                        </IconButton>
                      )}
                    </Typography>
                  </Grid2>
                )}
                {item.roles && item.roles.includes('ROLE_AMBASSADEUR') && (
                  <Typography component="p" color="primary">
                    Consultant ambassadeur.
                  </Typography>
                )}
              </CardContent>
              <Link to={`/consultants/${item.id}`}>
                <Button fullWidth variant="contained" color="primary">
                  Edition du consultant
                </Button>
              </Link>
            </Card>
          ))
        )}
        {consultants && consultants.length <= 0 && (
          <Container maxWidth="sm">
            <Typography component="p" color="textPrimary">
              Aucun consultant pour cette recherche.
            </Typography>
          </Container>
        )}
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogContent>{presentation}</DialogContent>
        </Dialog>
      </Grid>
    </>
  );
}

export default CardsConsultants;
