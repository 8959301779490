import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/styles';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { routes } from './constants/routes';
import renderRoutes from './utils/renderRoute';
import GlobalStyles from './styles/global/GlobalStyle';
import LoginPage from './pages/LoginPage';
import ForgottenPassword from './pages/ForgottenPassword';
import ResetPassword from './pages/ResetPassword';
import PdfError from './pages/PdfError';

let theme = createTheme();
theme = responsiveFontSizes(theme);

function NotFoundRedirect() {
  return <Redirect to="/" />;
}
function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <HashRouter>
        <Switch>
          {renderRoutes(routes)}
          <Route exact path="/login" component={LoginPage} />
          <Route
            exact
            path="/forgotten-password"
            component={ForgottenPassword}
          />
          <Route
            exact
            path="/reset-password/:token"
            component={ResetPassword}
          />
          <Route component={NotFoundRedirect} />
          <Route exact component={PdfError} />
        </Switch>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </HashRouter>
    </ThemeProvider>
  );
}

export default App;
