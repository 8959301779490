import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  LocalizationProvider,
  StaticDateTimePicker,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { fr } from 'date-fns/locale';
import FormText from '../sam/FormText';
import useErrorHandler from '../../hooks/useErrorHandler';
import SideBar from '../SideBar';
import { useStyles } from '../../styles/estimation/Classes';
import { fetchEventById, patchEvent } from '../../features/event/eventsSlice';
import TitlePage from '../TitlePage';

function EventEdit() {
  const [load, setLoad] = useState(false);
  // const [dateTime, setDateTime] = useState(null);
  const { event, loading } = useSelector((state) => state.events);
  const { errorHandler } = useErrorHandler();
  const { id } = useParams();
  const dispatch = useDispatch();
  const form = useForm({
    defaultValues: {
      titreEvenement: event.titreEvenement,
      adresse: event.adresse,
      adresseComplement: event.adresseComplement,
      date: new Date(event.date),
    },
  });
  const classes = useStyles();
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
  } = form;
  const onSubmit = async (data, e) => {
    try {
      setLoad(true);
      dispatch(patchEvent(data));
      setTimeout(() => {
        setLoad(false);
      }, 1000);
    } catch (error) {
      errorHandler(error);
    }
  };
  useEffect(() => {
    if (event) {
      reset(event);
    }
  }, [event]);
  useEffect(() => {
    dispatch(fetchEventById(parseInt(id, 10)), 500);
  }, [dispatch]);
  return (
    <>
      <SideBar>
        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <TitlePage title="Édition d'événement" />
          <Grid container item justifyContent="center" xs={11}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid className={classes.field} item xs={12} md={12}>
                <FormText
                  placeholder="Titre"
                  helperText="Sujet de l'événement"
                  label="Titre de l'événement"
                  name="titreEvenement"
                  control={control}
                  type="text"
                  fullWidth
                  errors={errors}
                />

                <FormText
                  placeholder="L'adresse de l'événement"
                  helperText="Ex: 2 rue du général de gaule, 75000 Paris 17"
                  label="Adresse"
                  name="adresse"
                  control={control}
                  type="text"
                  errors={errors}
                  fullWidth
                />
                <Controller
                  control={control}
                  name="adresseComplement"
                  render={({ field }) => (
                    <TextField
                      placeholder="1er étage... porte droite..."
                      helperText="Ex: Étage, Agence Citya, Salle de réunion, Coworking, Domicile..."
                      label="complément d'adresse"
                      {...field}
                      inputRef={field.ref}
                      errors={errors}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid className={classes.field} item xs={12} md={12}>
                <Controller
                  name="date"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, ...fieldProps } }) => (
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={fr}
                    >
                      <StaticDateTimePicker
                        toolbarFormat="dd MMMM "
                        label="Date de l'événement"
                        onChange={onChange} /*  */
                        renderInput={(params) => (
                          <TextField
                            type="datetime-local"
                            selected={params}
                            {...params}
                            name="date"
                            fullWidth
                            required
                            error={params.error}
                            value={params.value}
                          />
                        )}
                        onError={(reason, value) => {
                          setError(fieldProps.name, value, {
                            shouldFocus: true,
                          });
                        }}
                        disablePast
                        inputRef={fieldProps.ref}
                        {...fieldProps}
                      />
                    </LocalizationProvider>
                  )}
                />
              </Grid>
              {!load && (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  gap={1}
                  item
                >
                  <Button
                    type="submit"
                    className="mt-1 mb-4"
                    size="large"
                    variant="contained"
                  >
                    Enregistrer
                  </Button>
                  <Button
                    type="button"
                    color="primary"
                    className="mt-1 mb-4"
                    size="large"
                    variant="contained"
                    component={Link}
                    to="/mes-evenements"
                  >
                    Retour listes
                  </Button>
                </Grid>
              )}
            </form>
          </Grid>
        </Grid>
      </SideBar>
      <Backdrop className={classes.backdrop} open={load || loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default EventEdit;
