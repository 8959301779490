import { ErrorMessage } from '@hookform/error-message';
import {
  Button,
  Grid,
  TextField,
  Typography,
  Skeleton,
  Autocomplete,
} from '@mui/material';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HomeIcon from '@mui/icons-material/Home';
import TerrainIcon from '@mui/icons-material/Terrain';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';
import {
  checkboxCollectif,
  checkboxIndividuel,
  checkboxTerrain,
} from '../../constants/checkbox';

function EtapeUn({
  classes,
  control,
  required,
  errors,
  error,
  lastAdress,
  adress,
  setTypeBien,
  setError,
  register,
  loading,
  etape,
  setRequired,
  setType,
  setAdress,
}) {
  const [query, setQuery] = useState('');
  const [loadingAddress, setLoadingAddress] = useState(false);

  // Fecth sur l'api gouvernement pour les adresses de l'autocomplete
  // debounce
  const updateQuery = () => {
    setLoadingAddress(true);
    if (query !== undefined && query !== '' && query.length > 3) {
      // A search query api call.
      setError((prevState) => ({
        ...prevState,
        adresseClient: '',
      }));

      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${query}&type=housenumber&autocomplete=1`,
      )
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          setAdress(json.features);
          setLoadingAddress(false);
        });
    }
  };

  const delayedQuery = useCallback(debounce(updateQuery, 1000), [query]);

  useEffect(() => {
    delayedQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel;
  }, [delayedQuery]);
  const useStyles = makeStyles(() => ({
    icon: {
      fontSize: '80px',
    },
  }));

  const iconClasses = useStyles();
  return (
    <>
      <Grid className={classes.field} item xs={12} md={12}>
        <Typography className="title text">Coordonnées du client</Typography>
        {loading && <Skeleton variant="text" height={60} width="100%" />}
        {loading && <Skeleton variant="text" height={60} width="100%" />}
        {loading && <Skeleton variant="text" height={60} width="100%" />}
        {loading && <Skeleton variant="text" height={60} width="100%" />}
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          {loading && (
            <Skeleton variant="rectangular" width={125} height={125} />
          )}
          {loading && (
            <Skeleton variant="rectangular" width={125} height={125} />
          )}
          {loading && (
            <Skeleton variant="rectangular" width={125} height={125} />
          )}
          {loading && (
            <Skeleton variant="rectangular" width={125} height={125} />
          )}
        </Grid>

        {!loading && (
          <>
            <Controller
              render={({ field }) => (
                <TextField
                  placeholder="M. Mme Fouquet Frédéric"
                  label="Nom et prénom"
                  type="text"
                  inputRef={field.ref}
                  {...field}
                />
              )}
              name="client"
              control={control}
              defaultValue=""
              rules={{
                required:
                  required.required === true
                    ? false
                    : 'Veuillez compléter ce champ.',
                maxLength: {
                  value: 255,
                  message: 'Le nombre maximum de caractères a été dépassé',
                },
              }}
            />

            <ErrorMessage
              errors={errors}
              name="client"
              render={({ message }) => (
                <Typography className="error" color="secondary">
                  {message}
                </Typography>
              )}
            />
            <Controller
              render={() => (
                <Autocomplete
                  loading={loadingAddress}
                  freeSolo
                  id="adress"
                  onInputChange={(event, request) => {
                    setQuery(request);
                  }}
                  value={lastAdress}
                  disableClearable
                  options={adress}
                  getOptionLabel={(option) => {
                    return option.properties
                      ? option.properties.label
                      : lastAdress;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Adresse complète"
                      helperText="Pour les adresses en bis ou ter veuillez écrire ainsi : 47bis ou 47ter."
                      type="text"
                      placeholder="47 rue nationale 37000 Tours"
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                />
              )}
              name="adresseClient"
              control={control}
              defaultValue=""
              rules={{
                required: false,
                maxLength: {
                  value: 255,
                  message: 'Le nombre maximum de caractères a été dépassé',
                },
              }}
            />
            <Typography color="secondary">
              {error ? error.adresseClient : null}
            </Typography>
            <Controller
              render={({ field }) => (
                <TextField
                  label="Email"
                  type="email"
                  placeholder="frederic-fouquet@gmail.com"
                  inputRef={field.ref}
                  {...field}
                />
              )}
              name="emailClient"
              control={control}
              defaultValue=""
              rules={{
                required:
                  required.required === true
                    ? false
                    : 'Veuillez compléter ce champ.',
                pattern: /^\S+@\S+$/i,
              }}
            />
            <ErrorMessage
              errors={errors}
              name="emailClient"
              render={({ message }) => (
                <Typography className="error" color="secondary">
                  {message}
                </Typography>
              )}
            />
            <Controller
              render={({ field }) => (
                <TextField
                  label="Téléphone"
                  type="tel"
                  placeholder="0676461217"
                  inputRef={field.ref}
                  {...field}
                />
              )}
              name="telephoneClient"
              control={control}
              defaultValue=""
              rules={{
                required:
                  required.required === true
                    ? false
                    : 'Veuillez compléter ce champ.',
                minLength: {
                  value: 2,
                  message: 'Numéro incorrect',
                },
                maxLength: {
                  value: 10,
                  message: 'Numéro incorrect',
                },
              }}
            />
            <ErrorMessage
              errors={errors}
              name="telephoneClient"
              render={({ message }) => (
                <Typography className="error" color="secondary">
                  {message}
                </Typography>
              )}
            />
            <Typography className="title text mt-1 mb-1">
              Type du bien
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
            >
              <label className="iconType">
                <input
                  disabled={etape > 1}
                  type="radio"
                  name="typeBien"
                  value="maison"
                  onClick={() => {
                    setTypeBien(checkboxIndividuel);
                    setType('maison');
                    setError((prevState) => ({
                      ...prevState,
                      typeBien: ' ',
                    }));
                  }}
                  {...register('typeBien', {
                    required:
                      required.required === true
                        ? false
                        : etape > 1
                        ? false
                        : 'Veuillez complèter ce champ.',
                  })}
                />

                <HomeIcon className={iconClasses.icon} />
                <Typography align="center" className="text">
                  Maison
                </Typography>
              </label>

              <label className="iconType">
                <input
                  disabled={etape > 1}
                  type="radio"
                  onClick={() => {
                    setTypeBien(checkboxCollectif);
                    setType('appartement');
                    setError((prevState) => ({
                      ...prevState,
                      typeBien: ' ',
                    }));
                  }}
                  name="typeBien"
                  value="appartement"
                  {...register('typeBien', {
                    required:
                      required.required === true
                        ? false
                        : etape > 1
                        ? false
                        : 'Veuillez complèter ce champ.',
                  })}
                />
                <ApartmentIcon className={iconClasses.icon} />
                <Typography align="center" className="text">
                  Appartement
                </Typography>
              </label>

              <label className="iconType">
                <input
                  disabled={etape > 1}
                  type="radio"
                  onClick={() => {
                    setTypeBien(checkboxTerrain);
                    setType('terrain');
                    setError((prevState) => ({
                      ...prevState,
                      typeBien: ' ',
                    }));
                  }}
                  name="typeBien"
                  value="terrain"
                  {...register('typeBien', {
                    required:
                      required.required === true
                        ? false
                        : etape > 1
                        ? false
                        : 'Veuillez complèter ce champ.',
                  })}
                />
                <TerrainIcon className={iconClasses.icon} />
                <Typography align="center" className="text">
                  Terrain
                </Typography>
              </label>

              {/* <label className="iconType">
                <input
                  type="radio"
                  onClick={(event) => {
                    setTypeBien(checkboxCollectif);
                    setType("local commercial");
                    setError((prevState) => ({
                      ...prevState,
                      typeBien: " ",
                    }));
                  }}
                  name="typeBien"
                  value="local commercial"
                  {...register("typeBien", {
                    required:
                      required.required === true
                        ? false
                        : "Veuillez compléter ce champ.",
                  })}
                />
                <StoreIcon className={iconClasses.icon} />
                <Typography align="center" className="text">
                  Local commercial
                </Typography>
              </label> */}
            </Grid>
            <ErrorMessage
              errors={errors}
              name="typeBien"
              render={({ message }) => (
                <Typography className="error" color="secondary">
                  {message}
                </Typography>
              )}
            />
            <Typography color="secondary">
              {error ? error.typeBien : null}
            </Typography>
          </>
        )}
      </Grid>

      {etape < 1 ? (
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Button
            color="primary"
            className="mt-1 mb-2"
            size="large"
            type="submit"
            variant="contained"
            onClick={() => {
              setRequired((prevState) => ({
                ...prevState,
                required: null,
              }));
            }}
          >
            Suivant
          </Button>
        </Grid>
      ) : null}
    </>
  );
}

export default EtapeUn;
