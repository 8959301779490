import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreateIcon from '@mui/icons-material/Create';
import DescriptionIcon from '@mui/icons-material/Description';
import ForumIcon from '@mui/icons-material/Forum';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import SchoolIcon from '@mui/icons-material/School';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import BusinessIcon from '@mui/icons-material/Business';
import Leaderboard from '@mui/icons-material/Leaderboard';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import MovieIcon from '@mui/icons-material/Movie';

export const menu = {
  'Agenda reseau': {
    icon: <CalendarMonth fontSize="small" className="icon mr-1" />,
    text: 'Agenda réseau',
    href: 'https://calendar.google.com/calendar/u/0/embed?src=ac46bb55a4771dc98a57118326ce4577ab5c1a17d63175b60ffc13cb5b33b6c4@group.calendar.google.com&ctz=Europe/Paris',
    target: '_blank',
  },
  'Mes donnees immobilieres': {
    icon: <Leaderboard fontSize="small" className="icon mr-1" />,
    text: 'Mes données immobilières',
    href: 'https://data.opendatasoft.com/explore/dataset/demande-de-valeurs-foncieres%40public/map/?sort=date_mutation&location=8,47.65799,3.23273&basemap=jawg.streets',
    target: '_blank',
  },
  TransacNet: {
    icon: <AccountBalanceIcon fontSize="small" className="icon mr-1" />,
    text: 'TransacNet',
    href: 'https://www.cityalebonagent.immo/Login?typeApplication=Transaction',
    target: '_blank',
  },
  'Espace Pro': {
    icon: <ForumIcon fontSize="small" className="icon mr-1" />,
    text: 'Espace Pro',
    href: 'https://forum.lebonagent.fr/',
    target: '_blank',
  },
  Formation: {
    icon: <SchoolIcon fontSize="small" className="icon mr-1" />,
    text: 'Formation',
    href: 'https://www.plateformef.com/mon-compte',
    target: '_blank',
  },
  Yanport: {
    icon: <HomeWorkIcon fontSize="small" className="icon mr-1" />,
    text: 'Yanport',
    href: 'https://app.yanport.com/login',
    target: '_blank',
  },
  Basile: {
    icon: <RecordVoiceOverIcon fontSize="small" className="icon mr-1" />,
    text: 'Basile',
    href: 'https://lebonagent.cooptation.io/login',
    target: '_blank',
  },
  Protexa: {
    icon: <CreateIcon fontSize="small" className="icon mr-1" />,
    text: 'Protexa',
    href: 'https://production.protexa.fr/ProtexaFullWeb',
    target: '_blank',
  },
  Presse: {
    icon: <DescriptionIcon fontSize="small" className="icon mr-1" />,
    text: 'Presse',
    href: 'https://www.aucoeurdelimmo.com/',
    target: '_blank',
  },
  'Rhinov pro': {
    icon: <ApartmentIcon fontSize="small" className="icon mr-1" />,
    text: 'Rhinov pro',
    href: 'https://commande.rhinov.pro/#/login',
    target: '_blank',
  },
  'Au coeur ': {
    icon: <ViewComfyIcon fontSize="small" className="icon mr-1" />,
    text: 'Au Cœur de l’annonce',
    href: 'https://www.aucoeurdelimmo.com/annonces?transac=vente',
    target: '_blank',
  },
  Nodalview: {
    icon: <BusinessIcon fontSize="small" className="icon mr-1" />,
    text: 'Nodalview',
    href: 'https://nodalview.com/fr/users/sign_in',
    target: '_blank',
  },
  ImmoData: {
    icon: <LocationOnIcon fontSize="small" className="icon mr-1" />,
    text: 'ImmoData',
    href: 'https://www.immo-data.fr/',
    target: '_blank',
  },
  Danim: {
    icon: <MovieIcon fontSize="small" className="icon mr-1" />,
    text: 'DANIM',
    href: 'https://connect.danim.com/',
    target: '_blank',
  },
};
export const synergies = {
  Citya: {
    libelle: 'Citya',
    link: ['/#/contact/citya', 'https://www.citya.com/'],
    blank: ['', '_blank'],
    menu: ['Déclarer un contact', 'Citya'],
  },
  'Api Financement': {
    libelle: 'Api Financement',
    link: ['/#/contact/api', 'https://api-financement.net/'],
    blank: ['', '_blank'],
    menu: ['Déclarer un contact', 'Calculatrice'],
  },
  'Sphere Immo': {
    libelle: 'Sphère Immo',
    link: [
      '/#/contact/sphere-immo',
      'https://www.sphere-immo.com/authentification&v=0',
    ],
    blank: ['', '_blank'],
    menu: ['Déclarer un aquéreur', 'Vente de neuf'],
  },
  'St Pierre Assurances': {
    libelle: 'St Pierre Assurances',
    link: [
      '/#/contact/saint-pierre-assurances',
      'https://www.stpierreassurances.com/',
    ],
    blank: ['', '_blank'],
    menu: ['Déclarer un contact', 'St Pierre Assurances'],
  },
  Snexi: {
    libelle: 'Snexi',
    link: ['/#/contact/snexi', 'https://www.snexi.fr/'],
    blank: ['', '_blank'],
    menu: ['Déclarer un contact', 'St Pierre Snexi'],
  },
  'As & Associes': {
    libelle: 'As & Associées',
    link: ['/#/contact/as-et-associes', 'https://www.as-associes.com/'],
    blank: ['', '_blank'],
    menu: ['Déclarer un contact', 'St Pierre Assurances'],
  },
  'Cousin HUB': {
    libelle: 'Cousin HUB',
    link: ['/#/contact/cousin-hub', 'https://cousin-hub.com/'],
    blank: ['', '_blank'],
    menu: ['Déclarer un contact', 'St Pierre Assurances'],
  },
};

export const menuItems = {
  Citya: [
    {
      title: 'Déclarer un contact',
      path: '/#/contact/citya',
      target: '',
    },
  ],
  'Api Financement': [
    {
      title: 'Déclarer un contact',
      path: '/#/contact/api',
      target: '',
    },
  ],
  'Sphère Immo': [
    {
      title: 'Déclarer un aquéreur',
      path: '/#/contact/sphere-immo',
      target: '',
    },
  ],
  'St Pierre Assurances': [
    {
      title: 'Déclarer un contact',
      path: '/#/contact/saint-pierre-assurances',
      target: '',
    },
  ],
  'Cousin HUB': [
    {
      title: 'Déclarer un contact',
      path: '/#/contact/cousin-hub',
      target: '',
    },
  ],
  'As & Associes': [
    {
      title: 'Déclarer un contact',
      path: '/#/contact/as-et-associes',
      target: '',
    },
  ],
};
