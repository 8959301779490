const data = {
  RESEAUX: ['LinkedIn', 'Facebook', 'Instagram', 'Twitter'],
  QUALITES_HUMAINE: [
    'compétence',
    'détermination',
    'disponibilité',
    'dynamisme',
    'expérience',
    'professionnalisme',
    'réactivité',
    'rigueur',
    "sens de l'écoute",
    'talents relationnels',
    'empathie',
  ],
  IMPLICATION_LOCAL: [
    'amicale',
    'association',
    'cercle',
    'club',
    'comité',
    'confrérie',
    'conseil',
    'section',
    'société',
  ],
  COMPETENCES_TRANSVERSALES: [
    'Prise de décision',
    'Gestion du stress',
    'Orientation client',
    'Résolution de problèmes',
    'Communication',
    'Collaboration',
    'Adaptabilité',
    'Créativité',
    'Gestion du temps',
    'Pensée stratégique',
    'Intelligence émotionnelle',
  ],

  EXPERIENCES_PRECEDENTES: [
    'marketing',
    'commerce',
    'retail',
    'agent immobilier',
    'bâtiment',
    'secteur bancaire',
  ],
  LANGUES: [
    'anglais',
    'espagnol',
    'allemand',
    'néerlandais',
    'italien',
    'portugais',
    'mandarin',
    'arabe',
  ],
  PARTENAIRES: [
    'notaires',
    'courtiers',
    'diagnostiqueurs',
    'entreprises du bâtiment',
    'architectes',
    'géomètres-experts',
    'paysagistes',
    'artisans',
    "décorateurs d'intérieur",
    'spécialistes de la rénovation',
  ],
  TYPE_DE_BIENS: [
    'maison',
    'appartement',
    'immeuble',
    'parking',
    'terrain',
    'bail commercial',
    'manoir',
    'longère',
    'chalet',
    'corps de ferme',
    'bien atypique',
    'bien de prestige',
    'bien de caractère',
  ],
  SECTEURS_D_ACTIVITES: ['vente', 'achat', 'investissement', 'location'],
  COMPETENCES_PRO: [
    'Relationnel',
    'Vente',
    'Négociation',
    'Connaissance du marché',
    'Organisation',
    'Communication',
    'Persévérance',
    'Adaptabilité',
    'Expertise juridique',
    'Gestion du temps',
  ],
};
export default data;
