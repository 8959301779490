import { Grid, IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { AccountCircle } from '@material-ui/icons';
import React, { useCallback, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConsultantByEmail } from '../../features/consultant/consultantsSlice';
import SideBarItem from '../SideBarItem';
import { menu } from '../../constants/menu';

function MySpace() {
  const token = localStorage.getItem('token') || false;
  const user = token ? jwtDecode(token) : { username: '', roles: [] };
  const { username, roles = [] } = user;
  const { consultant, loading } = useSelector((state) => state.consultants);
  const dispatch = useDispatch();
  const fetchConsultant = useCallback(() => {
    dispatch(fetchConsultantByEmail(username));
  }, [dispatch, username]);

  useEffect(() => {
    fetchConsultant();
  }, [fetchConsultant]);

  // usage:

  return (
    <Grid
      container
      item
      spacing={1}
      alignItems="flex-end"
      justifyContent="center"
      gap="10%"
    >
      <Grid item xs={4}>
        {consultant && (
          <div className="icon-container">
            <Typography variant="caption">Profil</Typography>
            <IconButton
              component={Link}
              to={{
                pathname: `profil/${consultant.id}`,
              }}
            >
              <AccountCircle fontSize="large" className="icon" />
            </IconButton>
          </div>
        )}
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">Agenda</Typography>
          <SideBarItem
            key={menu['Agenda reseau'].id}
            icon={menu['Agenda reseau'].icon}
            target={menu['Agenda reseau'].target}
            href={menu['Agenda reseau'].href}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        {' '}
        <div className="icon-container">
          <Typography variant="caption">Formations</Typography>
          <SideBarItem
            key={Math.random()}
            icon={menu.Formation.icon}
            target={menu.Formation.target}
            href={menu.Formation.href}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption" className="iconButtonLabel">
            Espace-pro
          </Typography>
          <SideBarItem
            key={Math.random()}
            icon={menu['Espace Pro'].icon}
            target={menu['Espace Pro'].target}
            href={menu['Espace Pro'].href}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default MySpace;
