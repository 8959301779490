import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addParticipant } from '../../service/participantEventApi';
import {
  findAllRegions,
  findDepartementByRegion,
} from '../../service/consultantsApi';

function AddParticipant({ event }) {
  const [regions, setRegions] = useState([]);
  const [departements, setDepartements] = useState([]);
  const [regionValue, setRegionValue] = useState('');
  const [departementValue, setDepartementValue] = useState('');
  const [load, setLoad] = useState(true);
  const { control, handleSubmit, reset, formState } = useForm();
  const history = useHistory();
  const onAddParticipant = (e) => {
    const data = {
      nom: e.nom,
      prenom: e.prenom,
      telephone: e.telephone,
      email: e.email,
      evenement: [`api/evenements/${event.id}`],
      departement: `api/departements/${departementValue}`,
      rgpd: true,
      isActif: true,
    };
    addParticipant(data).then(() => {
      history.go(0);
      toast.success('Participant(e) ajouté');
    });
  };
  React.useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset();
    }
  }, [formState, reset]);
  const fetchRegions = async () => {
    setLoad(true);
    try {
      return await findAllRegions();
    } catch (e) {
      throw new Error(e);
    } finally {
      setLoad(false);
    }
  };
  const fetchDepartements = async () => {
    setLoad(true);
    try {
      return await findDepartementByRegion(regionValue);
    } catch (e) {
      throw new Error(e);
    } finally {
      setLoad(false);
    }
  };
  useEffect(() => {
    fetchRegions().then((res) => {
      setRegions(res);
    });
  }, []);

  useEffect(() => {
    if (regionValue !== '') {
      fetchDepartements().then((r) => setDepartements(r));
    }
  }, [regionValue]);

  return (
    <>
      <Typography variant="h6">Ajouter un participant(es)</Typography>
      <form onSubmit={handleSubmit(onAddParticipant)}>
        <InputLabel id="demo-multiple-name-label">Nom</InputLabel>
        <Controller
          name="nom"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              size="small"
              required
            />
          )}
        />
        <InputLabel id="demo-multiple-name-label">Prénom</InputLabel>
        <Controller
          name="prenom"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              size="small"
              required
            />
          )}
        />
        <InputLabel id="demo-multiple-name-label">Téléphone</InputLabel>
        <Controller
          name="telephone"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              fullWidth
              size="small"
              required
            />
          )}
        />
        <InputLabel id="demo-multiple-name-label">Email</InputLabel>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              variant="outlined"
              size="small"
              required
            />
          )}
        />
        {regions && (
          <>
            <InputLabel id="demo-multiple-name-label">Régions</InputLabel>
            <TextField
              select
              fullWidth
              variant="outlined"
              size="small"
              required
              value={regionValue || ''}
              onChange={(e) => setRegionValue(e.target.value)}
            >
              {regions.map((option) => (
                <MenuItem key={option.libelle} value={option.id}>
                  {option.libelle}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
        {departements.length > 0 && (
          <>
            <InputLabel id="demo-multiple-name-label">Départements</InputLabel>
            <Controller
              name="departements"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  {...field}
                  fullWidth
                  variant="outlined"
                  size="small"
                  required
                  value={departementValue || ''}
                  onChange={(e) => setDepartementValue(e.target.value)}
                >
                  {departements.map((option) => (
                    <MenuItem key={option.libelle} value={option.id}>
                      {option.libelle}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </>
        )}
        <Grid container display="flex" justifyContent="center">
          <Button
            type="submit"
            className="mt-1 mb-4"
            size="large"
            variant="contained"
          >
            Enregistrer
          </Button>
        </Grid>
      </form>
      {/* </Grid> */}
    </>
  );
}

export default AddParticipant;
