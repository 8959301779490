import axios from '../helpers/axios';
import { API_LBA, EVENEMENT_API } from '../constants/routes';
import useErrorHandler from '../hooks/useErrorHandler';

const { errorHandler } = useErrorHandler();

export const findEventAdmin = (page, itemsPerPage, order, search = '') => {
  return axios
    .get(
      `${EVENEMENT_API}?page=${page}&itemsPerPage=${itemsPerPage}&order[date]=${order}&consultant.nom=${search}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};

export const findEventById = (id) => {
  return axios
    .get(`${EVENEMENT_API}/${id}`)
    .then((response) => response)
    .catch((error) => {
      errorHandler(error);
    });
};

export const findEvent = (page, itemsPerPage, order, email) => {
  return axios
    .get(
      `${EVENEMENT_API}?page=${page}&itemsPerPage=${itemsPerPage}&order[date]=${order}&consultant.email=${email}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};

export const postEvent = ({
  date,
  adresse,
  adresseComplement,
  titreEvenement,
}) => {
  const newData = {
    date: `${new Date(date).toLocaleDateString('en-US')} ${new Date(
      date,
    ).toLocaleTimeString()}`,
    adresse,
    adresseComplement,
    titreEvenement,
  };
  return axios
    .post(`${EVENEMENT_API}`, newData)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
    });
};
export const putEvent = ({
  id,
  date,
  adresse,
  adresseComplement,
  titreEvenement,
}) => {
  const patch = {
    id,
    date: `${new Date(date).toLocaleDateString('en-US')} ${new Date(
      date,
    ).toLocaleTimeString()}`,
    adresse,
    adresseComplement,
    titreEvenement,
  };
  return axios
    .put(`${EVENEMENT_API}/${id}`, patch)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
    });
};
export const deleteEvent = (id) => {
  return axios
    .delete(`${EVENEMENT_API}/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
    });
};

export const downloadPng = (id) => {
  return axios
    .get(`${API_LBA}/media/download/evenement/${id}`, {
      responseType: 'blob',
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
    });
};
