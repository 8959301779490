import axios from '../helpers/axios';
import { API_URL, SCORING_MANDATS } from '../constants/routes';
import useErrorHandler from '../hooks/useErrorHandler';

const { errorHandler } = useErrorHandler();

export const findScoringAdmin = (page, itemsPerPage, order, search = '') => {
  return axios
    .get(
      `${SCORING_MANDATS}?page=${page}&itemsPerPage=${itemsPerPage}&order[dateCreation]=${order}&consultant.nom=${search}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};

export const findScoring = (page, itemsPerPage, order, username) => {
  return axios
    .get(
      `${SCORING_MANDATS}?page=${page}&itemsPerPage=${itemsPerPage}&order[dateCreation]=${order}&consultant.email=${username}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};

export const postScoring = (data) => {
  return axios
    .post(`${API_URL}/scoring_mandats`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      errorHandler(error);
    });
};
