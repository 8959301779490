import { ErrorMessage } from '@hookform/error-message';
import { Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

function Form({ title, name, control, errors, array }) {
  return (
    <Grid>
      <Controller
        render={({ field }) => (
          <>
            <InputLabel id={name}>{title}</InputLabel>
            <Select
              variant="standard"
              labelId={name}
              id={name}
              inputRef={field.ref}
              {...field}
            >
              {array.map((arr) => (
                <MenuItem key={Math.random()} value={arr.value}>
                  {arr.label}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
        name={name}
        control={control}
        defaultValue=""
        rules={{
          required: 'Veuillez compléter ce champ.',
          maxLength: {
            value: 255,
            message: 'Le nombre maximum de caractères a été dépassé',
          },
        }}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Typography className="error" color="secondary">
            {message}
          </Typography>
        )}
      />
    </Grid>
  );
}

export default Form;
