import styled from 'styled-components';

const LoginForm = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url('images/fond.jpg');
  height: 100vh;
  width: 100vw;
  background-size: cover;

  .containerArche {
    position: fixed;
    bottom: 0;
  }
  .logoArche {
    height: 25px;
  }
  .whiteBloc {
    padding: 10px;
    background-color: rgb(255 255 255 / 85%);
    width: 28rem;
    height: auto;
    text-align: center;
    border-radius: 25px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
    form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    input {
      height: 38px;
      width: 80%;
      margin: 0 auto;
      border-radius: 15px;
      border: none;
    }
    #email {
      background-color: white;
    }
  }
  .textGray {
    color: #3f525c;
    font-weight: 900;
  }

  .title {
    font-size: 1.5rem;
  }

  .textWhite {
    color: white;
  }
  .MuiButton-contained.btnLogin {
    margin: 0 auto 10px auto !important;
    background-color: #00afe6 !important;
    border-radius: 15px !important;
    color: #fff !important;
    font-weight: 900;
    height: 40px !important;
    width: 80% !important;
  }
  .MuiButton-contained.btnLogin:hover {
    color: #fff !important;
    background-color: #3f525c !important;
  }
  .MuiInput-root {
    margin: 0 auto;
    width: 80%;
    background: white !important;
    opacity: 1;
  }

  @media screen and (max-width: 640px) {
    .whiteBloc {
      width: 80vw;
    }
    .title {
      font-size: 1.5em;
    }
  }
  @media screen and (max-width: 640px) {
    .containerArche {
      display: none;
    }
  }
`;

export default LoginForm;
