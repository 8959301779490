import {
  CONTACTS_API,
  CONTACTS_API_CONSULTANT,
  CONTACTS_API_ESTIMATION,
  CONTACTS_API_PROSPECT,
  CONTACTS_API_RECRUTEMENT,
  CONTACTS_API_VENTE,
} from '../constants/routes';
import axios from '../helpers/axios';
import useErrorHandler from '../hooks/useErrorHandler';

const { errorHandler } = useErrorHandler();

export const findContact = (page, itemsPerPage, order) => {
  return axios
    .get(
      `${CONTACTS_API}?page=${page}&itemsPerPage=${itemsPerPage}&order${order}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
export const findContactRecrutement = (page, itemsPerPage, order) => {
  return axios
    .get(
      `${CONTACTS_API_RECRUTEMENT}?page=${page}&itemsPerPage=${itemsPerPage}&order${order}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
export const findContactEstimation = (page, itemsPerPage, order) => {
  return axios
    .get(
      `${CONTACTS_API_ESTIMATION}?page=${page}&itemsPerPage=${itemsPerPage}&order${order}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
export const findContactProspect = (page, itemsPerPage, order) => {
  return axios
    .get(
      `${CONTACTS_API_PROSPECT}?page=${page}&itemsPerPage=${itemsPerPage}&order${order}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
export const findContactVente = (page, itemsPerPage, order) => {
  return axios
    .get(
      `${CONTACTS_API_VENTE}?page=${page}&itemsPerPage=${itemsPerPage}&order${order}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
export const findContactConsultant = (page, itemsPerPage, order) => {
  return axios
    .get(
      `${CONTACTS_API_CONSULTANT}?page=${page}&itemsPerPage=${itemsPerPage}&order${order}`,
    )
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
