import { toast } from 'react-toastify';
import {
  FORGOTTEN_PASSWORD,
  LOGIN_API,
  RESET_PASSWORD,
} from '../constants/routes';
import axios from '../helpers/axios';
import history from '../helpers/history';

/**
 * Requête HTTP d'authentification et stockage du token dans le storage et sur Axios
 * @param {object} credentials
 */
export const authenticate = (credentials) => {
  return axios.post(LOGIN_API, credentials).catch((e) => {
    let message = 'Une erreur est survenue';
    if (e.status !== 200) {
      const codeErreur = e.response.status;
      if (codeErreur === 401) {
        message = 'Mot de passe incorrect';
        localStorage.clear();
        history.push('/login');
      }
    }
    toast.error(message);
  });
};

export const forgottenPassword = (email) => {
  return axios
    .post(FORGOTTEN_PASSWORD, email)
    .then((response) => toast.success(response.data))
    .catch((e) => {
      toast.success(e.response.data);
    });
};

export const resetPassword = (credentials) => {
  return axios
    .post(`${RESET_PASSWORD}/${credentials.token}`, credentials)
    .then((response) => {
      toast.success(response.data);
      history.push('/login');
    })
    .catch((e) => {
      toast.error(e.response.data);
    });
};
