import { ErrorMessage } from '@hookform/error-message';
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import SideBar from '../../components/SideBar';
import PreferenceContact from '../../components/synergie/PreferenceContact';
import { SYNERGIES_API } from '../../constants/routes';
import axios from '../../helpers/axios';
import { useStyles } from '../../styles/estimation/Classes';
import Contact from '../../components/synergie/Contact';
import AutocompleteAddress from '../../components/AutocompleteAddress';
import { AS_ASSOCIE } from '../../constants/synergyCategories';

function SyngergieAsAssocie() {
  const form = useForm();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = form;

  const [adress, setAdress] = useState([]);
  const [query, setQuery] = useState();
  const [load, setLoad] = useState(false);
  const [lastAdress, setLastAdress] = useState();
  const [checkedValuesJour, setCheckedValuesJour] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [error, setError] = useState({
    localisation: '',
    jour: '',
    contact: '',
  });

  const onSubmit = (data, e) => {
    const finalData = {
      ...data,
      preferenceContactJour: checkedValuesJour,
      preferenceContact: checkedValues,
      date: new Date(),
      localisation: lastAdress,
      impot: parseInt(data.impot, 10),
      synergieCategorie: AS_ASSOCIE,
    };

    if (finalData.localisation === '' || finalData.localisation === undefined) {
      setError((prevState) => ({
        ...prevState,
        localisation: 'Veuillez compléter ce champ.',
      }));
    } else if (checkedValuesJour.length === 0) {
      setError((prevState) => ({
        ...prevState,
        jour: 'Veuillez compléter ce champ.',
      }));
    } else if (checkedValues.length === 0) {
      setError((prevState) => ({
        ...prevState,
        contact: 'Veuillez compléter ce champ.',
      }));
    } else {
      setLoad(true);
      axios
        .post(SYNERGIES_API, finalData)
        .then(() => {
          setLoad(false);
          toast.success('Formulaire envoyé');
        })
        .catch(() => {
          setLoad(false);
          toast.error("Echec dans l'envoi du formulaire");
        });
    }
    reset();
  };

  const week = [' Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
  const contacts = [
    'Début de matinée',
    'Fin de matinée',
    "Début d'après-midi",
    "Fin d'après-midi",
  ];

  // Fecth sur l'api gouvernement pour les adresses de l'autocomplete
  // debounce

  const updateQueryAdress = () => {
    if (query !== undefined && query !== '') {
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${query}`)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          setAdress(json.features);
        });
    }
  };
  const delayedQueryAdress = useCallback(debounce(updateQueryAdress, 1000), [
    query,
  ]);

  useEffect(() => {
    delayedQueryAdress();
    // Cancel the debounce on useEffect cleanup.
    return delayedQueryAdress.cancel;
  }, [delayedQueryAdress]);

  const handleCheckedJour = (e) => {
    const jour = week[e.target.dataset.id];
    const newCheckedValuesJour = checkedValuesJour.filter(
      (item) => item !== jour,
    );
    if (e.target.checked) newCheckedValuesJour.push(jour);
    setError((prevState) => ({
      ...prevState,
      jour: '',
    }));
    setCheckedValuesJour(newCheckedValuesJour);
  };

  const handleChecked = (e) => {
    const contact = contacts[e.target.dataset.id];
    const newCheckedValues = checkedValues.filter((item) => item !== contact);
    if (e.target.checked) newCheckedValues.push(contact);
    setError((prevState) => ({
      ...prevState,
      contact: '',
    }));
    setCheckedValues(newCheckedValues);
  };

  const classes = useStyles();
  const style = makeStyles(() => ({
    icon: {
      height: '50px',
    },
  }));
  const iconClasses = style();

  return (
    <SideBar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} xl={12}>
          <Typography className="ariane mt-3" compoment="p" align="center">
            Déclarer un contact As & Associés
          </Typography>
          <Typography className="ariane m-1" compoment="p" align="center">
            <img className={iconClasses.icon} src="/images/as.png" alt="" />
          </Typography>
        </Grid>
        <Grid className={classes.container} item xs={11} md={11} lg={8}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Contact
              name="nom"
              firstname="prenom"
              telephone="telephone"
              email="email"
              title="Contact"
              control={control}
              errors={errors}
              error={error}
              setError={setError}
              required
            />
            <AutocompleteAddress
              setLastAdress={setLastAdress}
              control={control}
              errors={errors}
              error={error}
              setError={setError}
              title="Localisation"
              label="Adresse ou secteur du projet ( ville )"
            />
            <Grid className={classes.field} item xs={12} md={12}>
              <Typography className="title text toto">Situation</Typography>
              <Typography color="secondary">{error.localisation}</Typography>
              <Controller
                render={({ field }) => (
                  <TextField
                    placeholder="13000"
                    label="Revenus imposables année n-1"
                    type="number"
                    inputRef={field.ref}
                    {...field}
                  />
                )}
                name="impot"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Veuillez compléter ce champ.',
                  maxLength: {
                    value: 255,
                    message: 'Le nombre maximum de caractères a été dépassé',
                  },
                }}
              />
              <ErrorMessage
                errors={errors}
                name="impot"
                render={({ message }) => (
                  <Typography className="error" color="secondary">
                    {message}
                  </Typography>
                )}
              />
              <Controller
                render={({ field }) => (
                  <TextField
                    placeholder="Agent technique"
                    label="Professions des clients"
                    type="text"
                    inputRef={field.ref}
                    {...field}
                  />
                )}
                name="professionClient"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Veuillez compléter ce champ.',
                  maxLength: {
                    value: 255,
                    message: 'Le nombre maximum de caractères a été dépassé',
                  },
                }}
              />
              <ErrorMessage
                errors={errors}
                name="professionClient"
                render={({ message }) => (
                  <Typography className="error" color="secondary">
                    {message}
                  </Typography>
                )}
              />
              <Controller
                render={({ field }) => (
                  <TextField
                    placeholder="1 lot d'immeuble"
                    label="Patrimoine immobilier actuel"
                    type="text"
                    inputRef={field.ref}
                    {...field}
                  />
                )}
                name="patrimoine"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Veuillez compléter ce champ.',
                  maxLength: {
                    value: 255,
                    message: 'Le nombre maximum de caractères a été dépassé',
                  },
                }}
              />
              <ErrorMessage
                errors={errors}
                name="patrimoine"
                render={({ message }) => (
                  <Typography className="error" color="secondary">
                    {message}
                  </Typography>
                )}
              />
            </Grid>

            <PreferenceContact
              week={week}
              handleCheckedJour={handleCheckedJour}
              register={form.register}
              error={error}
              contacts={contacts}
              handleChecked={handleChecked}
            />

            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              item
            >
              <Button
                color="primary"
                className="mt-1 mb-4"
                type="submit"
                size="large"
                variant="contained"
              >
                Envoyer
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={!!load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default SyngergieAsAssocie;
