import { Button, Grid, Grow, Typography } from '@mui/material';
import { DropzoneArea } from 'material-ui-dropzone';
import React from 'react';
import { toast } from 'react-toastify';
import { API_LBA } from '../../constants/routes';
import axios from '../../helpers/axios';
import history from '../../helpers/history';
import PositionImage from './PositionImage';

function PhotoBien({
  id,
  etape,
  pictures,
  error,
  defaultImage,
  setRequired,
  idnew,
  loadPhoto,
  setLoad,
  setPictures,
  setError,
}) {
  const updatePhotos = () => {
    setLoad(true);
    const bodyFormData = new FormData();
    for (let index = 0; index < pictures.length; index++) {
      const currentFile = pictures[index];
      bodyFormData.append(`file${index}`, currentFile);
    }
    axios({
      method: 'post',
      url: `${API_LBA}/media/photo/estimation?id=${idnew}`,
      data: bodyFormData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(() => {
        setLoad(false);
        toast.success('Mise à jours des photos réussi');
      })
      .catch((e) => {
        if (e.response) {
          const codeErreur = e.response.status;
          if (codeErreur === 401) {
            const message = 'Veuillez vous reconnecter';
            localStorage.clear();
            history.push('/login');
            toast.error(message);
          }
        }
      });
  };

  const onDrop = (picture) => {
    setPictures(picture);
    setError((prevState) => ({
      ...prevState,
      pictures: '',
    }));
  };

  return (
    <>
      {etape >= 1
        ? !loadPhoto && (
            <Grow in className="mb-5">
              <Grid>
                <Typography className="titre" variant="h4" compoment="p">
                  Photos <span className="titreSpan">du bien</span>
                </Typography>
                <Grid container>
                  <Typography
                    align="center"
                    className="error"
                    variant="subtitle1"
                  >
                    5 photos obligatoires dans l&apos;ordre d&apos;affichage
                    souhaité*
                  </Typography>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className="mt-3"
                >
                  <Typography
                    align="center"
                    className="mb-3"
                    variant="subtitle2"
                  >
                    Ordre d&apos;affichage des photos sur le PDF, choisissez une
                    ou plusieurs photos, la couleur du cadre changera pour vous
                    indiquer l&apos;emplacement.
                  </Typography>
                </Grid>
                <PositionImage pictures={pictures} />
                <DropzoneArea
                  initialFiles={
                    defaultImage
                      ? defaultImage.map(
                          (element) =>
                            `${API_LBA}/media/images/estimation/${element.image}`,
                        )
                      : []
                  }
                  dropzoneText="Glisser-déposer vos images ici ou cliquer"
                  onChange={onDrop}
                  filesLimit={5}
                  maxFileSize={2000000}
                  showPreviewsInDropzone
                  acceptedFiles={['image/jpeg']}
                />
                <Typography className="error">
                  {error ? error.pictures : null}
                </Typography>

                {etape > 1 && !id ? (
                  <Button
                    color="primary"
                    className="mt-1 mb-2"
                    size="large"
                    variant="contained"
                    onClick={() => {
                      updatePhotos();
                    }}
                  >
                    Mettre à jours les photos du bien
                  </Button>
                ) : null}

                {etape < 2 ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      color="primary"
                      className="mt-1 mb-2"
                      size="large"
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        setRequired((prevState) => ({
                          ...prevState,
                          required: null,
                        }));
                      }}
                    >
                      Suivant
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </Grow>
          )
        : null}
    </>
  );
}

export default PhotoBien;
