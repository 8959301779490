/* eslint-disable react/no-array-index-key */

import { FormGroup, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useStyles } from '../../styles/estimation/Classes';

function PreferenceContact({
  week,
  handleCheckedJour,
  register,
  error,
  contacts,
  handleChecked,
}) {
  const classes = useStyles();

  const styles = makeStyles(() => ({
    form: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
  }));

  const classesContact = styles();

  return (
    <Grid className={classes.field} item xs={12} md={12}>
      <Typography className="title text toto">
        Préférence pour la mise en relation
      </Typography>
      <FormGroup className={`p-1 ${classesContact.form}`}>
        {week.map((jour, index) => (
          <label key={index}>
            <input
              type="checkbox"
              data-id={index}
              placeholder={jour}
              name={jour}
              onClick={handleCheckedJour}
              {...register('preferenceContactJour', {
                required: false,
              })}
            />
            <p>{jour}</p>
          </label>
        ))}
      </FormGroup>
      <Typography color="secondary">{error.jour}</Typography>
      <FormGroup className={`p-1 ${classesContact.form}`}>
        {contacts.map((contact, index) => (
          <label key={index}>
            <input
              type="checkbox"
              data-id={index}
              placeholder={contact}
              name={contact}
              onClick={handleChecked}
              {...register('preferenceContact', {
                required: false,
              })}
            />
            <p>{contact}</p>
          </label>
        ))}
      </FormGroup>
      <Typography className="error" color="secondary">
        {error.contact}
      </Typography>
    </Grid>
  );
}

export default PreferenceContact;
