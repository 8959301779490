import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import SideBar from '../../components/SideBar';
import Contact from '../../components/synergie/Contact';
import PreferenceContact from '../../components/synergie/PreferenceContact';
import Projet from '../../components/synergie/Projet';
import TypeProjet from '../../components/synergie/TypeProjet';
import { SYNERGIES_API } from '../../constants/routes';
import axios from '../../helpers/axios';
import { style, useStyles } from '../../styles/estimation/Classes';
import AutocompleteAddress from '../../components/AutocompleteAddress';
import { CITYA } from '../../constants/synergyCategories';

function SyngergieCitya() {
  const form = useForm();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = form;

  const [load, setLoad] = useState(false);
  const [lastAdress, setLastAdress] = useState();
  const [checkedValuesJour, setCheckedValuesJour] = useState([]);
  const [checkedValues, setCheckedValues] = useState([]);
  const [checkedValuesProjet, setCheckedValuesProjet] = useState([]);
  const [error, setError] = useState({
    localisation: '',
    projet: '',
    jour: '',
    contact: '',
  });
  const onSubmit = (data, e) => {
    const finalData = {
      ...data,
      preferenceContactJour: checkedValuesJour,
      preferenceContact: checkedValues,
      projet: checkedValuesProjet,
      date: new Date(),
      localisation: lastAdress,
      synergieCategorie: CITYA,
    };

    if (finalData.localisation === '' || finalData.localisation === undefined) {
      setError((prevState) => ({
        ...prevState,
        localisation: 'Veuillez compléter ce champ.',
      }));
    } else if (checkedValuesProjet.length === 0) {
      setError((prevState) => ({
        ...prevState,
        projet: 'Veuillez compléter ce champ.',
      }));
    } else if (checkedValuesJour.length === 0) {
      setError((prevState) => ({
        ...prevState,
        jour: 'Veuillez compléter ce champ.',
      }));
    } else if (checkedValues.length === 0) {
      setError((prevState) => ({
        ...prevState,
        contact: 'Veuillez compléter ce champ.',
      }));
    } else {
      setLoad(true);
      axios
        .post(SYNERGIES_API, finalData)
        .then(() => {
          setLoad(false);
          toast.success('Formulaire envoyé');
        })
        .catch(() => {
          setLoad(false);
          toast.error("Echec dans l'envoi du formulaire");
        });
    }
    reset();
  };

  const week = [' Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

  const contacts = [
    'Début de matinée',
    'Fin de matinée',
    "Début d'après-midi",
    "Fin d'après-midi",
  ];

  const projets = ['Mise en vente', 'Achat', 'Location', 'Gestion', 'Syndic'];

  const handleCheckedJour = (e) => {
    const jour = week[e.target.dataset.id];
    const newCheckedValuesJour = checkedValuesJour.filter(
      (item) => item !== jour,
    );
    if (e.target.checked) newCheckedValuesJour.push(jour);
    setError((prevState) => ({
      ...prevState,
      jour: '',
    }));
    setCheckedValuesJour(newCheckedValuesJour);
  };

  const handleChecked = (e) => {
    const contact = contacts[e.target.dataset.id];
    const newCheckedValues = checkedValues.filter((item) => item !== contact);
    if (e.target.checked) newCheckedValues.push(contact);
    setError((prevState) => ({
      ...prevState,
      contact: '',
    }));
    setCheckedValues(newCheckedValues);
  };

  const handleCheckedProjet = (e) => {
    const projet = projets[e.target.dataset.id];
    const newCheckedValuesProjet = checkedValuesProjet.filter(
      (item) => item !== projet,
    );
    if (e.target.checked) newCheckedValuesProjet.push(projet);
    setError((prevState) => ({
      ...prevState,
      projet: '',
    }));
    setCheckedValuesProjet(newCheckedValuesProjet);
  };

  const classes = useStyles();
  const iconClasses = style();
  return (
    <SideBar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} xl={12}>
          <Typography className="ariane mt-3" compoment="p" align="center">
            Déclarer un contact Citya
          </Typography>
          <Typography className="ariane m-1" compoment="p" align="center">
            <img className={iconClasses.icon} src="/svg/citya.svg" alt="" />
          </Typography>
        </Grid>
        <Grid className={classes.container} item xs={11} md={11} lg={8}>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Contact
              name="nom"
              firstname="prenom"
              telephone="telephone"
              email="email"
              title="Contact"
              control={control}
              errors={errors}
              error={error}
              setError={setError}
              required
            />
            <AutocompleteAddress
              setLastAdress={setLastAdress}
              control={control}
              errors={errors}
              error={error}
              setError={setError}
              title="Adresse"
              label=""
            />
            <Projet control={control} errors={errors} />
            <TypeProjet
              projets={projets}
              handleChecked={handleCheckedProjet}
              error={error}
              register={form.register}
              title="Type de projet"
              name="projet"
            />
            <PreferenceContact
              week={week}
              handleCheckedJour={handleCheckedJour}
              register={form.register}
              error={error}
              contacts={contacts}
              handleChecked={handleChecked}
            />
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              item
            >
              <Button
                color="primary"
                className="mt-1 mb-4"
                type="submit"
                size="large"
                variant="contained"
              >
                Envoyer
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={!!load}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default SyngergieCitya;
