import styled from 'styled-components';
import colors from '../../../../styles/helpers/colors';
import ListItems from './ListItems';
import breakpoints from '../../../../helpers/breakpoints';

const Menu = styled.div`
  @media only screen and (min-width: ${breakpoints.minLaptop}px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 38px;
  }
  @media only screen and (max-width: ${breakpoints.maxNotebook}px) {
    align-items: stretch;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    height: 100vh;
    left: 0;
    overflow: auto;
    padding: 154px 24px 0;
    position: absolute;
    top: 0;
    visibility: hidden;
    width: 100%;
    z-index: -1;
    background: ${colors.bgPrincipal};

    &.open {
      height: calc(100vh - 60px);
      max-height: 80vh;
      padding: 2rem 2rem 0;
      top: 60px;
      visibility: visible;
      z-index: 99;

      ${ListItems} {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 665ms cubic-bezier(0, 0, 0.1, 1),
          transform 665ms cubic-bezier(0, 0, 0.1, 1);
        transition-delay: 195ms, 130ms;
      }
    }
  }
`;

export default Menu;
