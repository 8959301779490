import axios from '../helpers/axios';
import { PARTICIPANT_API } from '../constants/routes';
import useErrorHandler from '../hooks/useErrorHandler';

const { errorHandler } = useErrorHandler();

export const updateParticipant = (participant) => {
  return axios
    .put(`${PARTICIPANT_API}/${participant.id}`, participant)
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};

export const addParticipant = (participant) => {
  return axios
    .post(`${PARTICIPANT_API}`, participant)
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};

export const deleteParticipant = (participant) => {
  return axios
    .delete(`${PARTICIPANT_API}/${participant.id}`)
    .then((response) => response.data)
    .catch((error) => {
      errorHandler(error);
    });
};
