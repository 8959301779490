import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import jwtDecode from 'jwt-decode';
import SideBar from '../components/SideBar';
import MySpace from '../components/menu/MySpace';
import MyTools from '../components/menu/MyTools';
import MyLinks from '../components/menu/MyLinks';
import MyCompagny from '../components/menu/MyCompagny';
import MySpaceStyle from '../styles/pages/Home/MySpace';
import MyAdmin from '../components/menu/MyAdmin';
import rolesConstants from '../constants/security';

function HomePage() {
  const token = localStorage.getItem('token') || false;
  const user = token ? jwtDecode(token) : null;
  const { roles } = user;
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      margin: '50px',
      textAlign: 'center',
      background: 'rgba(255, 255, 255, 0.8)',
      height: '400px',
      width: '300px',
      borderRadius: '10px',
      gap: '5%',
    },
  }));

  const classes = useStyles();
  return (
    <SideBar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item xs={12} xl={12}>
          <Typography className="ariane mt-1" component="p" align="center">
            Mon accueil
          </Typography>
        </Grid>
        <MySpaceStyle>
          <Grid
            container
            flex="flex"
            justifyContent="center"
            alignContent="flex-start"
            className={classes.root}
          >
            <Grid item>
              <Box className={classes.box}>
                <Typography className="titleCategorie" align="center">
                  Mon espace
                </Typography>
                <Grid container spacing={1}>
                  <MySpace />
                </Grid>
              </Box>
              <Box className={classes.box}>
                <Typography className="titleCategorie" align="center">
                  Mes outils
                </Typography>
                <Grid container spacing={1}>
                  <MyTools />
                </Grid>
              </Box>
            </Grid>
            <Grid item>
              <Box className={classes.box}>
                <Typography className="titleCategorie" align="center">
                  Mon Réseau
                </Typography>
                <Grid container spacing={1}>
                  <MyLinks />
                </Grid>
              </Box>
              <Box className={classes.box}>
                <Typography className="titleCategorie" align="center">
                  Mes actus
                </Typography>
                <Grid container spacing={1}>
                  <MyCompagny />
                </Grid>
              </Box>
            </Grid>
            {roles.includes(rolesConstants.ADMIN) && (
              <Box className={classes.box}>
                <Typography className="titleCategorie" align="center">
                  Mon Admin
                </Typography>
                <Grid container spacing={1}>
                  <MyAdmin />
                </Grid>
              </Box>
            )}
          </Grid>
        </MySpaceStyle>
      </Grid>
    </SideBar>
  );
}

export default HomePage;
