import {
  Button,
  CircularProgress,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import HeightIcon from '@mui/icons-material/Height';
import Pagination from '@mui/material/Pagination';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { debounce } from 'lodash';
import Backdrop from '@mui/material/Backdrop';
import SideBar from '../components/SideBar';
import { API_LBA } from '../constants/routes';
import { findScoring, findScoringAdmin } from '../service/scoringApi';
import useErrorHandler from '../hooks/useErrorHandler';
import TitlePage from '../components/TitlePage';
import { useStyles } from '../styles/estimation/Classes';

function Scoring() {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [order, setOrder] = useState('asc');
  const [scoring, setScoring] = useState([]);
  const itemsPerPage = 12;
  const token = localStorage.getItem('token') || false;
  const user = token ? jwtDecode(token) : null;
  const [search, setSearch] = useState(null);
  const { errorHandler } = useErrorHandler();

  useEffect(() => {
    if (!user.roles.includes('ROLE_ADMIN')) {
      fetchScoring();
    } else {
      fetchScoringAdmin();
    }
  }, [currentPage, order]);

  const fetchScoring = async () => {
    setIsLoading(true);
    try {
      const data = await findScoring(
        currentPage,
        itemsPerPage,
        order,
        user.username,
      );
      setScoring(data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };
  const fetchScoringAdmin = async () => {
    setIsLoading(true);
    try {
      const data = await findScoringAdmin(currentPage, itemsPerPage, order);
      setScoring(data);
    } catch (e) {
      errorHandler(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
    const { myValue } = event.currentTarget.dataset;
    setOrder(myValue);
  };

  // debounce
  const updateQuery = async () => {
    let data;
    if (search === null) return;
    try {
      setIsLoading(true);
      data = await findScoringAdmin(currentPage, itemsPerPage, order, search);
      setScoring(data);
    } catch (e) {
      toast.error('Une erreur est survenue');
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const delayedQuery = useCallback(debounce(updateQuery, 500), [search]);

  useEffect(() => {
    delayedQuery();
    // setCurrentPage(1);
    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel;
  }, [delayedQuery]);

  const handleSearch = ({ currentTarget }) => {
    setSearch(currentTarget.value);
  };

  const style = (item) => {
    switch (true) {
      case item >= 90:
        return { backgroundColor: '#d5f5d9' };
      case item >= 66 && item <= 89:
        return { backgroundColor: '#f7fae4' };
      case item <= 65:
        return { backgroundColor: '#ffe7d1' };
      default:
        break;
    }
    return false;
  };

  // pagination
  const totalPages = Math.ceil(scoring['hydra:totalItems'] / itemsPerPage);
  return (
    <SideBar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <TitlePage
          title="Gestion de SAM"
          underTitle="Consultez toutes les analyses de scoring mandat déjà générées"
        />
        <Grid className="p-5" item xs={12}>
          <TableContainer component={Paper}>
            <Grid container display="flex" justifyContent="flex-start">
              <Button
                component={Link}
                to="/scoring-mandats"
                className="m-1"
                variant="contained"
                color="primary"
              >
                Accéder à l&apos;outil
              </Button>
              {user.roles.includes('ROLE_ADMIN') && (
                <Button
                  variant="contained"
                  color="primary"
                  className="m-1"
                  href={`${API_LBA}/scoring/export`}
                  download="export_scoring.csv"
                >
                  Export CSV
                </Button>
              )}
              {user.roles.includes('ROLE_ADMIN') && (
                <TextField
                  size="small"
                  className="m-1"
                  onChange={handleSearch}
                  value={search || ''}
                  label="Rechercher SAM par nom du consultant"
                />
              )}
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Consultant</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Résultat</TableCell>
                  <TableCell align="center">PDF</TableCell>
                  <TableCell align="center">
                    Date
                    <Button
                      className="ml-1"
                      size="small"
                      color="primary"
                      onClick={handleClick}
                    >
                      <HeightIcon fontSize="small" />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem data-my-value="desc" onClick={handleClose}>
                        Décroissant
                      </MenuItem>
                      <MenuItem data-my-value="asc" onClick={handleClose}>
                        Croissant
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              </TableHead>
              {scoring['hydra:member'] !== undefined &&
                scoring['hydra:member'].map((item) => (
                  <TableBody key={Math.random()}>
                    <TableRow>
                      <TableCell align="center">
                        {item.consultant.prenom} {item.consultant.nom}
                      </TableCell>
                      <TableCell align="center">
                        {item.consultant.email}
                      </TableCell>
                      <TableCell style={style(item.resultat)} align="center">
                        {item.resultat}%
                      </TableCell>
                      <TableCell align="center">
                        <Button variant="contained" size="small">
                          <Typography variant="caption">
                            <a
                              target="blank"
                              href={`${API_LBA}/scoring-mandats/pdf/${item.id}`}
                              download
                              rel="noreferrer"
                            >
                              Télécharger
                            </a>
                          </Typography>
                        </Button>
                      </TableCell>
                      <TableCell align="center">
                        {new Date(item.dateCreation).toLocaleDateString(
                          'fr-FR',
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
            </Table>{' '}
            {!isLoading ? (
              <Pagination
                count={!Number.isNaN(totalPages) ? totalPages : 0}
                page={currentPage}
                onChange={handleChange}
                size="large"
                shape="rounded"
              />
            ) : null}
          </TableContainer>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={!!isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default Scoring;
