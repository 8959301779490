import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import { Celebration, ManageSearch } from '@mui/icons-material';
import { ContactMail } from '@material-ui/icons';

function MyAdmin() {
  return (
    <Grid container item spacing={1} alignItems="flex-end">
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">Les Consultant(es)</Typography>
          <ListItem className="iconButton" component={Link} to="consultants">
            <ManageSearch fontSize="large" className="icon" />
          </ListItem>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography className="titleIcon" variant="caption">
            Contact
          </Typography>
          <ListItem className="iconButton" component={Link} to="contact">
            <ContactMail fontSize="large" className="icon" />
          </ListItem>
        </div>
      </Grid>{' '}
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography className="titleIcon" variant="caption">
            Animation
          </Typography>
          <ListItem
            className="iconButton"
            component={Link}
            to="animation/consultants"
          >
            <Celebration fontSize="large" className="icon" />
          </ListItem>
        </div>
      </Grid>
    </Grid>
  );
}

export default MyAdmin;
