import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/ 
     v2.0 | 20110126
     License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    text-decoration: none;
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    overflow-x: hidden;
    background: #F0F1F6;
    background-image: url('/images/fond.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh; /* set the minimum height to the viewport height */
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
  }

  /* pretty radio */
  label > input[type="checkbox"] {
    display: none;
  }

  label > input[type="checkbox"] + *::before {
    content: "";
    display: inline-block;
    vertical-align: bottom;
    width: 1rem;
    height: 1rem;
    margin-right: 0.3rem;
    margin-top: 0.3rem;
    border-radius: 10%;
    border-style: solid;
    border-width: 0.1rem;
    border-color: gray;
  }

  label > input[type="checkbox"]:checked + * {
    color: #00AFE6;
    font-weight: bold
  }

  label > input[type="checkbox"]:checked + *::before {
    content: "✓";
    color: #00AFE6;
    text-align: center;
  }

  .fileContainer .uploadPictureContainer {
    width: 12%;
    margin: 1%
  }

  a {
    color: white;
  }

  .m-0 {
    margin: 0 auto !important;
  }

  .m-1 {
    margin: 10px !important;
  }

  .m-2 {
    margin: 20px !important;
  }

  .m-3 {
    margin: 30px !important;
  }

  .m-4 {
    margin: 40px !important;
  }

  .m-5 {
    margin: 50px !important;
  }

  .p-0 {
    padding: 0 !important;
  }

  .p-1 {
    padding: 10px !important;
  }

  .p-2 {
    padding: 20px !important;
  }

  .p-3 {
    padding: 30px !important;
  }

  .p-4 {
    padding: 40px !important;
  }

  .p-5 {
    padding: 50px !important;
  }

  .pt-1 {
    padding-top: 10px !important;
  }

  .mt-0 {
    margin-top: 0  !important;
  }

  .mt-1 {
    margin-top: 10px !important;
  }

  .mt-2 {
    margin-top: 20px !important;
  }

  .mt-3 {
    margin-top: 30px !important;
  }

  .mt-4 {
    margin-top: 40px !important;
  }

  .mt-5 {
    margin-top: 50px !important;
  }

  .mb-0 {
    margin-bottom: 0  !important;
  }

  .mb-1 {
    margin-bottom: 10px !important;
  }

  .mb-2 {
    margin-bottom: 20px !important;
  }

  .mb-3 {
    margin-bottom: 30px !important;
  }

  .mb-4 {
    margin-bottom: 40px !important;
  }

  .mb-5 {
    margin-bottom: 50px !important;
  }

  .ml-0 {
    margin-left: 0  !important;
  }

  .ml-1 {
    margin-left: 10px !important;
  }

  .ml-2 {
    margin-left: 20px !important;
  }

  .ml-3 {
    margin-left: 30px !important;
  }

  .ml-4 {
    margin-left: 40px !important;
  }

  .ml-5 {
    margin-left: 50px !important;
  }

  .mr-0 {
    margin-right: 0  !important;
  }

  .mr-1 {
    margin-right: 10px !important;
  }

  .mr-2 {
    margin-right: 20px !important;
  }

  .mr-3 {
    margin-right: 30px !important;
  }

  .mr-4 {
    margin-right: 40px !important;
  }

  .mr-5 {
    margin-right: 50px !important;
  }

  .p-1 {
    padding: 10px !important;
  }

  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  html {
    scrollbar-color: #7f7f7f white; /* thumb and track color */
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: white;
  }

  ::-webkit-scrollbar-thumb {
    background: #7f7f7f;
    border-right: 1px solid white;
  }

  .input-field {
    margin-left: 11rem;
  }

  .MuiPagination-root {
    display: flex;
    justify-content: center;
  }

  .MuiPagination-ul {
    margin-bottom: 2rem !important;
    margin-top: 2rem !important;
  }

  tr:nth-child(even) {
    background-color: #f5f5f5;
  }

  .MuiButton-containedPrimary {
    color: #fff !important;
    background-color: #00AFE6 !important;
    min-width: 64px !important;
    padding: 8px 22px !important;
    border-radius: 4px !important;
  }

  .MuiButton-containedPrimary:hover {
    color: #fff !important;
    background-color: #7ad0ef !important;
  }

  //.MuiCardContent-root {
  //  height: 200px !important;
  //}

  .MuiDrawer-paperAnchorLeft {
    background-color: #3f525c !important;
  }

  hr {
    border-top: 0px solid rgba(255, 255, 255, 0.5);
  }


  .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
    background-color: rgb(0, 175, 230) !important;
  }

  .ariane {
    font-size: 2.125rem !important;
    //color: #3f525c !important;
    color: white !important;
    font-weight: bold !important;
  } 
  .subAriane {
    font-size: 1.5rem !important;
    color: rgb(0, 175, 230) !important;;
  }

  .titleCategorie {
    font-size: 1.425rem !important;
    font-weight: bold !important;
    color: #3f525c !important;
    padding: 10px;
  }

  .titleCategorie::after {
    border-top: 2px solid rgb(0, 175, 230);
    height: 10px;
    width: 100%;
  }

  .MuiTypography-caption {
    color: #3f525c !important;
    font-weight: bold !important;
  }

  @media screen and (max-width: 640px) {
    .ariane {
      font-size: 1.4rem !important;
    }

    .fileUploader {
      width: auto;
    }
  }

  /* HIDE RADIO */
  [type=radio] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* IMAGE STYLES */
  [type=radio] + svg {
    cursor: pointer;
    color: #3f525c;
  }

  /* CHECKED STYLES */
  [type=radio]:checked + svg {
    color: #00afe6;
  }

  .iconType {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .MuiDropzoneArea-root {
    color: #3f525c;
    background: white;
    border-radius: 0px;
    border-left: solid 15px #00AFE6 !important;
    border-right: solid 5px #3f525c !important;
    border-bottom: solid #00AFE6 !important;
    border-top: solid 5px #3f525c !important;
  }

  .MuiGrid-spacing-xs-8 {
    width: calc(93% + 64px);
    margin: -32px;
  }

  .menu-img {
    margin: 0 auto;
    height: 25px;
  }

  .MuiListItem-gutters {
    padding-left: 16px;
    padding-right: 16px !important;
  }

  .titleSection {
    font-size: 18px;
  }

  .button-menu span.MuiButton-label {
    text-transform: capitalize;
  }

  .container-menu:hover {
    background: rgb(0, 175, 230);
  }

  .button-menu {
    width: 100% !important;
    justify-content: flex-start !important;
  }

  .Toastify__toast-container--top-center {
    width: auto;
    white-space: pre-line;
  }

  .error {
    color: red !important;
  }

  .backdrop {
    z-index: 100000;
    color: #fff;
  }

  ,

`;

export default GlobalStyles;
