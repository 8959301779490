import { Button, CircularProgress, Grid, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClearIcon from '@mui/icons-material/Clear';
import Pagination from '@mui/material/Pagination';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';
import Backdrop from '@mui/material/Backdrop';
import Paper from '@mui/material/Paper';
import CardsConsultants from '../../components/CardsConsultants';
import SideBar from '../../components/SideBar';
import TitlePage from '../../components/TitlePage';
import { fetchConsultants } from '../../features/consultant/consultantsSlice';
import ButtonCountRed from '../../styles/pages/consultants/ButtonCountRed';
import ButtonCountStyle from '../../styles/pages/consultants/ButtonCountStyle';
import { findConsultantStats } from '../../service/consultantsApi';

function AdminConsultant() {
  const dispatch = useDispatch();
  const { consultants, loading } = useSelector((state) => state.consultants);
  const [isActif, setIsActif] = useState(null);
  const [inactif, setInactif] = useState(null);
  const [actif, setActif] = useState(null);
  const [search, setSearch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  useEffect(() => {
    const data = {
      currentPage,
      search: search === null ? '' : search,
      itemsPerPage,
      actif,
    };
    dispatch(fetchConsultants(data));
    if (isActif === null) {
      fetchActif();
    }
  }, [currentPage, actif]);

  // debounce
  const updateQuery = () => {
    if (search !== null) {
      const data = {
        currentPage,
        search: search === null ? '' : search,
        itemsPerPage,
        actif,
      };
      dispatch(fetchConsultants(data));
    }
  };

  const delayedQuery = useCallback(debounce(updateQuery, 500), [search]);

  useEffect(() => {
    delayedQuery();
    setCurrentPage(1);
    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel;
  }, [delayedQuery]);

  // compte le nombre de mandataire actif ou non actif
  const fetchActif = async () => {
    try {
      const data = await findConsultantStats();
      setIsActif(data.consultantActif);
      setInactif(data.consultantInactif);
    } catch (error) {
      toast.error('Impossible de charger les consultants actif');
    }
  };

  const handleSearch = ({ currentTarget }) => {
    setSearch(currentTarget.value);
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  // pagination
  const totalPages = Math.ceil(consultants['hydra:totalItems'] / itemsPerPage);

  const styles = makeStyles(() => ({
    img: {
      width: '15px',
      marginRight: '10px',
      marginLeft: '10px',
      fill: 'white',
    },
  }));

  const style = styles();

  return (
    <SideBar>
      <Grid
        container
        item
        direction="row"
        justifyContent="center"
        alignItems="center"
        xs={12}
      >
        <TitlePage
          title="Gestion des consultants"
          underTitle="Administrez la photo de profil des consultants, leurs barèmes,
          leurs RSAC ainsi que leur visibilité sur le site"
        />

        <Grid
          container
          item
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          className="mt-3"
          xs={11}
          component={Paper}
        >
          <Grid className="p-5" item xs={12}>
            <Grid
              container
              display="flex"
              justifyContent="flex-start"
              alignContent="center"
            >
              <ButtonCountStyle onClick={() => setActif(true)}>
                <img className={style.img} src="/images/user.svg" alt="" />
                {actif === null && (
                  <>
                    <div className="ui-button">Consultants activés</div>
                    <div className="result">{isActif}</div>
                  </>
                )}
                {actif === true && (
                  <>
                    <div className="ui-button">Consultants activés</div>
                    <div className="result">{isActif}</div>
                  </>
                )}
                {actif === false && (
                  <div className="ui-button">
                    Afficher les consultants activés
                  </div>
                )}
              </ButtonCountStyle>

              <ButtonCountRed onClick={() => setActif(false)}>
                <img className={style.img} src="/images/user.svg" alt="" />

                {!actif ? (
                  <>
                    <div className="ui-button">Consultants désactivés</div>
                    <div className="result">{inactif}</div>
                  </>
                ) : (
                  <div className="ui-button">
                    Afficher les consultants désactivés
                  </div>
                )}
              </ButtonCountRed>

              {actif !== null && (
                <Button onClick={() => setActif(null)}>
                  <ClearIcon color="secondary" />
                </Button>
              )}
              <TextField
                className="m-1"
                onChange={handleSearch}
                value={search || ''}
                label="Rechercher un consultant"
              />
            </Grid>
          </Grid>

          <CardsConsultants
            loading={loading}
            consultants={consultants && consultants['hydra:member']}
          />
          {!loading ? (
            <Pagination
              count={!Number.isNaN(totalPages) ? totalPages : 0}
              page={currentPage}
              onChange={handleChange}
              size="large"
              shape="rounded"
              siblingCount={1}
            />
          ) : null}
        </Grid>
      </Grid>
      <Backdrop className="backdrop" open={!!loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default AdminConsultant;
