import styled from 'styled-components';
import breakpoints from '../../../../helpers/breakpoints';
import colors from '../../../../styles/helpers/colors';

const Nav = styled.nav`
  display: flex;
  align-items: center;
  height: 90px;
  background-color: ${colors.bgSecondaire};
  z-index: 99;
  width: 100%;
  position: sticky;
  top: 0;
  @media only screen and (max-width: ${breakpoints.maxNotebook}px) {
    height: 60px;
  }
`;

export default Nav;
