import { toast } from 'react-toastify';
import history from '../helpers/history';

const useErrorHandler = () => {
  const errorHandler = (error) => {
    let message = 'Une erreur est survenue';

    if (error.response) {
      if (error.response.status === 401) {
        message = 'Veuillez vous reconnecter';
        localStorage.clear();
        history.push('/login');
      } else if (error.response.status === 422) {
        message = error.response.data['hydra:description'];
      } else if (error.response.status === 400) {
        message = error.response.data;
        toast.error(message);
        throw error;
      }
      toast.error(message);
      throw error;
    }
  };

  return { errorHandler };
};

export default useErrorHandler;
