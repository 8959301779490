/*
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sendResetPassword } from '../features/auth/authSlice';
import LoginForm from '../styles/pages/login/LoginForm';

function ResetPassword() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth); // Update with the correct slice name
  const { token } = useParams();

  const form = useForm();
  const { register, handleSubmit, reset } = form;

  const onSubmit = (data) => {
    const credentials = {
      password: data.password,
      password2: data.password2,
      token,
    };
    dispatch(sendResetPassword(credentials));
    reset();
  };

  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const useStyles = makeStyles(() => ({
    form: {
      width: '100%',
    },
  }));

  const classes = useStyles();

  return (
    <LoginForm>
      <Container className="whiteBloc">
        <Typography>Mot de passe oublié</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl className={`p-2 ${classes.form}`}>
            <input
              id="password"
              name="password"
              className="mb-1 "
              type={values.showPassword ? 'text' : 'password'}
              placeholder="Votre nouveau de passe"
              {...register('password', { required: false })}
              /!*               endadornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    size="large"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              } *!/
            />
          </FormControl>
          <FormControl className={classes.form}>
            <input
              id="password2"
              name="password2"
              className="mb-1"
              type={values.showPassword ? 'text' : 'password'}
              placeholder="Votre nouveau de passe"
              {...register('password2', { required: false })}
            />
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    onClick={handleClickShowPassword}
                    color="primary"
                    size="small"
                  />
                }
                variant="body2"
                label={
                  <Typography variant="body2">
                    Afficher le mot de passe
                  </Typography>
                }
              />
            </Grid>
            <FormHelperText variant="standard" className="mb-2">
              Au moins 8 caractères, une majuscule, un chiffre, un caractères
              spécial sauf / et \.
            </FormHelperText>
          </FormControl>
          <Button
            disabled={loading}
            className="btnLogin mb-1"
            variant="contained"
            type="submit"
          >
            {!loading ? (
              'Changer mon mot de passe'
            ) : (
              <CircularProgress style={{ color: 'white' }} size="15px" />
            )}
          </Button>
        </form>
      </Container>
    </LoginForm>
  );
}

export default ResetPassword;
*/
import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { sendResetPassword } from '../features/auth/authSlice';
import LoginForm from '../styles/pages/login/LoginForm';
import TitlePage from '../components/TitlePage';

function ResetPassword() {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth); // Update with the correct slice name
  const { token } = useParams();
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const form = useForm();
  const { register, handleSubmit, reset } = form;

  const onSubmit = (data) => {
    const credentials = {
      password: data.password,
      password2: data.password2,
      token,
    };
    dispatch(sendResetPassword(credentials));
    reset();
  };

  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
  });

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const useStyles = makeStyles((theme) => ({
    formContainer: {
      padding: theme.spacing(4),
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderRadius: theme.shape.borderRadius,
    },
    textField: {
      marginBottom: theme.spacing(2),
    },
    submitButton: {
      marginTop: theme.spacing(1),
    },
    forgotPasswordLink: {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
      '&:hover': {
        color: theme.palette.primary.main,
      },
      marginTop: theme.spacing(1),
    },
    registerLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        textDecoration: 'underline',
      },
      marginBottom: theme.spacing(0),
    },
    logo: {
      maxWidth: '100px',
      maxHeight: '100px',
    },
  }));

  const classes = useStyles();

  return (
    <LoginForm>
      <Container>
        <TitlePage
          title="MON ENTREPRISE"
          underTitle="Modifier votre mot de passe
"
        />
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={8} md={6} className={classes.formContainer}>
            <Grid container justifyContent="center" paddingBottom={2}>
              <img
                src="/svg/lba.svg"
                className={classes.logo}
                alt="lebonagent logo"
              />
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth variant="standard">
                <TextField
                  id="password"
                  name="password"
                  className="mb-1 "
                  type={showPassword1 ? 'text' : 'password'}
                  placeholder="Votre nouveau de passe"
                  {...register('password', { required: false })}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        className={classes.passwordIcon}
                        onClick={handleClickShowPassword1}
                      >
                        {showPassword1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />

                <TextField
                  id="password2"
                  name="password2"
                  className="mb-1"
                  type={showPassword2 ? 'text' : 'password'}
                  placeholder="Votre nouveau de passe"
                  {...register('password2', { required: false })}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        className={classes.passwordIcon}
                        onClick={handleClickShowPassword2}
                      >
                        {showPassword2 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />

                <FormHelperText variant="standard" className="mb-2">
                  Au moins 8 caract&egrave;re, une majuscule, un
                  caract&egrave;res·sp&eacute;cial·parmi·&excl;·&commat;·&num;·&dollar;·&percnt;·&Hat;·&amp;·&lt;·&gt;·&lowbar;·&hyphen;·&lpar;·&rpar;·&period;·&comma;·&lbrace;
                </FormHelperText>
              </FormControl>
              <Grid container justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  size="large"
                >
                  {!loading ? (
                    'Changer de mot de passe'
                  ) : (
                    <CircularProgress style={{ color: 'white' }} size="15px" />
                  )}
                </Button>{' '}
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        className="containerArche mb-2"
      >
        <img
          className="mb-1 logoArche"
          src="https://media.sas-arche.com/apps_logos_white/arche.svg"
          alt="logo arche"
        />
        <Typography variant="caption" className="textWhite">
          ©{new Date().getFullYear()}, tous droits réservés
        </Typography>
      </Grid>
    </LoginForm>
  );
}

export default ResetPassword;
