import Button from '@mui/material/Button';
import React from 'react';
import { toast } from 'react-toastify';
import history from '../helpers/history';

function Logout() {
  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.clear();
    history.push('/login');
    toast.success('Vous êtes désormais déconnecté·e. Merci de votre visite !');
  };

  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      onClick={(e) => handleLogout(e)}
    >
      Déconnexion
    </Button>
  );
}

export default Logout;
