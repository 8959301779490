import React from 'react';
import AnalyseQualitative from './AnalyseQualitative';
import EtapeUn from './EtapeUn';
import MethodeDeux from './MethodeDeux';
import MethodeTrois from './MethodeTrois';
import PhotoBien from './PhotoBien';
import Situation from './Situation';

function ContainerEtape({
  id,
  load,
  classes,
  control,
  required,
  errors,
  error,
  setUpdateAdress,
  lastAdress,
  adress,
  setTypeBien,
  setError,
  register,
  loading,
  etape,
  setRequired,
  setType,
  pictures,
  defaultImage,
  idnew,
  loadPhoto,
  setLoad,
  setUpdateAdressBien,
  lastAdressBien,
  adressBien,
  typeBien,
  setEtape,
  prixMin,
  prixMax,
  prixMoyen,
  cityBien,
  resultMethodeDeux,
  methodeQ,
  handleChangeLimitation,
  handleChangeValorisation,
  valorisation,
  limitation,
  menuProps,
  setAdress,
  setAdressBien,
  setValorisation,
  setLimitation,
  setPictures,
  setCityBien,
  setPrixBien,
  type,
  surface,
}) {
  return (
    <>
      <EtapeUn
        classes={classes}
        control={control}
        required={required}
        errors={errors}
        error={error}
        setUpdateAdress={setUpdateAdress}
        lastAdress={lastAdress}
        adress={adress}
        setTypeBien={setTypeBien}
        setError={setError}
        register={register}
        etape={etape}
        setRequired={setRequired}
        setType={setType}
        setAdress={setAdress}
      />
      <PhotoBien
        id={id}
        idnew={idnew}
        etape={etape}
        pictures={pictures}
        error={error}
        setError={setError}
        loadPhoto={loadPhoto}
        setLoad={setLoad}
        defaultImage={defaultImage}
        setRequired={setRequired}
        setPictures={setPictures}
      />
      <Situation
        type={type}
        setCityBien={setCityBien}
        setPrixBien={setPrixBien}
        setError={setError}
        setAdressBien={setAdressBien}
        etape={etape}
        setUpdateAdressBien={setUpdateAdressBien}
        setEtape={setEtape}
        classes={classes}
        control={control}
        required={required}
        errors={errors}
        error={error}
        lastAdressBien={lastAdressBien}
        adressBien={adressBien}
        loading={loading}
        typeBien={typeBien}
        setRequired={setRequired}
        prixMin={prixMin}
        prixMax={prixMax}
        prixMoyen={prixMoyen}
        cityBien={cityBien}
        surface={surface}
      />
      <MethodeDeux
        id={id}
        load={load}
        etape={etape}
        error={error}
        typeBien={typeBien}
        setEtape={setEtape}
        classes={classes}
        register={register}
        loading={loading}
        setRequired={setRequired}
        setError={setError}
        idnew={idnew}
        resultMethodeDeux={resultMethodeDeux}
      />
      <MethodeTrois
        id={id}
        etape={etape}
        control={control}
        required={required}
        errors={errors}
        loading={loading}
        setRequired={setRequired}
        methodeQ={methodeQ}
      />
      <AnalyseQualitative
        etape={etape}
        error={error}
        setEtape={setEtape}
        handleChangeValorisation={handleChangeValorisation}
        handleChangeLimitation={handleChangeLimitation}
        valorisation={valorisation}
        limitation={limitation}
        MenuProps={menuProps}
        loading={loadPhoto}
        typeBien={typeBien}
        setRequired={setRequired}
        setValorisation={setValorisation}
        setLimitation={setLimitation}
        setError={setError}
      />
    </>
  );
}

export default ContainerEtape;
