export default {
  minLargeDesktop: 1921,
  maxDesktop: 1920,
  minDesktop: 1441,
  maxLaptop: 1440,
  minLaptop: 1281,
  maxNotebook: 1280,
  minNotebook: 1025,
  maxTabletLarge: 1024,
  minTabletLarge: 834,
  maxTablet: 833,
  minTablet: 768,
  maxMobile: 767,
  minMobile: 321,
  maxSmallMobile: 320,
};
