import { ErrorMessage } from '@hookform/error-message';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Autocomplete,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import history from '../../helpers/history';
import { findActif } from '../../service/consultantsApi';
import GroupeBtn from './GroupeBtn';

function ChoiceTemplate({
  classes,
  handleChangeRadio,
  errors,
  setImageData,
  onClick,
  onSubmit,
  isLoading,
  control,
}) {
  const [search, setSearch] = useState(null);
  const [actif, setActif] = useState();

  const useStyles = makeStyles(() => ({
    auto: {
      width: '225px',
      padding: '5px',
      // height: '30px',
    },
  }));

  const choiceClasse = useStyles();

  const handleSearch = ({ currentTarget }) => {
    setSearch(currentTarget.value);
  };

  const fetchActif = async () => {
    try {
      const data = await findActif(search);
      setActif(data['hydra:member']);
    } catch (e) {
      if (e.response) {
        const codeErreur = e.response.status;
        if (codeErreur === 401) {
          const message = 'Veuillez vous reconnecter';
          localStorage.clear();
          history.push('/login');
          toast.error(message);
        }
      }
    }
  };

  // debounce
  const updateQuery = () => {
    // A search query api call.
    if (search !== null && search !== 0) {
      fetchActif(search);
    }
  };

  const delayedQuery = useCallback(debounce(updateQuery, 500), [search]);

  useEffect(() => {
    delayedQuery();
    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel;
  }, [delayedQuery]);

  return (
    <Grid
      className={classes.container}
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Controller
          render={() => (
            <Autocomplete
              freeSolo
              id="consultant"
              className={choiceClasse.auto}
              onInputChange={handleSearch}
              onChange={(event, value) =>
                setImageData((prevState) => ({
                  ...prevState,
                  consultant: value.id,
                }))
              }
              disableClearable
              options={actif !== undefined ? actif : []}
              getOptionLabel={(option) => `${option.nom} ${option.prenom}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Rechercher un consultant"
                  type="text"
                  placeholder="DUPONT Jordan "
                  InputProps={{ ...params.InputProps, type: 'search' }}
                />
              )}
            />
          )}
          name="consultant"
          control={control}
          defaultValue=""
          rules={{
            required: false,
            maxLength: {
              value: 255,
              message: 'Le nombre maximum de caractères a été dépassé',
            },
          }}
        />
      </Grid>
      <Grid item>
        <RadioGroup
          aria-label="Template"
          name="Template"
          onChange={handleChangeRadio}
        >
          <FormControlLabel
            value="0"
            control={<Radio color="primary" />}
            label="Bienvenue"
          />
          <FormControlLabel
            value="1"
            control={<Radio color="primary" />}
            label="1ère vente"
          />
          <FormControlLabel
            value="3"
            control={<Radio color="primary" />}
            label="1er mandat"
          />
          <FormControlLabel
            value="2"
            control={<Radio color="primary" />}
            label="Anniversaire"
          />
          <ErrorMessage
            errors={errors}
            name="template"
            message="Template requis."
            render={({ message }) => <span>{message}</span>}
          />
        </RadioGroup>
      </Grid>
      <Grid item>
        <GroupeBtn
          classes={classes}
          isLoading={isLoading}
          onClick={onClick}
          onSubmit={onSubmit}
        />
      </Grid>
    </Grid>
  );
}

export default ChoiceTemplate;
