import { Grid, Paper, Tabs, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import SideBar from '../components/SideBar';
import { findEstimationStats } from '../service/estimationApi';
import TitlePage from '../components/TitlePage';

function StatsCompany() {
  const [totalEstimation, setTotalEstimation] = useState();
  const [totalMoyenneEstimation, setTotalMoyenneEstimation] = useState();
  const [totalEstimationByConsultant, setTotalEstimationByConsultant] =
    useState();

  const fetchEstimationStats = async () => {
    try {
      const data = await findEstimationStats();
      setTotalEstimation(data.totalEstimations);
      setTotalEstimationByConsultant(data.totalEstimationsConsultant);
      setTotalMoyenneEstimation(data.totalMoyenneEstimations);
    } catch (e) {
      toast.error('Une erreur est survenue');
    }
  };

  useEffect(() => {
    fetchEstimationStats();
    return () => {
      setTotalEstimation();
      setTotalEstimationByConsultant();
      setTotalMoyenneEstimation();
    };
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      marginLeft: '10px',
      marginBottom: '10px',
      color: 'white',
      background: 'rgba(0,175,230,1)',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignContent: 'center',
    },
    img: {
      height: '200px',
      width: '200px',
    },
  }));
  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,175,230,1)',
    },
    '& .MuiTabs-indicatorSpan': {
      width: '100%',
      backgroundColor: 'rgba(0,175,230,1)',
    },
  });
  const classes = useStyles();

  return (
    <SideBar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <TitlePage
          title="Statistiques"
          underTitle="Consultez tout les chiffres de l'entreprise"
        />
        <Grid
          container
          className="p-5"
          item
          display="flex"
          justifyContent="center"
        >
          <Grid item xs={11} lg={8}>
            <Paper elevation={3} className={classes.paper}>
              <Typography compoment="p" variant="h6">
                {totalEstimationByConsultant ? (
                  <CountUp delay={2} end={totalEstimationByConsultant} />
                ) : (
                  0
                )}
              </Typography>
              <Typography compoment="p" variant="body2">
                Estimations générées
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={11} lg={8}>
            <Paper elevation={3} className={classes.paper}>
              <Typography compoment="p" variant="h6">
                {totalEstimation ? (
                  <CountUp delay={2} end={totalEstimation} />
                ) : (
                  0
                )}
              </Typography>
              <Typography compoment="p" variant="body2">
                Estimations générées par les consultants
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={11} lg={8}>
            <Paper elevation={3} className={classes.paper}>
              <Typography compoment="p" variant="h6">
                {Number.isNaN(totalMoyenneEstimation) ? (
                  <CountUp delay={2} end={totalMoyenneEstimation} />
                ) : (
                  0
                )}
              </Typography>
              <Typography compoment="p" variant="body2">
                Estimations en moyenne par consultant
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        {/*         <MyChartComponent
          totalEstimation={totalEstimation}
          totalMoyenneEstimation={totalMoyenneEstimation}
          totalEstimationByConsultant={totalEstimationByConsultant}
        /> */}
      </Grid>
    </SideBar>
  );
}
export default StatsCompany;
