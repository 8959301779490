import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Home as HomeIcon, Score as ScoreIcon } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import SideBarItem from '../SideBarItem';
import { menu } from '../../constants/menu';

function MyTools() {
  return (
    <Grid container item spacing={1} alignItems="flex-end">
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">Mes estimations</Typography>
          <ListItem
            className="iconButton"
            component={Link}
            to="mes-estimations"
          >
            <HomeIcon fontSize="large" className="icon" />
          </ListItem>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">Transacnet</Typography>
          <SideBarItem
            key={Math.random()}
            icon={menu.TransacNet.icon}
            target={menu.TransacNet.target}
            href={menu.TransacNet.href}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">Protexa</Typography>
          <SideBarItem
            key={Math.random()}
            icon={menu.Protexa.icon}
            target={menu.Protexa.target}
            href={menu.Protexa.href}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">Yanport</Typography>
          <SideBarItem
            key={Math.random()}
            icon={menu.Yanport.icon}
            target={menu.Yanport.target}
            href={menu.Yanport.href}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography className="titleIcon" variant="caption">
            SAM
          </Typography>
          <ListItem className="iconButton" component={Link} to="scoring">
            <ScoreIcon fontSize="large" className="icon" />
          </ListItem>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">RhinovPro</Typography>
          <SideBarItem
            key={Math.random()}
            icon={menu['Rhinov pro'].icon}
            target={menu['Rhinov pro'].target}
            href={menu['Rhinov pro'].href}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">NodalView</Typography>
          <SideBarItem
            key={Math.random()}
            icon={menu.Nodalview.icon}
            target={menu.Nodalview.target}
            href={menu.Nodalview.href}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">ImmoData</Typography>
          <SideBarItem
            key={Math.random()}
            icon={menu.ImmoData.icon}
            target={menu.ImmoData.target}
            href={menu.ImmoData.href}
          />
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">Danim</Typography>
          <SideBarItem
            key={Math.random()}
            icon={menu.Danim.icon}
            target={menu.Danim.target}
            href={menu.Danim.href}
          />
        </div>
      </Grid>
    </Grid>
  );
}

export default MyTools;
