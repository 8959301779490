import { Button, CircularProgress, Fab, Grid, Typography } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { mapValues } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Scroll from 'react-scroll';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';
import ContainerEtape from '../components/estimation/ContainerEtape';
import SideBar from '../components/SideBar';
import {
  checkboxCollectif,
  checkboxIndividuel,
  checkboxTerrain,
} from '../constants/checkbox';
import { API_LBA, ESTIMATION_API } from '../constants/routes';
import axios from '../helpers/axios';
import history from '../helpers/history';
import {
  findEstimationById,
  findEstimationPhotoById,
  postNewEstimation,
  saveEstimationAndRedirect,
  updateEstimationAndMedia,
  updateEstimationAndRedirect,
} from '../service/estimationApi';
import { useStyles } from '../styles/estimation/Classes';

const scroll = Scroll.animateScroll;

function Estimation() {
  const token = localStorage.getItem('token') || false;
  const user = token ? jwtDecode(token) : null;
  const form = useForm();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = form;
  const [valorisation, setValorisation] = useState([]);
  const [limitation, setLimitation] = useState([]);
  const [methodeQ, setMethodeQ] = useState();
  const [defaultImage, setDefaultImage] = useState();
  const [pictures, setPictures] = useState([]);
  const [adress, setAdress] = useState([]);
  const [adressBien, setAdressBien] = useState([]);
  const [etape, setEtape] = useState(0);
  const [id, setId] = useState();
  const [load, setLoad] = useState(false);
  const [loadPhoto, setLoadPhoto] = useState(false);
  const [loadingEtape, setLoadingEtape] = useState(true);
  const [estimation, setEstimation] = useState([]);
  const [typeBien, setTypeBien] = useState();
  const [type, setType] = useState();
  const [idnew, setIdnew] = useState();
  const [editing, setEditing] = useState(false);
  const [resultMethodeDeux, setResultMethodeDeux] = useState();
  const [surface, setSurface] = useState(0);
  const [error, setError] = useState({
    pictures: '',
    valorisation: '',
    limitation: '',
    checkbox: '',
    typeBien: '',
    adresseBien: '',
    adresseClient: '',
  });
  const [lastAdress, setLastAdress] = useState('');
  const [lastAdressBien, setLastAdressBien] = useState('');
  const [updateAdress, setUpdateAdress] = useState('');
  const [updateAdressBien, setUpdateAdressBien] = useState('');
  const [required, setRequired] = useState({ required: false });
  const { idEstimation = 'new' } = useParams();
  const [prixBien, setPrixBien] = useState({
    min: 0,
    max: 0,
    moyen: 0,
  });
  const [cityBien, setCityBien] = useState();
  const classes = useStyles();
  // Gestion taille du menu select
  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  // fetch l'estimation si en edit set le type de bien en fonction
  const fetchEstimationById = async () => {
    try {
      const data = await findEstimationById(idEstimation);
      if (
        !user.roles.includes('ROLE_ADMIN') &&
        user.username !== data.consultant.email
      ) {
        history.push('/');
      } else {
        setEstimation(data);
        setIdnew(data.id);
        setValorisation(data.valorisation);
        setLimitation(data.limitation);
        setResultMethodeDeux(data.methodeDeux);
        setMethodeQ(data.methodeQuatre);
        setSurface(data.surface);
        setType(data.typeBien);
        setEtape(data.etape);
        switch (true) {
          case data.typeBien === 'terrain':
            setTypeBien(checkboxTerrain);
            break;
          case data.typeBien === 'maison':
            setTypeBien(checkboxIndividuel);
            break;
          case data.typeBien === 'local commercial':
            setTypeBien(checkboxIndividuel);
            break;
          case data.typeBien === 'appartement':
            setTypeBien(checkboxCollectif);
            break;
          default:
            break;
        }
      }
    } catch (e) {
      if (e.response) {
        let message = 'Une erreur est survenue';
        const codeErreur = e.response.status;
        if (codeErreur === 401) {
          message = 'Veuillez vous reconnecter';
          localStorage.clear();
          history.push('/login');
        }
        toast.error(message);
      }
    }
  };
  const fetchEstimationPhotos = async () => {
    setLoadPhoto(true);
    try {
      const data = await findEstimationPhotoById(idEstimation);
      setDefaultImage(data['hydra:member']);
      setLoadPhoto(false);
    } catch (e) {
      setLoadingEtape(true);
      const message = 'Une erreur est survenue';
      toast.error(message);
    }
  };
  const fetchEstimation = async () => {
    setLoadingEtape(true);
    try {
      await fetchEstimationById(idEstimation);
      await fetchEstimationPhotos(idEstimation);
    } catch (e) {
      const message =
        "Une erreur est survenue lors du chargement de l'estimation";
      toast.error(message);
    } finally {
      setLoadingEtape(false);
    }
  };
  // gestion du fetch estimation si en edit
  useEffect(() => {
    if (idEstimation === 'new') {
      setLoadingEtape(false);
    } else {
      setEditing(true);
      fetchEstimation();
    }
    return () => {
      setEditing(false);
      setLoadingEtape(false);
      setDefaultImage();
      setLoadPhoto(false);
      setEstimation([]);
      setEtape(0);
      setIdnew();
      setValorisation([]);
      setLimitation([]);
      setResultMethodeDeux();
      setMethodeQ();
      setType();
    };
  }, [idEstimation]);
  // gestion de l'adresse dans l'autocomplete
  useEffect(() => {
    if (Object.keys(estimation).length > 0) {
      mapValues(estimation, (value, key) => setValue(key, value));
      if (
        estimation.adresseClient &&
        estimation.codePostal &&
        estimation.villeClient
      ) {
        setLastAdress(
          `${estimation.adresseClient} ${estimation.codePostal} ${estimation.villeClient}`,
        );
      }
      if (
        estimation.adresseBien &&
        estimation.codePostalBien &&
        estimation.villeBien
      ) {
        setLastAdressBien(
          `${estimation.adresseBien} ${estimation.codePostalBien} ${estimation.villeBien}`,
        );
      }
    }
  }, [estimation]);
  const onSubmit = (data) => {
    // tous les champs de l'estimation
    const finalData = {
      ...data,
      etape,
      consultant: null,
      codePostalBien:
        adressBien && adressBien[0] ? adressBien[0].properties.postcode : '',
      adresseBien:
        adressBien && adressBien[0] ? adressBien[0].properties.name : '',
      villeBien:
        adressBien && adressBien[0] ? adressBien[0].properties.city : '',
      codePostal: adress && adress[0] ? adress[0].properties.postcode : '',
      villeClient: adress && adress[0] ? adress[0].properties.city : '',
      adresseClient: adress && adress[0] ? adress[0].properties.name : '',
      typeBien: type || null,
      valorisation,
      limitation,
      methodeUn: data && data.methodeUn ? parseInt(data.methodeUn, 10) : 0,
      methodeDeux: resultMethodeDeux || 0,
      methodeTrois:
        data && data.methodeTrois ? parseInt(data.methodeTrois, 10) : 0,
      methodeQuatre:
        data && data.methodeQuatre ? parseInt(data.methodeQuatre, 10) : 0,
      montantLoyer:
        data && data.montantLoyer ? parseInt(data.montantLoyer, 10) : 0,
      rentabilite:
        data && data.rentabilite ? parseInt(data.rentabilite, 10) : 0,
      surface: data.surface ? parseFloat(data.surface) : 0,
      pieces: data.pieces ? parseInt(data.pieces, 10) : 0,
      surfaceTerrain: data.surfaceTerrain
        ? parseInt(data.surfaceTerrain, 10)
        : 0,
      immeubleAutre: data.immeubleAutre ? data.immeubleAutre : false,
      logementBeton: data.logementBeton ? data.logementBeton : false,
      logementCaractere: data.logementCaractere
        ? data.logementCaractere
        : false,
      logementRecent: data.logementRecent ? data.logementRecent : false,
      logementMoins10Ans: data.logementMoins10Ans
        ? data.logementMoins10Ans
        : false,
      maisonArchitecte: data.maisonArchitecte ? data.maisonArchitecte : false,
      terremoinsPlein: data.terremoinsPlein ? data.terremoinsPlein : false,
      grandStanding: data.grandStanding ? data.grandStanding : false,
      standing: data.standing ? data.standing : false,
      standingNormal: data.standingNormal ? data.standingNormal : false,
      moyenne: data.moyenne ? data.moyenne : false,
      moins40: data.moins40 ? data.moins40 : false,
      de40A60: data.de40A60 ? data.de40A60 : false,
      de60A80: data.de60A80 ? data.de60A80 : false,
      de80A120: data.de80A120 ? data.de80A120 : false,
      plus120: data.plus120 ? data.plus120 : false,
      rdc: data.rdc ? data.rdc : false,
      plus3: data.plus3 ? data.plus3 : false,
      pasAscenseur: data.pasAscenseur ? data.pasAscenseur : false,
      appartementCalme: data.appartementCalme ? data.appartementCalme : false,
      appartementBruyant: data.appartementBruyant
        ? data.appartementBruyant
        : false,
      appartementEspacesVerts: data.appartementEspacesVerts
        ? data.appartementEspacesVerts
        : false,
      immeubleRoute: data.immeubleRoute ? data.immeubleRoute : false,
      immeubleDegrade: data.immeubleDegrade ? data.immeubleDegrade : false,
      environnementDifficile: data.environnementDifficile
        ? data.environnementDifficile
        : false,
      maisonCalme: data.maisonCalme ? data.maisonCalme : false,
      maisonBruyante: data.maisonBruyante ? data.maisonBruyante : false,
      terrainPaysage: data.terrainPaysage ? data.terrainPaysage : false,
      maisonRoulante: data.maisonRoulante ? data.maisonRoulante : false,
      maisonDegrade: data.maisonDegrade ? data.maisonDegrade : false,
      vueDegagee: data.vueDegagee ? data.vueDegagee : false,
      vueMonument: data.vueMonument ? data.vueMonument : false,
      jardin: data.jardin ? data.jardin : false,
      plage: data.plage ? data.plage : false,
      colline: data.colline ? data.colline : false,
      rue: data.rue ? data.rue : false,
      vallee: data.vallee ? data.vallee : false,
      cour: data.cour ? data.cour : false,
      visAvis: data.visAvis ? data.visAvis : false,
      mitoyen1: data.mitoyen1 ? data.mitoyen1 : false,
      mitoyen2: data.mitoyen2 ? data.mitoyen2 : false,
      limitePropriete: data.limitePropriete ? data.limitePropriete : false,
      vieNord: data.vieNord ? data.vieNord : false,
      vieSud: data.vieSud ? data.vieSud : false,
      doubleOrientation: data.doubleOrientation
        ? data.doubleOrientation
        : false,
      mansardees: data.mansardees ? data.mansardees : false,
      enfilades: data.enfilades ? data.enfilades : false,
      pasStationnement: data.pasStationnement ? data.pasStationnement : false,
      stationnementCollectif: data.stationnementCollectif
        ? data.stationnementCollectif
        : false,
      stationnementPrivatif: data.stationnementPrivatif
        ? data.stationnementPrivatif
        : false,
      box: data.box ? data.box : false,
      plus2Places: data.plus2Places ? data.plus2Places : false,
      communs300MoinsM: data.communs300MoinsM ? data.communs300MoinsM : false,
      communs300Moins500: data.communs300Moins500
        ? data.communs300Moins500
        : false,
      communs500PlusM: data.communs500PlusM ? data.communs500PlusM : false,
      ecoles: data.ecoles ? data.ecoles : false,
      gardien: data.gardien ? data.gardien : false,
      alarme: data.alarme ? data.alarme : false,
      pasInterphone: data.pasInterphone ? data.pasInterphone : false,
      individuelGaz: data.individuelGaz ? data.individuelGaz : false,
      communGaz: data.communGaz ? data.communGaz : false,
      electricite: data.electricite ? data.electricite : false,
      electriciteModerne: data.electriciteModerne
        ? data.electriciteModerne
        : false,
      collectifSol: data.collectifSol ? data.collectifSol : false,
      renouvelable: data.renouvelable ? data.renouvelable : false,
      isolationParfait: data.isolationParfait ? data.isolationParfait : false,
      isolationBien: data.isolationBien ? data.isolationBien : false,
      isolationMoyen: data.isolationMoyen ? data.isolationMoyen : false,
      isolationAmeliorer: data.isolationAmeliorer
        ? data.isolationAmeliorer
        : false,
      hautInterieur: data.hautInterieur ? data.hautInterieur : false,
      bonInterieur: data.bonInterieur ? data.bonInterieur : false,
      aucunInterieur: data.aucunInterieur ? data.aucunInterieur : false,
      hautExterieur: data.hautExterieur ? data.hautExterieur : false,
      bonExterieur: data.bonExterieur ? data.bonExterieur : false,
      aucunExterieur: data.aucunExterieur ? data.aucunExterieur : false,
      parfaitEtat: data.parfaitEtat ? data.parfaitEtat : false,
      bonEtat: data.bonEtat ? data.bonEtat : false,
      moyenEtat: data.moyenEtat ? data.moyenEtat : false,
      mauvaisEtat: data.mauvaisEtat ? data.mauvaisEtat : false,
      cave: data.cave ? data.cave : false,
      combles: data.combles ? data.combles : false,
      sousmoinsSolInterieur: data.sousmoinsSolInterieur
        ? data.sousmoinsSolInterieur
        : false,
      sousmoinsSolExterieur: data.sousmoinsSolExterieur
        ? data.sousmoinsSolExterieur
        : false,
      balcon: data.balcon ? data.balcon : false,
      terrasse: data.terrasse ? data.terrasse : false,
      partieCommunes: data.partieCommunes ? data.partieCommunes : false,
      ravalementDeFacade: data.ravalementDeFacade
        ? data.ravalementDeFacade
        : false,
      petitsTravaux: data.petitsTravaux ? data.petitsTravaux : false,
      grosTravaux: data.grosTravaux ? data.grosTravaux : false,
      chargesImportantes: data.chargesImportantes
        ? data.chargesImportantes
        : false,
      fonciers: data.fonciers ? data.fonciers : false,
      loue: data.loue ? data.loue : false,
      loueComplique: data.loueComplique ? data.loueComplique : false,
      lotissement: data.lotissement ? data.lotissement : false,
      diffus: data.diffus ? data.diffus : false,
      constructibleOui: data.constructibleOui ? data.constructibleOui : false,
      constructionNon: data.constructionNon ? data.constructionNon : false,
      dePassage: data.dePassage ? data.dePassage : false,
      ecoulements: data.ecoulements ? data.ecoulements : false,
      service: data.service ? data.service : false,
      tresHautCoef: data.tresHautCoef ? data.tresHautCoef : false,
      hautCoef: data.hautCoef ? data.hautCoef : false,
      moyenCoef: data.moyenCoef ? data.moyenCoef : false,
      faibleCoef: data.faibleCoef ? data.faibleCoef : false,
      solRoche: data.solRoche ? data.solRoche : false,
      solArgile: data.solArgile ? data.solArgile : false,
      solNormal: data.solNormal ? data.solNormal : false,
      solHumide: data.solHumide ? data.solHumide : false,
      formeDuTerrainPenteImportante: data.formeDuTerrainPenteImportante
        ? data.formeDuTerrainPenteImportante
        : false,
      formeDuTerrainPenteFaible: data.formeDuTerrainPenteFaible
        ? data.formeDuTerrainPenteFaible
        : false,
      formeDuTerrainPlat: data.formeDuTerrainPlat
        ? data.formeDuTerrainPlat
        : false,
      formeDuTerrainDrapeau: data.formeDuTerrainDrapeau
        ? data.formeDuTerrainDrapeau
        : false,
      formeDuTerrainRectiligne: data.formeDuTerrainRectiligne
        ? data.formeDuTerrainRectiligne
        : false,
      viabiliteSurRue: data.viabiliteSurRue ? data.viabiliteSurRue : false,
      viabiliteTerrainRaccorde: data.viabiliteTerrainRaccorde
        ? data.viabiliteTerrainRaccorde
        : false,
      assainissementCollectif: data.assainissementCollectif
        ? data.assainissementCollectif
        : false,
      assainissementIndividuel: data.assainissementIndividuel
        ? data.assainissementIndividuel
        : false,
      vegetationContraignante: data.vegetationContraignante
        ? data.vegetationContraignante
        : false,
      vegetationAgreable: data.vegetationAgreable
        ? data.vegetationAgreable
        : false,
      edit: false,
    };
    try {
      let final;
      if (idnew) {
        findEstimationById(idnew)
          .then((res) => {
            final = {
              ...finalData,
              consultant: res.consultant[' @id '],
            };
            checkNotEmptyFieldAdress(final);
            checkValorisationLimitationLength();
            checkLengthMedia();
            ifCalcul(final);
            ifCalculProfitability(data);
            confirmAndSave(final);
          })
          .catch((err) => toast(err));
      } else {
        confirmAndSave(finalData);
      }
    } catch (err) {
      setLoad(false);
      throw err;
    }
  };
  const confirmAndSave = (finalData) => {
    if (
      !(
        required.required !== 'calcul' &&
        required.required !== 'calculRentabiliter'
      )
    )
      return;
    if (window.confirm('Êtes-vous sûr de vouloir continuer ?')) {
      setLoad(true);
      ifSave(finalData);
      ifSaveAndNoId(finalData);
      ifSaveAndId(finalData);
      ifSaveAndIdAndStepSmallerThanOne(finalData);
      ifSaveAndStepEgualToSix(finalData);
      ifEditerPdf(finalData);
    }
  };
  const ifSave = (finalData) => {
    if (!required.required) return;
    const finalDataEdit = {
      ...finalData,
      edit: true,
    };
    ifNoIdSaveEstimation(finalDataEdit);
    ifIdSaveEstimation(finalDataEdit);
    ifStepGreaterThenOne(finalDataEdit);
  };
  const ifSaveAndNoId = async (finalData) => {
    if (!(required.required === null && !idnew)) return;
    const data = await postNewEstimation(finalData);
    setIdnew(data.id);
    setEtape(etape + 1);
    setLoad(false);
    scroll.scrollToBottom();
  };
  const ifSaveAndId = (finalData) => {
    if (!(required.required === null && idnew)) return;
    if (!(etape >= 1 && pictures && pictures.length === 5)) return;
    axios
      .put(`${ESTIMATION_API}/${idnew}`, finalData)
      .then((response) => {
        if (etape < 2) {
          const bodyFormData = new FormData();
          for (let index = 0; index < pictures.length; index++) {
            const currentFile = pictures[index];
            bodyFormData.append(`file${index}`, currentFile);
          }
          axios({
            method: 'post',
            url: `${API_LBA}/media/photo/estimation?id=${response.data.id}`,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              setEtape(etape + 1);
              setLoad(false);
              setSurface(response.data.surface);
              scroll.scrollToBottom();
            })
            .catch((e) => {
              if (e.response) {
                const message = 'Une erreur est survenue';
                toast.error(message);
              }
            });
        } else {
          setEtape(etape + 1);
          setLoad(false);
          setSurface(response.data.surface);
          scroll.scrollToBottom();
        }
      })
      .catch((e) => {
        setLoad(false);
        if (e.response) {
          const message = 'Une erreur est survenue';
          toast.error(message);
        }
        toast.error('Une erreur est survenue');
      });
  };
  const ifSaveAndIdAndStepSmallerThanOne = (finalData) => {
    if (!(required.required === null && idnew && etape < 1)) return;
    axios.put(`${ESTIMATION_API}/${idnew}`, finalData).then(() => {
      setEtape(etape + 1);
      setLoad(false);
      scroll.scrollToBottom();
    });
  };
  const ifSaveAndStepEgualToSix = (finalData) => {
    if (!(required.required === null && etape === 6)) return;
    if (
      valorisation.length > 0 &&
      limitation.length > 0 &&
      valorisation.length <= 4 &&
      limitation.length <= 4
    ) {
      setLoad(true);
      axios.put(`${ESTIMATION_API}/${idnew}`, finalData).then(() => {
        setEtape(etape + 1);
        setLoad(false);
        scroll.scrollToBottom();
      });
    }
  };
  const ifEditerPdf = (finalData) => {
    if (required.required !== false) return;
    axios.put(`${ESTIMATION_API}/${idnew}`, finalData).then((response) => {
      setId(response.data.id);
      setLoad(false);
    });
  };
  const ifNoIdSaveEstimation = async (finalDataEdit) => {
    if (idnew) return;
    await saveEstimationAndRedirect(finalDataEdit);
  };
  const ifIdSaveEstimation = async (finalDataEdit) => {
    if (!(etape <= 0 && idnew)) return;
    await updateEstimationAndRedirect(idnew, finalDataEdit);
  };
  const ifStepGreaterThenOne = async (finalDataEdit) => {
    if (etape < 1) return;
    if (pictures && pictures.length === 5) {
      await updateEstimationAndMedia(idnew, finalDataEdit, pictures, etape);
    } else {
      await updateEstimationAndRedirect(idnew, finalDataEdit);
    }
  };
  const checkNotEmptyFieldAdress = (finalData) => {
    if (!(etape === 2 && finalData.adresseBien === '')) return;
    setError((prevState) => ({
      ...prevState,
      adresseBien: 'Veuillez compléter ce champ.',
    }));
  };
  const checkValorisationLimitationLength = () => {
    if (!(!required.required && etape === 6)) return;
    if (valorisation.length === 0 || valorisation.length > 4) {
      setLoad(false);
      setError((prevState) => ({
        ...prevState,
        valorisation:
          valorisation.length === 0
            ? 'Veuillez compléter ce champ.'
            : 'Nombres de choix supérieur à 4.',
      }));
    }
    if (limitation.length === 0 || limitation.length > 4) {
      setLoad(false);
      setError((prevState) => ({
        ...prevState,
        limitation:
          limitation.length === 0
            ? 'Veuillez compléter ce champ.'
            : 'Nombres de choix supérieur à 4.',
      }));
    }
  };
  const checkLengthMedia = () => {
    if (!required.required && etape >= 1 && pictures && pictures.length !== 5) {
      setError((prevState) => ({
        ...prevState,
        pictures: '5 photos obligatoires',
      }));
      throw new Error('error');
    }
  };
  const ifCalcul = (finalData) => {
    if (required.required !== 'calcul') return;
    setLoad(true);
    const finalDataEdit = {
      ...finalData,
      edit: true,
    };
    axios.put(`${ESTIMATION_API}/${idnew}`, finalDataEdit).then(() => {
      axios
        .get(`${API_LBA}/estimation/methode-deux/${idnew}`)
        .then((response) => {
          setResultMethodeDeux(response.data);
          setLoad(false);
        });
    });
  };
  const ifCalculProfitability = (data) => {
    if (required.required !== 'calculRentabiliter') return;
    setLoad(true);
    const rentabiliter = data.rentabilite / 100;
    setMethodeQ((data.montantLoyer * 10) / rentabiliter);
    setLoad(false);
  };
  const backdrop = () => loadingEtape || load;
  return (
    <SideBar>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid className={classes.container}>
          <Grid item>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <Grid
                className="mt-5"
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12}>
                  {(editing && (
                    <Typography className="ariane" compoment="p" align="center">
                      Modification d&apos;une étude de prix et de projet
                      personnalisé
                    </Typography>
                  )) || (
                    <Typography className="ariane" compoment="p" align="center">
                      Création d&apos;une étude de prix et de projet
                      personnalisé
                    </Typography>
                  )}
                </Grid>
                <Grid>
                  <Typography className="titre" variant="h4" compoment="p">
                    <span className="titreSpan"> Informations générales</span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <ContainerEtape
                  id={id}
                  load={load}
                  surface={surface}
                  type={type}
                  setCityBien={setCityBien}
                  setPrixBien={setPrixBien}
                  setPictures={setPictures}
                  setLimitation={setLimitation}
                  setValorisation={setValorisation}
                  setAdressBien={setAdressBien}
                  setAdress={setAdress}
                  classes={classes}
                  control={control}
                  required={required}
                  errors={errors}
                  error={error}
                  setUpdateAdress={setUpdateAdress}
                  lastAdress={lastAdress}
                  adress={adress}
                  setTypeBien={setTypeBien}
                  setError={setError}
                  register={register}
                  typeBien={typeBien}
                  loading={loadingEtape}
                  editing={editing}
                  etape={etape}
                  setEtape={setEtape}
                  setRequired={setRequired}
                  setType={setType}
                  idnew={idnew}
                  pictures={pictures}
                  loadPhoto={loadPhoto}
                  setLoad={setLoad}
                  defaultImage={defaultImage}
                  setUpdateAdressBien={setUpdateAdressBien}
                  lastAdressBien={lastAdressBien}
                  adressBien={adressBien}
                  prixMin={prixBien.min}
                  prixMax={prixBien.max}
                  prixMoyen={prixBien.moyen}
                  cityBien={cityBien}
                  resultMethodeDeux={resultMethodeDeux}
                  methodeQ={methodeQ}
                  valorisation={valorisation}
                  limitation={limitation}
                  menuProps={MenuProps}
                />
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="flex-start"
                >
                  {id ? null : (
                    <Button
                      color="primary"
                      className="mt-1 mb-2"
                      size="large"
                      type="submit"
                      disabled={!!load}
                      variant="contained"
                      onClick={() => {
                        setRequired((prevState) => ({
                          ...prevState,
                          required: true,
                        }));
                      }}
                    >
                      Sauvegarder et revenir plus tard
                    </Button>
                  )}
                </Grid>
                {etape === 6 ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    item
                  >
                    {id && !load && !required.required ? (
                      <Button
                        className={`mb-5 mt-1 ${classes.btn}`}
                        color="primary"
                        size="large"
                        type="submit"
                        variant="contained"
                        disabled={!!load}
                      >
                        <Typography variant="caption">
                          <a
                            className={classes.ddl}
                            href={`${API_LBA}/estimation/pdf/${id}`}
                            download
                            target="_blank"
                            rel="noreferrer"
                            onClick={() => {
                              history.push('/mes-estimations');
                            }}
                          >
                            Ouvrir le pdf
                          </a>
                        </Typography>
                      </Button>
                    ) : (
                      <Button
                        className={`mb-3 mt-1 ${classes.btnEditer}`}
                        type="submit"
                        color="primary"
                        size="large"
                        variant="contained"
                        disabled={!!load}
                        onClick={() => {
                          setRequired((prevState) => ({
                            ...prevState,
                            required: false,
                          }));
                        }}
                      >
                        Éditer
                      </Button>
                    )}
                  </Grid>
                ) : null}
              </Grid>
              {etape >= 1 && (
                <>
                  <Grid item align="right" className={classes.arrowUp}>
                    <Fab
                      onClick={() => scroll.scrollToTop()}
                      aria-label="up"
                      size="small"
                    >
                      <KeyboardArrowUpIcon fontSize="large" />
                    </Fab>
                  </Grid>
                  <Grid item align="right" className={classes.arrowDown}>
                    <Fab
                      onClick={() => scroll.scrollToBottom()}
                      aria-label="up"
                      size="small"
                    >
                      <KeyboardArrowDownIcon fontSize="large" />
                    </Fab>
                  </Grid>
                </>
              )}
            </form>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={backdrop()}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress color="inherit" />
          <Typography>Chargement en cours, veuillez patienter.</Typography>
        </Grid>
      </Backdrop>
    </SideBar>
  );
}

export default Estimation;
