import {
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import HeightIcon from '@mui/icons-material/Height';
import Pagination from '@mui/material/Pagination';
import React, { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import SortIcon from '@mui/icons-material/Sort';
import Backdrop from '@mui/material/Backdrop';
import SideBar from '../../components/SideBar';
import useErrorHandler from '../../hooks/useErrorHandler';
import {
  findInterAgent,
  findInterAgentAdmin,
} from '../../service/consultantsApi';
import { useStyles } from '../../styles/estimation/Classes';
import TitlePage from '../../components/TitlePage';

function GestionInterAgent() {
  const token = localStorage.getItem('token') || false;
  const user = token ? jwtDecode(token) : null;
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElEtat, setAnchorElEtat] = useState(null);
  const [order, setOrder] = useState('[date]=desc');
  const [interAgent, setInterAgent] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState(null);
  const [etat, setEtat] = useState();
  const [typeReco, setTypeReco] = useState({
    query: `recepteur.email=${user.username}`,
    state: 'reçu',
  });
  const { errorHandler } = useErrorHandler();

  const itemsPerPage = 12;

  useEffect(() => {
    setIsLoading(true);
    fetchInterAgent();
    return () => {
      setInterAgent([]);
    };
  }, [currentPage, order, etat, typeReco.query]);

  const fetchInterAgent = async () => {
    let data;
    try {
      if (user.roles.includes('ROLE_ADMIN')) {
        data = await findInterAgentAdmin(
          currentPage,
          itemsPerPage,
          order,
          etat,
        );
      } else {
        data = await findInterAgent(
          currentPage,
          itemsPerPage,
          order,
          typeReco.query,
          etat,
        );
      }
      setInterAgent(data['hydra:member']);
      setTotalPages(
        Math.ceil(interAgent ? data['hydra:totalItems'] / itemsPerPage : 0),
      );
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };
  // debounce
  const updateQuery = async () => {
    let data;
    if (search === null) return;

    try {
      setIsLoading(true);
      data = await findInterAgentAdmin(
        currentPage,
        itemsPerPage,
        order,
        search,
      );
      setInterAgent(data['hydra:member']);
      setTotalPages(
        Math.ceil(interAgent ? data['hydra:totalItems'] / itemsPerPage : 0),
      );
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const delayedQuery = useCallback(debounce(updateQuery, 500), [search]);

  useEffect(() => {
    delayedQuery();
    setCurrentPage(1);
    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel;
  }, [delayedQuery]);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleClickMenuEtat = (event) => {
    setAnchorElEtat(event.currentTarget);
  };
  const handleClickMenuDate = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    setAnchorElEtat(null);
    const { myValue } = event.currentTarget.dataset;
    setEtat(myValue);
  };
  const handleCloseMenuDate = (event) => {
    setAnchorEl(null);
    const { myValue } = event.currentTarget.dataset;
    setOrder(myValue);
  };

  const useStylesComponents = makeStyles((theme) => ({
    container: {
      padding: '50px',
      [theme.breakpoints.down('md')]: {
        padding: '10px',
      },
    },
    action: {
      marginRight: '5px',
      [theme.breakpoints.down('md')]: {
        paddingLeft: '0',
      },
    },
    statut: {
      marginRight: '5px',
      height: '32px',
    },
    buttonDdl: {
      width: '150px',
      height: '32px',
    },
    ddl: {
      color: 'white',
      width: '150px',
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    textSuccess: {
      color: 'green',
    },
    textEnCours: {
      color: '#00AFE6',
    },
  }));

  const classesComponents = useStylesComponents();
  const classes = useStyles();

  return (
    <SideBar>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <TitlePage
          title="Inter Agent"
          underTitle={'Consultez toutes les recommandations reçu et envoyé\n'}
        />
        <Grid className={classesComponents.container} item xs={12}>
          <TableContainer component={Paper}>
            <Grid container className="p-1">
              <Link className="" to="/inter-agent/recherche">
                <Button className="mr-1" variant="contained" color="primary">
                  Faire une recommandation
                </Button>
              </Link>
              <RadioGroup row className="ml-1" defaultValue="reçu">
                <FormControlLabel
                  onClick={(e) =>
                    setTypeReco((prevState) => ({
                      ...prevState,
                      query: `recepteur.email=${user.username}`,
                      state: 'reçu',
                    }))
                  }
                  value="reçu"
                  control={<Radio />}
                  label="Recommandation reçu"
                />
                <FormControlLabel
                  onClick={(e) =>
                    setTypeReco((prevState) => ({
                      ...prevState,
                      query: `consultant.email=${user.username}`,
                      state: 'envoyé',
                    }))
                  }
                  value="envoyé"
                  control={<Radio />}
                  label="Recommandation envoyé"
                />
              </RadioGroup>
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  {typeReco.state !== 'reçu' ? (
                    <TableCell>Recommandation envoyé a </TableCell>
                  ) : (
                    <TableCell>Recommandation reçus de </TableCell>
                  )}
                  <TableCell>Pour le client</TableCell>
                  <TableCell>
                    État
                    <Button
                      className="ml-1"
                      size="small"
                      color="primary"
                      onClick={handleClickMenuEtat}
                    >
                      <SortIcon fontSize="small" />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorElEtat}
                      keepMounted
                      open={Boolean(anchorElEtat)}
                      onClose={handleClose}
                    >
                      <MenuItem data-my-value="accepter" onClick={handleClose}>
                        Accepté
                      </MenuItem>
                      <MenuItem data-my-value="refuser" onClick={handleClose}>
                        Refusé
                      </MenuItem>
                      <MenuItem data-my-value="en cours" onClick={handleClose}>
                        En cours
                      </MenuItem>
                    </Menu>
                  </TableCell>
                  <TableCell>Règle de partage</TableCell>
                  <TableCell>
                    Date
                    <Button
                      className="ml-1"
                      size="small"
                      color="primary"
                      onClick={handleClickMenuDate}
                    >
                      <HeightIcon fontSize="small" />
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenuDate}
                    >
                      <MenuItem
                        data-my-value="[date]=desc"
                        onClick={handleCloseMenuDate}
                      >
                        Décroissant
                      </MenuItem>
                      <MenuItem
                        data-my-value="[date]=asc"
                        onClick={handleCloseMenuDate}
                      >
                        Croissant
                      </MenuItem>
                    </Menu>
                  </TableCell>
                  {typeReco.state !== 'envoyé' && <TableCell>Action</TableCell>}
                </TableRow>
              </TableHead>
              {interAgent.map((item) => (
                <TableBody key={Math.random()}>
                  <TableRow>
                    {typeReco.state !== 'reçu' ? (
                      <TableCell>
                        {item.recepteur.nom} {item.recepteur.prenom}
                      </TableCell>
                    ) : (
                      <TableCell>
                        {item.consultant.nom} {item.consultant.prenom}
                      </TableCell>
                    )}
                    <TableCell>{item.nom}</TableCell>
                    <TableCell>{item.etat}</TableCell>
                    <TableCell>{item.regle}%</TableCell>
                    <TableCell>
                      {new Date(item.date).toLocaleDateString('fr-FR')}
                    </TableCell>
                    {typeReco.state !== 'envoyé' && (
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          component={Link}
                          to={`/inter-agent/recommandation/reception/${item.id}`}
                        >
                          Voir
                        </Button>
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              ))}
            </Table>{' '}
            {!isLoading ? (
              <Pagination
                count={!Number.isNaN(totalPages) ? totalPages : 0}
                page={currentPage}
                onChange={handleChange}
                size="large"
                shape="rounded"
                siblingCount={0}
              />
            ) : null}
          </TableContainer>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={!!isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </SideBar>
  );
}

export default GestionInterAgent;
