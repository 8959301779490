import { ErrorMessage } from '@hookform/error-message';
import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  TextField,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Skeleton from '@mui/material/Skeleton';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import SideBar from '../../components/SideBar';

import Form from '../../styles/pages/editionConsultant/Form';
import { useStyles } from '../../styles/estimation/Classes';
import TitlePage from '../../components/TitlePage';
import {
  fetchConsultantById,
  patchConsultant,
} from '../../features/consultant/consultantsSlice';

function AdminEditconsultantId() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([]);
  const { editConsultant, loading } = useSelector((state) => state.consultants);
  const [editedconsultantIds, setEditedconsultantIds] = useState({
    rsac: '',
    dateNaissance: '',
    actif: '',
  });
  // const [badges, setBadges] = useState([]);
  // const [actifMooveo, setActifMooveo] = useState();
  // const [badgesData, setBadgesData] = useState([]);
  /* useEffect(() => {
   const getBadge = () => {
     return axios
       .get(`${BADGES_API}`)
       .then((response) => {
         return response.data;
       })
       .catch((error) => {
         useErrorHandler(error);
       });
   };
   getBadge().then((response) => setBadgesData(response['hydra:mer']));
 }, []);
const filterOptions = (options, inputValue) => {
    const candidate = inputValue.toLowerCase();

    return options.filter(({ label }) =>
      label.toLowerCase().includes(candidate),
    );
    const loadOptions = (inputValue, callback) => {
       // map server data to options
       const asyncOptions = badgesData.map((badge) => {
      return { label: badge.label };
    });
       // Filter options if needed

    const filtered = filterOptions(asyncOptions, inputValue);
       // Call callback with mapped and filtered options
       callback(filtered);
     };

     const postUpdateMooveo = (event) => {
        axios
          .post(
            `${API_LBA}/backoffice/consultantIds/update-mooveo/${parseInt(
              id,
              10,
            )}?actifMooveo=${event[0].value}`,
            event,
          )
          .then(() => {
            setIsLoading(false);
          })
          .catch(() => {
            toast.error('Erreur lié à mooveo');
          });
      };
      const handleChangeMooveo = (event) => {
        setActifMooveo(event.targetvalu;
        // postUpdateMooveo(event.value);
      };
       const handleChangeActif = (event) => {
       setActif(event.value);
     };
    /*  const handleChangeAmbassadeur = (event) => {
       if (event.value) {
         setRoles((current) => [...current, 'ROLE_AMBASSADEUR']);
       } else {
         setRoles(roles.filter((r) => r !== 'ROLE_AMBASSADEUR'));
       }
     };
 */
  /*      const handleChangeBadges = (event) => {
  const newBadge = event.map((badge) => {
    return badge['@id'];
  });
  setBadges(newBadge);
}; */

  const form = useForm();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = form;

  useEffect(() => {
    dispatch(fetchConsultantById(parseInt(id, 10)));
  }, [dispatch]);
  useEffect(() => {
    setEditedconsultantIds({
      rsac: editConsultant && editConsultant.rsac,
      dateNaissance: editConsultant && editConsultant.dateNaissance,
      actif: editConsultant && editConsultant.actif,
    });
    setRoles(editConsultant && editConsultant.roles);
  }, [editConsultant]);

  const onSubmit = async (data) => {
    const patch = {
      actif: data.actif,
      actifPresentation: data.actifPresentation,
      countPresentation: data.countPresentation
        ? parseInt(data.countPresentation, 10)
        : editConsultant.countPresentation,
      rsac: data.rsac === '' ? null : data.rsac,
      dateNaissance: data.dateNaissance,
      roles,
      // actifMooveo,
      // badges,
      id: parseInt(id, 10),
    };
    dispatch(patchConsultant(patch));
    toast.success('consultant mise à jour.');
  };
  useEffect(() => {
    if (editedconsultantIds) {
      const day = new Date(
        editedconsultantIds && editedconsultantIds.dateNaissance,
      ).getDate();
      const year = new Date(
        editedconsultantIds && editedconsultantIds.dateNaissance,
      ).getFullYear();
      const month = new Date(
        editedconsultantIds && editedconsultantIds.dateNaissance,
      ).getUTCMonth();
      const incrementeMonth = parseInt(month, 10) + 1;
      const monthToString = incrementeMonth.toString();
      const finalMonth =
        monthToString < 10 ? `0${monthToString}` : monthToString;
      const finalDay = day < 10 ? `0${day}` : day;
      setValue(
        'dateNaissance',
        `${year.toString()}-${finalMonth}-${finalDay.toString()}`,
      );
      setValue('rsac', editedconsultantIds.rsac);
    }
  }, [editedconsultantIds]);
  const options = [
    { value: true, label: 'Oui' },
    { value: false, label: 'Non' },
  ];

  function hasAmbassadorRole(consultant) {
    return consultant && consultant.roles.includes('ROLE_AMBASSADEUR');
  }

  const defaultValueActive = options.find(
    (option) => option.value === (editConsultant && editConsultant.actif),
  );

  const defaultValueAmbassador = options.find(
    (option) => option.value === hasAmbassadorRole(editConsultant),
  );
  const defaultValuePresentationActive = options.find(
    (option) =>
      option.value === (editConsultant && editConsultant.actifPresentation),
  );
  const handleChangeActive = (selectedOption) => {
    setValue('actif', selectedOption.value);
  };

  const handleChangePresentationActive = (selectedOption) => {
    setValue('actifPresentation', selectedOption.value);
  };
  const handleChangeCountPresentationActive = (selectedOption) => {
    setValue('countPresentation', selectedOption.target.value);
  };

  const handleChangeAmbassador = (event) => {
    setValue('ambassadeur', event.value);
    if (event.value) {
      setRoles((current) => [...current, 'ROLE_AMBASSADEUR']);
    } else {
      setRoles(roles.filter((r) => r !== 'ROLE_AMBASSADEUR'));
    }
  };
  const classes = useStyles();
  const Styles = makeStyles(() => ({
    grid: {
      height: '500px',
    },
    btn: {
      paddingTop: '20px',
    },
  }));
  const style = Styles();

  return (
    <SideBar>
      <Grid
        className={style.grid}
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        {loading ? (
          <Grid
            className="mt-3"
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Skeleton variant="text" height={80} width={400} />
          </Grid>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className="mt-3"
          >
            <TitlePage
              title="Edition du Consultant"
              underTitle={`${editConsultant && editConsultant.nom} ${
                editConsultant && editConsultant.prenom
              }`}
            />
          </Grid>
        )}
        <Grid className={classes.field}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormControl>
              {loading ? (
                <>
                  <Skeleton variant="text" height={80} width={400} />
                  <Skeleton variant="text" height={80} width={400} />
                  <Skeleton variant="text" height={80} width={400} />
                  <Skeleton variant="text" height={80} width={400} />
                  <Skeleton variant="input" height={80} width={400} />
                </>
              ) : (
                <>
                  <label htmlFor="rsac" className="mb-1">
                    Rsac<span className="asterix"> *</span> :
                  </label>
                  <input
                    id="rsac"
                    name="rsac"
                    className="mb-1"
                    type="text"
                    placeholder="Rsac"
                    {...register('rsac', { required: false })}
                  />
                  <i className="mt-0">
                    Veuillez indiquer le RSAC au format XXX XXX XXX.
                  </i>
                  <ErrorMessage
                    errors={errors}
                    name="rsac"
                    message="Rsac du
consultant requis."
                    render={({ message }) => <span>{message}</span>}
                  />

                  <label htmlFor="dateNaissance" className="mb-1 mt-1">
                    Date de naissance<span className="asterix"> *</span> :
                  </label>
                  <input
                    id="dateNaissance"
                    name="dateNaissance"
                    className="mb-1"
                    type="date"
                    placeholder="Date de naissance"
                    {...register('dateNaissance', { required: false })}
                    // onChange={handleDateChange}
                    defaultValue={
                      editConsultant && editConsultant.dateNaissance
                    }
                  />
                  <label htmlFor="actif" className="mb-1 mt-1">
                    Consultant actif<span className="asterix"> *</span> :
                  </label>
                  <Select
                    id="actif"
                    defaultValue={defaultValueActive}
                    options={options}
                    onBlurResetsInput={false}
                    onSelectResetsInput={false}
                    simpleValue
                    clearable={false}
                    name="actif"
                    searchable
                    valueKey="boolean"
                    labelKey="actif"
                    onChange={handleChangeActive}
                  />

                  <label htmlFor="ambassadeur" className="mb-1 mt-1">
                    Ambassadeur<span className="asterix"> *</span> :
                  </label>
                  <Select
                    id="ambassadeur"
                    defaultValue={defaultValueAmbassador}
                    options={options}
                    onBlurResetsInput={false}
                    onSelectResetsInput={false}
                    simpleValue
                    clearable={false}
                    name="ambassadeur"
                    valueKey="boolean"
                    labelKey="ambassadeur"
                    onChange={handleChangeAmbassador}
                  />

                  <label htmlFor="actif_presentation" className="mb-1 mt-1">
                    Active la présentation :
                  </label>
                  <Select
                    id="actif_presentation"
                    defaultValue={defaultValuePresentationActive}
                    options={options}
                    onBlurResetsInput={false}
                    onSelectResetsInput={false}
                    simpleValue
                    clearable={false}
                    name="actifPresentation"
                    valueKey="boolean"
                    labelKey="actif_presentation"
                    onChange={handleChangePresentationActive}
                  />
                  <label htmlFor="count_presentation" className="mb-1 mt-1">
                    Ajout de token Chagpt :
                  </label>
                  <TextField
                    id="count_presentation"
                    defaultValue={
                      editConsultant &&
                      parseInt(editConsultant.countPresentation, 10)
                    }
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    type="number"
                    name="countPresentation"
                    onChange={handleChangeCountPresentationActive}
                  />
                </>
              )}
            </FormControl>

            {/*
                <label htmlFor="actifMooveo" className="mb-1 mt-1">
                  Mooveo<span className="asterix"> *</span> :
                </label>
                <Select
                  id="actifMooveo"
                  className="selectEdit"
                  defaultValue={

editConsultant &&
editConsultant.actifMooveo ? value[0] : value[1]
                  }
                  loadOptions={value}
                  onBlurResetsInput={false}
                  onSelectResetsInput={false}
                  simpleValue
                  clearable={false}
                  name="actifMooveo"
                  searchable
                  valueKey="boolean"
                  labelKey="actifMooveo"
                  onChange={handleChangeMooveo}
                />
                <label htmlFor="badges" className="mb-1 mt-1">
                  Badges<span className="asterix"> *</span> :
                </label>
                <AsyncSelect
                  id="badges"
                  className="selectEdit"
                  defaultValue={
                    !loading &&
editConsultant &&
editConsultant.badge !== 'undefined'
                      ?
editConsultant.badges
                      : null
                  }
                  cacheOptions
                  loadOptions={loadOptions}
                  defaultOptions={badgesData}
                  onBlurResetsInput={false}
                  onSelectResetsInput={false}
                  isMulti
                  clearable={false}
                  name="badges"
                  valueKey="object"
                  labelKey="badges"
                  onChange={handleChangeBadges}
                  getOptionValue={(option) => `${option.label}`}
                  closeMenuOnSelect={false}
                />
                */}

            {/* }
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="flex-start"
            mt="10px"
          >
            {!isLoading &&

editConsultant &&

editConsultant.badges.length > 0 &&

editConsultant.badges.map((badge, key) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid key={`${key}`}>
                    <img
                      alt={badge.label}
                      style={{ height: 130, width: 130 }}
                      src={`${API_LBA}/build/front/static/badges/badges-couleur/${badge.path}`}
                    />
                  </Grid>
                );
              })}
          </Grid>
          */}
            <Grid container className={style.btn} justifyContent="center">
              <Button
                disabled={loading}
                variant="contained"
                color="primary"
                type="submit"
                style={{ width: '150px', height: '40px' }}
              >
                Enregistrer
              </Button>
              <Link className="ml-1" to="/consultants">
                <Button
                  style={{ width: '150px', height: '40px' }}
                  variant="contained"
                >
                  Retour
                </Button>
              </Link>
            </Grid>
          </Form>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      ;
    </SideBar>
  );
}

export default AdminEditconsultantId;
