import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { Groups2, Handshake } from '@mui/icons-material';
import jwtDecode from 'jwt-decode';
import SideBarItem from '../SideBarItem';
import { menu } from '../../constants/menu';
import rolesConstants from '../../constants/security';

function MyLinks() {
  const token = localStorage.getItem('token') || false;
  const user = token ? jwtDecode(token) : { username: '', roles: [] };
  const { username, roles = [] } = user;
  function hasRole(userRoles, targetRoles) {
    return targetRoles.some((role) => userRoles.includes(role));
  }
  return (
    <Grid
      container
      item
      spacing={1}
      alignItems="flex-end"
      justifyContent="center"
      // alignContent="center"
      // paddingTop="20px"
      gap="10%"
    >
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">Interagent</Typography>
          <IconButton className="iconButton" component={Link} to="inter-agent">
            <Handshake fontSize="large" className="icon" />
          </IconButton>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="icon-container">
          <Typography variant="caption">Basile</Typography>
          <SideBarItem
            key={Math.random()}
            icon={menu.Basile.icon}
            target={menu.Basile.target}
            href={menu.Basile.href}
          />
        </div>
      </Grid>

      {hasRole(roles, [rolesConstants.AMBASSADEUR, rolesConstants.ADMIN]) ? (
        <Grid item xs={4}>
          <div className="icon-container">
            <Typography variant="caption">Mes évenements</Typography>
            <IconButton
              className="iconButton"
              component={Link}
              to="mes-evenements"
            >
              <Groups2 fontSize="large" className="icon" />
            </IconButton>
          </div>
        </Grid>
      ) : null}
    </Grid>
  );
}

export default MyLinks;
